import React, { Fragment, useState } from "react";

import classes from "./SurveyEditerPage.module.css";
import { AutoAffix } from "react-overlays";

import SurveyPreviewContainer from "../Containers/SurveyPreview/SurveyPreviewContainer";
import EditTab from "../Containers/EditTab/EditTab";
import EditFooter from "../Containers/EditFooter/EditFooter";
import ModalEditer from "../Components/UI/ModalEditer/ModalEditer";
import ScrollTop from "@nzambello/react-scrolltop";
import QuestionListMoblile from "../Containers/QuestionListPanel/QuestionListMoblie";
import firebase from "firebase";
import SurveyEditDrawer from "../Containers/SurveyEditDrawer/SurveyEditDrawer";
import { useDispatch } from "react-redux";
import { CHANGE_SURVEY_STATE } from "../Store/actions/actionsTypes";

const SurveyEditerPage = () => {
  firebase.analytics().logEvent("Survey Edit page is rendered");
  const [open, setOpen] = useState(false);

  window.onbeforeunload = function () {
    return true;
  };

  return (
    <Fragment>
      <ScrollTop />

      {/* <h3 className={classes.SmallScreen}>Please use bigger screen device for Editer !</h3> */}
      <div className={classes.SurveyEditerPage}>
        <div className={classes.SurveyPreview}>
          <SurveyPreviewContainer setOpen={setOpen} />
        </div>
        {/* <div className={classes.SideBar}>
          <AutoAffix>
            <div>
              <EditTab />
              <div className={classes.Footer}>
                <EditFooter />
              </div>
            </div>
          </AutoAffix>
        </div> */}
        <AutoAffix>
          <>
            <SurveyEditDrawer open={open} setOpen={setOpen}></SurveyEditDrawer>

            <div className={classes.Footer}>
              <EditFooter />
            </div>
          </>
        </AutoAffix>

        <div className={classes.ForMobile}>
          <ModalEditer />
          <div className={classes.Container}>
            <QuestionListMoblile />
            <EditFooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SurveyEditerPage;
