import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../ulitity/ulitity";

const initialState = {
  survey: {
    id: "",
    title: "",
    subTitle: "",
    openTill: "",
    questions: {},
    question_order: [],
    creatorDate: "",
    lastModified: "",
    submitting: false,
  },
  company: {
    id: "",
    logo: "",
    name: "",
    status: "",
    activeTill: "",
    address: "",
    cin: "",
    role: "",
    emailGroups: [],
  },
  surveyLoading: false,
  submitLoading: false,
  error: null,
  redirect: false,
  showModal: false,
};

const reducer = (state = initialState, action) => {
  let idx,
    newOrder,
    updated_survey,
    groupInd,
    questInd,
    inversed,
    removed_id,
    count,
    cloned_id;
  switch (action.type) {
    case actionTypes.FETCH_SURVEY_START:
      return updateObject(state, { surveyLoading: true });
    case actionTypes.FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        survey: {
          id: state.survey.id,
          ...action.payload,
        },
        surveyLoading: false,
        error: null,
      };
    case actionTypes.FETCH_SURVEY_FAIL:
      return updateObject(state, { surveyLoading: false, error: action.error });
    case actionTypes.ADD_QUESTION:
      newOrder = [...state.survey.question_order];
      newOrder.splice(action.questionNo - 1, 0, action.questionId);

      return {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [action.payload._id]: action.payload,
          },
          question_order: newOrder,
          current_question_id: action.questionId,
          auto_scroll: true,
        },
      };
    case actionTypes.ADD_UNGROUP_QUESTION:
      updated_survey = { ...state.survey };
      count = 0;
      let flag = true;
      if (updated_survey.grouped_questions.length > 0) {
        for (let i = 0; i < updated_survey.grouped_questions.length; i++) {
          if (count === 0) {
            count += updated_survey.grouped_questions[i].questions.length;
            if (count >= action.questionNo) {
              flag = false;
              updated_survey.grouped_questions[i].questions.splice(
                action.questionNo - 1,
                0,
                action.questionId
              );
              break;
            }
          } else if (count !== 0) {
            let tempCount =
              updated_survey.grouped_questions[i].questions.length + count;
            if (tempCount >= action.questionNo) {
              flag = false;
              updated_survey.grouped_questions[i].questions.splice(
                action.questionNo - count - 1,
                0,
                action.questionId
              );
              break;
            } else {
              count += updated_survey.grouped_questions[i].questions.length;
            }
          }
        }
      }
      if (flag) {
        updated_survey.ungrouped_questions.splice(
          action.questionNo - count - 1,
          0,
          action.questionId
        );
      }
      updated_survey.question_order.push(action.payload._id);
      updated_survey.questions[action.payload._id] = action.payload;
      updated_survey.current_question_id = action.payload._id;
      updated_survey.auto_scroll = true;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.UPDATE_SURVEY_START:
      return updateObject(state, { submitLoading: true });
    case actionTypes.UPDATE_SURVEY_SUCCESS:
      return updateObject(state, { submitLoading: false, error: null });
    case actionTypes.UPDATE_SURVEY_FAIL:
      return updateObject(state, { submitLoading: false, error: action.error });
    case actionTypes.ACTIVE_QUESTION:
      return {
        ...state,
        survey: {
          ...state.survey,
          current_question_id: action.questionId,
        },
      };

    case actionTypes.CHANGE_SURVEY_STATE:
      return {
        ...state,
        survey: {
          ...state.survey,
          survey_saved: action.payload,
          auto_scroll: action.payload ? false : true,
        },
      };

    case actionTypes.UPDATE_QUESTION:
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [action.questionId]: {
              ...state.survey.questions[action.questionId],
              ...action.payload,
            },
          },
        },
      };
    case actionTypes.GROUP_QUESTIONS:
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_SURVEY_HEADER:
      return {
        ...state,
        survey: {
          ...state.survey,
          ...action.payload,
        },
      };
    case actionTypes.SORT_QUESTION_UP:
      idx = state.survey.question_order.indexOf(action.questionId);
      newOrder = [...state.survey.question_order];
      newOrder[idx] = newOrder[idx - 1];
      newOrder[idx - 1] = action.questionId;
      return {
        ...state,
        survey: {
          ...state.survey,
          question_order: newOrder,
        },
      };
    case actionTypes.SORT_GROUP_QUESTION_UP:
      groupInd = action.payload.groupInd;
      questInd = action.payload.questInd;

      updated_survey = { ...state.survey };
      inversed =
        updated_survey.grouped_questions[groupInd].questions[questInd - 1];

      updated_survey.grouped_questions[groupInd].questions[questInd - 1] =
        updated_survey.grouped_questions[groupInd].questions[questInd];

      updated_survey.grouped_questions[groupInd].questions[questInd] = inversed;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.SORT_UNGROUP_QUESTION_UP:
      newOrder = [...state.survey.ungrouped_questions];
      newOrder[action.payload.questInd] = newOrder[action.payload.questInd - 1];
      newOrder[action.payload.questInd - 1] = action.payload.questId;
      return {
        ...state,
        survey: {
          ...state.survey,
          ungrouped_questions: newOrder,
        },
      };
    case actionTypes.SORT_QUESTION_DOWN:
      idx = state.survey.question_order.indexOf(action.questionId);
      newOrder = [...state.survey.question_order];
      newOrder[idx] = newOrder[idx + 1];
      newOrder[idx + 1] = action.questionId;
      return {
        ...state,
        survey: {
          ...state.survey,
          question_order: newOrder,
        },
      };
    case actionTypes.SORT_GROUP_QUESTION_DOWN:
      groupInd = action.payload.groupInd;
      questInd = action.payload.questInd;

      updated_survey = { ...state.survey };
      inversed =
        updated_survey.grouped_questions[groupInd].questions[questInd + 1];

      updated_survey.grouped_questions[groupInd].questions[questInd + 1] =
        updated_survey.grouped_questions[groupInd].questions[questInd];

      updated_survey.grouped_questions[groupInd].questions[questInd] = inversed;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.SORT_UNGROUP_QUESTION_DOWN:
      // idx = state.survey.question_order.indexOf(action.questionId);
      newOrder = [...state.survey.ungrouped_questions];
      newOrder[action.payload.questInd] = newOrder[action.payload.questInd + 1];
      newOrder[action.payload.questInd + 1] = action.payload.questId;
      return {
        ...state,
        survey: {
          ...state.survey,
          ungrouped_questions: newOrder,
        },
      };
    case actionTypes.CLONE_QUESTION:
      idx = state.survey.question_order.indexOf(action.payload.questionId);
      newOrder = [...state.survey.question_order];
      newOrder.splice(idx + 1, 0, action.payload._id);
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: {
            ...state.survey.questions,
            [action.payload._id]: {
              ...action.payload.question,
              _id: action.payload._id,
            },
          },
          question_order: [...newOrder],
        },
      };
    case actionTypes.CLONE_GROUP_QUESTION:
      groupInd = action.payload.groupInd;
      questInd = action.payload.questInd;
      idx = state.survey.question_order.indexOf(action.payload.questionId);
      updated_survey = { ...state.survey };
      updated_survey.grouped_questions[groupInd].questions.splice(
        questInd + 1,
        0,
        action.payload._id
      );
      updated_survey.question_order.splice(idx + 1, 0, action.payload._id);
      updated_survey.questions[action.payload._id] = {
        ...action.payload.question,
        _id: action.payload._id,
      };
      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.CLONE_UNGROUP_QUESTION:
      questInd = action.payload.questInd;
      idx = state.survey.question_order.indexOf(action.payload.questionId);
      updated_survey = { ...state.survey };
      updated_survey.ungrouped_questions.splice(
        questInd + 1,
        0,
        action.payload._id
      );
      updated_survey.question_order.splice(idx + 1, 0, action.payload._id);
      updated_survey.questions[action.payload._id] = {
        ...action.payload.question,
        _id: action.payload._id,
      };
      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.REMOVE_QUESTION:
      idx = state.survey.question_order.indexOf(action.questionId);
      let newQuestions = { ...state.survey.questions };
      delete newQuestions[action.questionId];
      return {
        ...state,
        survey: {
          ...state.survey,
          questions: newQuestions,
          question_order: [
            ...state.survey.question_order.slice(0, idx),
            ...state.survey.question_order.slice(idx + 1),
          ],
        },
      };
    case actionTypes.REMOVE_GROUP_QUESTION:
      groupInd = action.payload.groupInd;
      questInd = action.payload.questInd;

      updated_survey = { ...state.survey };

      removed_id = updated_survey.grouped_questions[groupInd].questions.splice(
        questInd,
        1
      );
      updated_survey.ungrouped_questions.push(removed_id[0]);
      if (updated_survey.grouped_questions[groupInd].questions.length == 0) {
        updated_survey.grouped_questions.splice(groupInd, 1);
      }
      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.REMOVE_UNGROUP_QUESTION:
      questInd = action.payload.questInd;

      updated_survey = { ...state.survey };

      removed_id = updated_survey.ungrouped_questions.splice(questInd, 1);
      delete updated_survey.questions[removed_id[0]];
      idx = updated_survey.question_order.indexOf(removed_id[0]);

      updated_survey.question_order.splice(idx, 1);

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.CREATE_COMPARATVE_SURVEY:
      updated_survey = { ...state.survey };
      if (updated_survey.type === actionTypes.COMPARATIVE) {
        updated_survey.type = actionTypes.REGULAR;
        delete updated_survey.surveyee_count;
        delete updated_survey.ungrouped_questions;
        delete updated_survey.grouped_questions;
        delete updated_survey.surveyee_list;
      } else {
        updated_survey.type = actionTypes.COMPARATIVE;
        updated_survey.surveyee_count = 0;
        updated_survey.ungrouped_questions = [...updated_survey.question_order];
        updated_survey.grouped_questions = [];
        updated_survey.surveyee_list = [];
        updated_survey.ratingIndex = "";
        updated_survey.ratingIndexAsFootnote = false;
      }

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    //Table reducers starts here

    case actionTypes.SURVEYEE_COUNT_CHANGE:
      updated_survey = { ...state.survey };
      if (action.payload === "DEC") {
        updated_survey.surveyee_count = updated_survey.surveyee_count - 1;
        updated_survey.surveyee_list.pop();
      } else if (action.payload === "INC") {
        updated_survey.surveyee_list.push({ name: "", email: "" });

        updated_survey.surveyee_count = updated_survey.surveyee_count + 1;
      }
      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };

    case actionTypes.SURVEYEE_UPDATE:
      updated_survey = { ...state.survey };
      updated_survey.surveyee_list = action.payload.value;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.SURVEYEE_REMOVE:
      updated_survey = { ...state.survey };
      updated_survey.surveyee_list = action.payload.finalList;
      updated_survey.surveyee_count = updated_survey.surveyee_count - 1;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    case actionTypes.SURVEYEE_REARRANGE:
      newOrder = action.payload.newOrder;

      updated_survey = { ...state.survey };

      updated_survey.surveyee_list = newOrder;

      return {
        ...state,
        survey: {
          ...updated_survey,
        },
      };
    //Table reducers ends here

    case actionTypes.GET_SURVEY_ID:
      return {
        ...state,
        survey: {
          ...state.survey,
          id: action.surveyId,
        },
      };

    case actionTypes.GET_COMPANY_ID:
      return {
        ...state,
        company: {
          ...state.company,
          id: action.compId,
          logo: action.logo,
          name: action.name,
          status: action.status,
          activeTill: action.activeTill,
          address: action.address,
          cin: action.cin,
          role: action.role,
        },
      };

    case actionTypes.RESET_OPENTILL:
      return {
        ...state,
        survey: {
          ...state.survey,
          id: action.surveyId,
          openTill: action.openTill,
        },
      };
    case actionTypes.RESET_SURVEY_ID:
      return {
        ...state,
        survey: {
          ...state.survey,
          id: "",
          title: "",
          subTitle: "",
          openTill: "",
          questions: {},
          question_order: [],
          creatorDate: "",
          lastModified: "",
          submitting: false,
        },
        redirect: false,
      };
    case actionTypes.RESET_COMPANY_ID:
      return {
        ...state,
        company: {
          ...state.company,
          id: "",
          logo: "",
          name: "",
          status: "",
          activeTill: "",
        },
        redirect: false,
      };
    case actionTypes.REDIRECT_PAGE:
      return {
        ...state,
        redirect: true,
      };
    case actionTypes.TOGGLE_SUBMIT_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          submitting: !state.survey.submitting,
        },
      };
    case actionTypes.TOGGLE_SUBMIT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.OPEN_MODAL_EDITER:
      return updateObject(state, { showModal: true });
    case actionTypes.CLOSE_MODAL_EDITER:
      return updateObject(state, { showModal: false });

    case "SET_EMAIL_GROUPS":
      return {
        ...state,
        company: {
          ...state.company,
          emailGroups: action.emailGroups,
        },
      };
    case "SET_PENDING_INVITES":
      return {
        ...state,
        company: {
          ...state.company,
          emailGroups: [action.pendingInvites, ...state.company.emailGroups],
        },
      };
    default:
      return state;
  }
};

export default reducer;
