import React from "react";

import classes from "./Toolbar.module.css";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems.js";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import CompanyLogo from "../../CompanyLogo/CompanyLogo";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const toolbar = (props) => (
  <header className={classes.Toolbar}>
    <DrawerToggle clicked={props.drawerToggleClicked} />
    <div className={classes.Logo}>
      <Logo />
    </div>
    <div className={classes.companyDetail}>
      {window.selectedCompany ? <CompanyLogo /> : <div></div>}
    </div>
    <div className={classes.NavContainer}>
      <nav className={classes.DesktopOnly}>
        <NavigationItems isAuthenticated={props.isAuth} email={props.email} />
      </nav>
      <Button
        variant="success"
        href="https://desk.zoho.com/portal/cimplyfive/en/kb/cimplybest"
        target="_blank"
        style={{
          padding: "10px",
          marginRight: "0px",
          height: "fit-content",
          borderColor: "#34baf2",
        }}
      >
        <FontAwesomeIcon
          icon={faQuestionCircle}
          style={{ marginRight: "10px" }}
        />
        Help
      </Button>
    </div>
  </header>
);

export default toolbar;
