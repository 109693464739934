import React from "react";
import error from "./error.svg";
import classes from "./ErrorPage.module.css";
import { Link } from "react-router-dom";
const ErrorPage = () => {
  return (
    <div className={classes.ErrorContainer}>
      <img src={error} alt="error" className={classes.ErrorImg}></img>
      <h2 className={classes.Error}>Whoops!</h2>
      <h4 className={classes.ErrorMsg}>
        We could not find the page you are looking for
      </h4>
      <Link to="/">
        <button className={classes.Return}>Return To Homepage</button>
      </Link>
    </div>
  );
};

export default ErrorPage;
