import * as actionTypes from "./actionsTypes";
import firebase from "firebase";
import { db } from "../../firebase-config";

export const fetchLogsSuccess = (logs) => {
  return {
    type: actionTypes.FETCH_LOGS_SUCCESS,
    logs: logs,
  };
};

export const fetchLogsFail = (err) => {
  return {
    type: actionTypes.FETCH_LOGS_FAIL,
    error: err,
  };
};

export const fetchLogsStart = () => {
  return {
    type: actionTypes.FETCH_LOGS_START,
  };
};

export const fetchInviteeSuccess = (invitee) => {
  return {
    type: actionTypes.FETCH_INVITEE_SUCCESS,
    invitee: invitee,
  };
};

export const fetchInviteeFail = (err) => {
  return {
    type: actionTypes.FETCH_INVITEE_FAIL,
    error: err,
  };
};

export const fetchInviteeStart = () => {
  return {
    type: actionTypes.FETCH_INVITEE_START,
  };
};

export const fetchTemplatesSuccess = (templates) => {
  return {
    type: actionTypes.FETCH_TEMPLATES_SUCCESS,
    templates: templates,
  };
};

export const fetchTemplatesFail = (err) => {
  return {
    type: actionTypes.FETCH_TEMPLATES_FAIL,
    error: err,
  };
};

export const fetchTemplatesStart = () => {
  return {
    type: actionTypes.FETCH_TEMPLATES_START,
  };
};

export const fetchAdminLogsSuccess = (adminlogs) => {
  return {
    type: actionTypes.FETCH_ADMINLOGS_SUCCESS,
    adminlogs: adminlogs,
  };
};

export const fetchAdminLogsFail = (err) => {
  return {
    type: actionTypes.FETCH_ADMINLOGS_FAIL,
    error: err,
  };
};

export const fetchAdminLogsStart = () => {
  return {
    type: actionTypes.FETCH_ADMINLOGS_START,
  };
};

export const fetchLogs = (compId) => {
  return (dispatch) => {
    dispatch(fetchLogsStart());
    db.collection(`logs/${compId}/logsList`)
      .orderBy("timestamp", "desc")
      .onSnapshot(
        (docs) => {
          const logsData = [];
          docs.forEach((doc) => logsData.push({ ...doc.data(), id: doc.id }));
          dispatch(fetchLogsSuccess(logsData));
        },
        (err) => {
          dispatch(fetchLogsFail(err));
        }
      );
  };
};

export const fetchInvitee = (compId, surveyId) => {
  return (dispatch) => {
    dispatch(fetchInviteeStart());
    db.collection(`mailAudit/${surveyId}/mailAuditList`)
      .where("type", "in", ["SURVEY", "CONSOLIDATED"])
      .onSnapshot(
        (snapshot) => {
          const mailAuditData = [];
          snapshot.forEach((doc) =>
            mailAuditData.push({ ...doc.data(), id: doc.id })
          );

          let fetchInvitee = [];
          for (let val of mailAuditData) {
            if (val["deliveredEmails"] && val["deliveredEmails"].length > 0) {
              val["deliveredEmails"].map((email, i) => {
                if (fetchInvitee.indexOf(email) < 0) {
                  fetchInvitee.push(email);
                }
              });
            }
          }
          fetchInvitee.reverse();
          dispatch(fetchInviteeSuccess(fetchInvitee));
        },
        (err) => {
          dispatch(fetchInviteeFail(err));
        }
      );
  };
};

export const fetchTemplates = () => {
  return (dispatch) => {
    dispatch(fetchTemplatesStart());

    db.collection("templates").onSnapshot(
      (snapshot) => {
        const fetchTemplates = [];
        snapshot.forEach((doc) =>
          fetchTemplates.push({ ...doc.data(), id: doc.id })
        );
        dispatch(fetchTemplatesSuccess(fetchTemplates));
      },
      (err) => {
        dispatch(fetchTemplatesFail(err));
      }
    );
  };
};

export const fetchAdminlogs = () => {
  return (dispatch) => {
    dispatch(fetchAdminLogsStart());
    db.collection("adminlogs").onSnapshot(
      (snapshot) => {
        const adminlogsData = [];
        snapshot.forEach((doc) =>
          adminlogsData.push({ ...doc.data(), id: doc.id })
        );
        adminlogsData.reverse();
        dispatch(fetchAdminLogsSuccess(adminlogsData));
      },
      (err) => {
        dispatch(fetchAdminLogsFail(err));
      }
    );
  };
};
