import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import newId from "../../../ulitity/idGenerator";
import Notiflix from "notiflix-react";
import firebase from "firebase";
import { fetchTemplates } from "../../../Store/actions/logs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./NewSurvey.module.css";
import { fetchSurveys } from "../../../Store/actions/surveys";
import * as actionTypes from "../../../Store/actions/actionsTypes";
import NewSurveyView from "../../../Components/NewSurvey/NewSurvey";
import { InitQuestions } from "../../../ulitity/constants/Questions";
import { db } from "../../../firebase-config";

class NewSurvey extends Component {
  state = {
    _id: "",
    userId: "",
    content: {
      title: "Your Survey Title",
      subTitle: "",
      creatorDate: "",
      openTill: new Date().toISOString().split("T")[0] + "T23:59",
      lastModified: "",
    },
  };

  componentDidMount() {
    this.props.onFetchTemplates();
    this.props.onFetchSurveys(this.props.token, this.props.user_Id);
  }

  deleteTemplateHandler = (templateId) => {
    db.collection("templates")
      .doc(String(templateId))
      .delete()
      .then(() => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Success(
          "Template Deleted",
          "The template has been deleted successfully",
          "ok"
        );
      })
      .catch((err) => {
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Template Deletion Failed",
          "An unexpected error ocurred, please try again" + err,
          "ok"
        );
      });
  };

  clickHandler = (templateId) => {
    if (this.props.status === "Active") {
      if (this.props.role === "Admin" || this.props.role === "Editor") {
        const companyId = this.props.compId;
        var component = this;
        if (templateId === undefined) {
          var sampleQuestion;
          var questionObj;
          sampleQuestion = InitQuestions["SINGLE_LINE_TEXT"]();
          questionObj = {
            [sampleQuestion._id]: sampleQuestion,
          };
          var orderQuestions;
          let questions = [sampleQuestion._id];
          orderQuestions = questions.map(
            (questionId) => questionObj[questionId]
          );
          let newStateValue = {
            _id: newId(),
            userId: this.props.user_Id,
            content: {
              title: "Survey Title",
              subTitle: "Survey Subtitle",
              creatorDate: Date(),
              lastModified: Date(),
              openTill: new Date().toISOString().split("T")[0] + "T23:59",
              questions: orderQuestions,
              submitting: true,
            },
          };

          db.collection(`surveys/${companyId}/surveysList`)
            .doc(String(newStateValue._id))
            .set(newStateValue)
            .then(() => {
              db.collection(`logs/${companyId}/logsList`)
                .add({
                  name: firebase.auth().currentUser.displayName,
                  email: firebase.auth().currentUser.email,
                  timestamp: new Date().getTime(),
                  entity: "Surveys",
                  operation: "CREATED",
                  payload: newStateValue,
                })
                .then(() => {
                  Notiflix.Loading.Remove();
                  component.props.onGetSurveyId(newStateValue._id);
                })
                .catch((err) => {
                  alert(err);
                });
            })
            .catch((error) => {
              alert(error);
            });
        } else {
          db.collection("templates")
            .doc(String(templateId))
            .get()
            .then((doc) => {
              const templateData = { ...doc.data(), id: doc.id };
              const { content = {} } = templateData;
              let newStateValue = {
                _id: newId(),
                userId: component.props.user_Id,
                content: {
                  title: content.title,
                  subTitle: content.subTitle,
                  creatorDate: Date(),
                  lastModified: Date(),
                  openTill: new Date().toISOString().split("T")[0] + "T23:59",
                  questions: content.questions,
                  submitting: true,
                },
              };

              db.collection(`surveys/${companyId}/surveysList`)
                .doc(String(newStateValue._id))
                .set(newStateValue)
                .then(() => {
                  db.collection(`logs/${companyId}/logsList`)
                    .add({
                      name: firebase.auth().currentUser.displayName,
                      email: firebase.auth().currentUser.email,
                      timestamp: new Date().getTime(),
                      entity: "Surveys",
                      operation: "CREATED",
                      payload: newStateValue,
                    })
                    .then(() => {
                      Notiflix.Loading.Remove();
                      component.props.onGetSurveyId(newStateValue._id);
                    })
                    .catch((err) => {
                      alert(err);
                    });
                });
            });
        }
      } else {
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Rights Mismatch",
          "Only Admins and Editor can create new surveys. You do not have the correct rights",
          "ok"
        );
      }
    } else {
      Notiflix.Loading.Remove();
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "Company Inactive",
        "Please pay the annual fees to continue services",
        "ok"
      );
    }
  };

  render() {
    return (
      <div
        style={{
          display:
            this.props.role === "Admin" || this.props.role === "Editor"
              ? "flex"
              : "none",
        }}
        className={classes.newsurvbtn}
      >
        <NewSurveyView
          clicked={this.clickHandler}
          templates={this.props.templates}
          deleteTemplate={this.deleteTemplateHandler}
          isLoading={this.props.loading}
          error={this.props.isError}
        />
        <div className={classes.survcont}>
          <h5>
            {this.props.surveylength +
              (this.props.surveylength === 1 ? " Survey" : " Surveys")}
          </h5>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    templates: state.logs.templates,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    role: state.surveyEditer.company.role,
    compId: state.surveyEditer.company.id,
    surveylength: state.surveys.surveys.length,
    status: state.surveyEditer.company.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSurveys: (token, userId) => dispatch(fetchSurveys(token, userId)),
    onFetchTemplates: () => dispatch(fetchTemplates()),
    onGetSurveyId: (surveyId) =>
      dispatch({ type: actionTypes.GET_SURVEY_ID, surveyId: surveyId }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewSurvey)
);
