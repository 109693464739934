import * as actionTypes from "./actionsTypes";
import firebase from "firebase";
import moment from "moment";
import { db } from "../../firebase-config";
import { func } from "prop-types";

export const fetchCompaniesSuccess = (companies) => {
  return {
    type: actionTypes.FETCH_COMPANIES_SUCCESS,
    companies: companies,
  };
};

export const fetchCompaniesFail = (err) => {
  return {
    type: actionTypes.FETCH_COMPANIES_FAIL,
    error: err,
  };
};

export const fetchCompaniesStart = () => {
  return {
    type: actionTypes.FETCH_COMPANIES_START,
  };
};

export const fetchCompanies = () => {
  return (dispatch) => {
    window.selectedCompany = null;
    dispatch(fetchCompaniesStart());
    const emailID = localStorage.getItem("email");

    db.collectionGroup("roles")
      .where("email", "==", emailID)
      .get()
      .then((docs) => {
        const rolesData = [],
          companiesData = [],
          companyIDs = [],
          promisesList = [];
        let temp = [];

        // Creating an array of companyID-arrays [[...],[...]...]
        docs.forEach((doc) => rolesData.push({ ...doc.data() }));
        for (let i = 0; i < rolesData.length; i++) {
          if (i > 0 && i % 10 === 0) {
            companyIDs.push(temp);
            temp = [];
          }
          temp.push(rolesData[i].companyID);
        }
        if (temp.length) companyIDs.push(temp);

        // creating array of promises
        for (let ids of companyIDs) {
          promisesList.push(
            db.collection("company").where("_id", "in", ids).get()
          );
        }

        Promise.all(promisesList)
          .then((values) => {
            for (let val of values) {
              val.forEach((doc) =>
                companiesData.push({ ...doc.data(), id: doc.id })
              );
            }

            companiesData.sort((a, b) => a.name.localeCompare(b.name));
            dispatch(fetchCompaniesSuccess(companiesData));
          })
          .catch((err) => dispatch(fetchCompaniesFail(err)));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err));
      });
  };
};

export const fetchCompaniesInactive = () => {
  return (dispatch) => {
    dispatch(fetchCompaniesStart());
    const emailID = localStorage.getItem("email");

    db.collectionGroup("roles")
      .where("email", "==", emailID)
      .get()
      .then((docs) => {
        const rolesData = [],
          companiesData = [],
          companyIDs = [],
          promisesList = [];
        let temp = [];

        // Creating an array of companyID-arrays [[...],[...]...]
        docs.forEach((doc) => rolesData.push({ ...doc.data() }));
        for (let i = 0; i < rolesData.length; i++) {
          if (i > 0 && i % 10 === 0) {
            companyIDs.push(temp);
            temp = [];
          }
          temp.push(rolesData[i].companyID);
        }
        if (temp.length) companyIDs.push(temp);

        // creating array of promises
        for (let ids of companyIDs) {
          promisesList.push(
            db.collection("company").where("_id", "in", ids).get()
          );
        }

        Promise.all(promisesList)
          .then((values) => {
            for (let val of values) {
              val.forEach((doc) =>
                companiesData.push({ ...doc.data(), id: doc.id })
              );
            }

            const inactive = companiesData.filter(function (item) {
              return moment().format("DD/MM/YYYY") === item.status;
            });
            inactive.sort((a, b) => a.name.localeCompare(b.name));
            dispatch(fetchCompaniesSuccess(inactive));
          })
          .catch((err) => dispatch(fetchCompaniesFail(err)));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err));
      });
  };
};

export const fetchCompaniesActive = () => {
  return (dispatch) => {
    dispatch(fetchCompaniesStart());
    const emailID = localStorage.getItem("email");

    db.collectionGroup("roles")
      .where("email", "==", emailID)
      .get()
      .then((docs) => {
        const rolesData = [],
          companiesData = [],
          companyIDs = [],
          promisesList = [];
        let temp = [];

        // Creating an array of companyID-arrays [[...],[...]...]
        docs.forEach((doc) => rolesData.push({ ...doc.data() }));
        for (let i = 0; i < rolesData.length; i++) {
          if (i > 0 && i % 10 === 0) {
            companyIDs.push(temp);
            temp = [];
          }
          temp.push(rolesData[i].companyID);
        }
        if (temp.length) companyIDs.push(temp);

        // creating array of promises
        for (let ids of companyIDs) {
          promisesList.push(
            db.collection("company").where("_id", "in", ids).get()
          );
        }

        Promise.all(promisesList)
          .then((values) => {
            for (let val of values) {
              val.forEach((doc) =>
                companiesData.push({ ...doc.data(), id: doc.id })
              );
            }

            const inactive = companiesData.filter(function (item) {
              return moment().format("DD/MM/YYYY") !== item.status;
            });
            inactive.sort((a, b) => a.name.localeCompare(b.name));
            dispatch(fetchCompaniesSuccess(inactive));
          })
          .catch((err) => dispatch(fetchCompaniesFail(err)));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err));
      });
  };
};
