import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Survey from "../Components/Survey/Survey";
import { assembleSurvey } from "../Store/actions/surveyEditer";
import { submitSurvey } from "../Store/actions/survey";
import Alert from "@material-ui/lab/Alert";

import classes from "./TestPage.module.css";
import firebase from "firebase";

class TestPage extends Component {
  componentDidMount() {
    firebase.analytics().logEvent("Survey Test page rendered");
  }

  render() {
    return (
      <div className={classes.TextPage}>
        <Alert
          severity="info"
          style={{
            fontSize: "18px",
            display: this.props.role === "Auditor" ? "none" : null,
          }}
          className={classes.Title}
        >
          You are currently in test mode, this is how your survey will look to
          the respondents
        </Alert>
        <div
          style={{
            backgroundColor: "#EAEAEA",
            marginBottom: "10px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Survey {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    survey: assembleSurvey(state.surveyEditer.survey),
    questionsObj: state.surveyEditer.survey,
    role: state.surveyEditer.company.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: bindActionCreators(submitSurvey, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
