import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditSurveyPanelView from "../../Components/EditPanel/EditSurveyPanel";
import { updateSurveyHeader } from "../../Store/actions/surveyEditer";
import React, { Component } from "react";
import { CHANGE_SURVEY_STATE } from "../../Store/actions/actionsTypes";

class EditSurveyPanelViewContainer extends Component {
  render() {
    return <EditSurveyPanelView {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.surveyEditer.survey.title,
    subTitle: state.surveyEditer.survey.subTitle,
    ratingIndex: state.surveyEditer.survey.ratingIndex,
    type: state.surveyEditer.survey.type,
    ratingIndexAsFootnote: state.surveyEditer.survey.ratingIndexAsFootnote,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: bindActionCreators(updateSurveyHeader, dispatch),
    changeSurveyState: () =>
      dispatch({ type: CHANGE_SURVEY_STATE, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSurveyPanelViewContainer);
