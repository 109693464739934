import React, { useEffect, useState } from "react";
import Notiflix from "notiflix-react";
import classes from "./UserSurveyPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPollH } from "@fortawesome/free-solid-svg-icons";
import http from "../lib/http-service";
import firebase from "firebase";

export default function ConsolidatedViewPage() {
  let [data, setData] = React.useState([]);
  let [name, setName] = React.useState("");
  let [logo, setLogo] = React.useState("");
  let [loading, setLoading] = React.useState(true);
  useEffect(() => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Getting Data");
    let hash = window.location.pathname.split("/")[2];
    let consolidatedid = window.location.pathname.split("/")[3];
    let metaid = window.location.pathname.split("/")[4];
    getConsolidatedData(hash, consolidatedid, metaid);
  }, []);

  const getConsolidatedData = async (hash, consolidatedid, metaid) => {
    firebase.auth().onAuthStateChanged((user) => {
      http
        .get(
          `/getConsolidatedData?consolidatedId=${consolidatedid}&metaId=${metaid}&hash=${hash}`
        )
        .then((res) => {
          const { data } = res;
          if (typeof data.openSurveys == "object") {
            Notiflix.Loading.Remove();
            setData(data.openSurveys);
            setName(data.name);
            setLogo(data.logo);
            setLoading(false);
          } else {
            Notiflix.Loading.Remove();
            Notiflix.Report.Failure("Invalid Link", "This link does not exist");
            setLoading(false);
          }
        });
    });
  };

  function redirectToSurvey(item) {
    window.open(`/${item.hash}/${item.metaId}/start`);
  }

  return (
    <div>
      {loading ? null : (
        <div>
          {data.length === 0 ? (
            <div className={classes.empty}>
              <FontAwesomeIcon
                icon={faPollH}
                size="5x"
                style={{ marginRight: 20 }}
              />
              <h1>No Surveys Available</h1>
            </div>
          ) : (
            <div>
              <div
                className={classes.companyHeader}
                style={{
                  display: logo === "" ? "none" : null,
                  backgroundColor: "#F7F7F7",
                }}
              >
                <div className={classes.compinfo}>
                  <img src={logo} className={classes.logo} alt=""></img>

                  <h5 className={classes.Name}>{name}</h5>
                </div>
              </div>
              <div
                style={{
                  marginTop: 50,
                  paddingLeft: 100,
                  paddingRight: 100,
                  marginBottom: 50,
                }}
              >
                {data.map((item, index) => {
                  return (
                    <div key={index} className={classes.SurveyItem}>
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <FontAwesomeIcon
                          icon={faPollH}
                          style={{
                            fontSize: "30px",
                            color: "#495058",
                            marginTop: "12px",
                            marginRight: "15px",
                          }}
                        />
                        <div>
                          <h3>{item.surveyTitle}</h3>
                          <p>
                            <b>Open Till: </b>
                            {item.openTill.split("T")[0] +
                              " " +
                              item.openTill.split("T")[1]}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          alignContent: "center",
                          justifyItems: "center",
                        }}
                      >
                        <button
                          className={classes.DeleteButton}
                          onClick={() => redirectToSurvey(item)}
                        >
                          Take Survey
                        </button>
                      </div>
                    </div>
                  );
                })}
                <br />
                <br />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
