import CONSTANTS from "../constants";
import { COMPARATIVE } from "../Store/actions/actionsTypes";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const isEmty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const lastSubmitDate = (results, creatorDate) => {
  if (results.length <= 0) {
    return "No Responses Yet";
  } else {
    let lastDate = new Date(creatorDate);
    results.forEach((result) => {
      if (new Date(result.submitDate) > lastDate) {
        lastDate = new Date(result.submitDate);
      }
    });
    return lastDate.toString();
  }
};

export const currentResultToday = (results) => {
  let currentSubmit = 0;
  const today = new Date().toDateString();
  results.forEach((results) => {
    if (new Date(results.submitDate).toDateString() === today) {
      currentSubmit++;
    }
  });
  return currentSubmit;
};

export const arrayContainsAnotherArray = (
  needle,
  haystack,
  type,
  surveyee_list,
  assembledData = {}
) => {
  if (type !== COMPARATIVE) {
    for (let i = 0; i < needle.length; i++) {
      if (haystack.indexOf(needle[i]) === -1) return false;
    }
    return true;
  } else if (type === COMPARATIVE) {
    for (let i = 0; i < needle.length; i++) {
      if (haystack.indexOf(needle[i]) === -1) {
        return false;
      } else {
        let ansCount = 0;
        for (let j = 0; j < assembledData[needle[i]].length; j++) {
          if (!assembledData[needle[i]][j]) {
            return false;
          }
          ansCount += 1;
        }
        if (ansCount != surveyee_list.length) {
          return false;
        }
      }
    }
    return true;
  }
};

export const getResponders = (results) => {
  let responders = [];
  results.forEach((result) => {
    if (
      result.surveyResponder != null &&
      result.surveyResponder != "" &&
      responders.indexOf(result.surveyResponder) < 0
    ) {
      responders.push(result.surveyResponder);
    }
  });
  return responders;
};

export const onLoginClickFromUserSurvey = () => {
  let relativeSurveyPath = window.location.pathname;
  let isUserSurveyPage =
    relativeSurveyPath.split("/").lastIndexOf("start") === 3 ? true : false;
  if (isUserSurveyPage) {
    localStorage.setItem("redirectToSurvey", "true");

    // Deploy local storage set item part
    localStorage.setItem(
      "redirectURL",
      window.location.origin + relativeSurveyPath
    );

    // // test local storage set item part in localhost:3000
    // localStorage.setItem('redirectURL', 'http://localhost:3000' + relativeSurveyPath);
  } else {
    localStorage.removeItem("redirectToSurvey");
    localStorage.removeItem("redirectURL");
  }
};
