import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminLogItem from "../../Components/AdminLogList/AdminLogItem";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { fetchAdminlogs } from "../../Store/actions/logs";

class AdminLogList extends Component {
    componentDidMount() {
        this.props.onFetchAdminLogs();
    }

    render() {
        let adminlogList = <Spinner />;
        if (!this.props.loading) {
            adminlogList =
                <div>
                    <AdminLogItem
                        adminlogs={this.props.adminlogs}
                    />
                </div>
        }
        return <div>{adminlogList}</div>;
    }
}

const mapStateToProps = state => {
    return {
        compId: state.surveyEditer.company.id,
        adminlogs: state.logs.adminlogs,
        loading: state.logs.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAdminLogs: () => dispatch(fetchAdminlogs())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLogList));