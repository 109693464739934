import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classes from "./MailAudit.module.css";
import { fetchMailAudits } from "../Store/actions/mailAudit";
import firebase from 'firebase';

class MailAudit extends Component {
    componentDidMount() {
        this.props.onFetchMailAudits(this.props.token, this.props.userId);
        firebase.analytics().logEvent('Mail Audit page is rendered');
    }

    render() {

        let data = (
            <h1>No Data</h1>
        )
        // if(this.props.mailAudits) {
        //     data = ({this.props.mailAudits.map((audit,i) => {
        //         <ul>
        //             <li>{audit.targetId}</li>
        //         </ul>
        //     })
        //     )};
        // }
        return (
            <div>{data}</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_Id: state.auth.userId,
        token: state.auth.token,
        surveyId: state.surveyEditer.survey.id,
        compId: state.surveyEditer.company.id,
        compname: state.surveyEditer.company.name,
        survname: state.surveyEditer.survey.title,
        status: state.surveyEditer.company.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchMailAudits: (token, userId) => dispatch(fetchMailAudits(token, userId))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MailAudit));