import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Icon from "@material-ui/core/Icon";
import classes from "./NewCompany.module.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core";
import newId from "../../ulitity/idGenerator";
import firebase from "firebase";
import Notiflix from "notiflix-react";
import Alert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { confirmAlert } from "react-confirm-alert";
import FileInputComponent from "react-file-input-previews-base64";
import { db, storage } from "../../firebase-config";

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    marginBottom: "15px",
    minWidth: "230px",
    width: "37vw",
    marginRight: "15px",
    borderRadius: 4,
    display: "block",
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#34baf2",
    },
  },
  focused: {},
}));

function RedditTextField(props) {
  const classes = useStylesReddit();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

const NewCompany = (props) => {
  const [open, setOpen] = React.useState(false);
  const [logoDetails, setLogoDetails] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const checkCredits = () => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Checking Credits");

    db.collection("users")
      .doc(String(firebase.auth().currentUser.uid))
      .get()
      .then((doc) => {
        Notiflix.Loading.Remove();

        if (!doc?.exists) return;

        if (doc.data().credits <= 0) {
          confirmAlert({
            title: "Not Enough Credits",
            message: "You do not have enough credits to add a new company.",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
          return;
        }

        handleClickOpen();
      });
  };

  const handleClose = () => {
    localStorage.removeItem("cname");
    localStorage.removeItem("address");
    localStorage.removeItem("cin");
    localStorage.removeItem("logobase64");
    setOpen(false);
  };

  const saveDataToDB = (imageURL, companyData, emailID, adminrole) => {
    companyData.logo = imageURL;

    db.collection("company")
      .doc(String(companyData._id))
      .set(companyData)
      .then((res) => {
        db.collection(`company/${companyData._id}/roles`)
          .doc(String(emailID))
          .set(adminrole)
          .then((res) => {
            db.collection("users")
              .doc(String(firebase.auth().currentUser.uid))
              .update({
                credits: firebase.firestore.FieldValue.increment(-1),
              })
              .then(() => {
                db.collection(`logs/${companyData._id}/logsList`)
                  .add({
                    name: firebase.auth().currentUser.displayName,
                    email: firebase.auth().currentUser.email,
                    timestamp: new Date().getTime(),
                    entity: "Company",
                    operation: "CREATED",
                    payload: companyData,
                  })
                  .then(() => {
                    localStorage.removeItem("logobase64");
                    Notiflix.Loading.Remove();
                    window.location.reload();
                  });
              });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadImage = (companyData, emailID, adminrole) => {
    // Create the file metadata
    const metadata = {
      contentType: logoDetails.type,
    };
    const logoType = logoDetails.type.split("/")[1];

    // Upload file and metadata to the object
    const storageRef = storage.ref();
    const uploadTask = storageRef
      .child(`companyLogo/${companyData._id}/${companyData._id}.${logoType}`)
      .put(logoDetails.file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {},
      (error) => {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Init({
          position: "right-bottom",
          fontSize: "17px",
        });
        Notiflix.Notify.Failure("Something went wrong...");
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          saveDataToDB(downloadURL, companyData, emailID, adminrole);
        });
      }
    );
  };

  const createCompany = () => {
    const emailID = firebase.auth().currentUser.email;

    const companyData = {
      _id: newId(),
      name: sessionStorage.getItem("cname"),
      address: localStorage.getItem("address"),
      cin: localStorage.getItem("cin"),
      uid: localStorage.getItem("userId"),
      registeredOn: new Date().getTime(),
      status: "Active",
    };

    const adminrole = {
      name: firebase.auth().currentUser.displayName,
      role: "Admin",
      email: firebase.auth().currentUser.email,
      companyID: companyData._id,
    };

    const date = new Date(companyData.registeredOn + 3.154e10).getDate();
    const month = new Date(companyData.registeredOn + 3.154e10).getMonth() + 1;
    const year = new Date(companyData.registeredOn + 3.154e10).getFullYear();
    companyData.activeTill = `${date}/${month}/${year}`;
    db.collection("users")
      .doc(String(firebase.auth().currentUser.uid))
      .get()
      .then((doc) => {
        Notiflix.Loading.Remove();
        if (!doc?.exists) return;

        if (doc.data().credits <= 0) {
          handleClose();
          confirmAlert({
            title: "Not Enough Credits",
            message: "You do not have enough credits to add a new company.",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
          return;
        }

        if (
          localStorage.getItem("logobase64") == null ||
          localStorage.getItem("logobase64") == ""
        ) {
          Notiflix.Notify.Failure("Please attach a logo under 1MB first");
        } else {
          Notiflix.Loading.Pulse("Uploading Logo And Creating Company");
          uploadImage(companyData, emailID, adminrole);
        }
      });
  };

  return (
    <div className={classes.CreateSurvey}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        className={classes.Dialog}
      >
        <DialogTitle className={classes.DialogTitle}>
          Add New Company
        </DialogTitle>
        <DialogContent>
          <h5 className={classes.Info}>
            Enter the following details to register a new Company
          </h5>
          <div className={classes.group}>
            <RedditTextField
              label="Company Name"
              className={classes.margin}
              variant="filled"
              id="reddit-input"
              onChange={(e) =>
                window.sessionStorage.setItem("cname", e.target.value)
              }
            />
            <RedditTextField
              label="Registered Address"
              className={classes.margin}
              variant="filled"
              multiline
              id="reddit-input"
              onChange={(e) => localStorage.setItem("address", e.target.value)}
            />
            <RedditTextField
              label="CIN (21 Digits)"
              className={classes.margin}
              variant="filled"
              id="reddit-input"
              onChange={(e) => localStorage.setItem("cin", e.target.value)}
            />
          </div>
          <FileInputComponent
            labelText="Upload Logo (Max 1MB):"
            labelStyle={{
              fontSize: 20,
              fontWeight: "bold",
              marginRight: "15px",
            }}
            multiple={false}
            textBoxVisible={true}
            imagePriview={true}
            callbackFunction={(file_arr) => {
              if (file_arr.size > 1000) {
                alert("File size exceeds 1MB limit");
              } else {
                localStorage.removeItem("logobase64");
                localStorage.setItem("logobase64", file_arr.base64);
                setLogoDetails(file_arr);
              }
            }}
            accept="image/*"
          />
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Alert severity="warning">The logo cannot be over 1MB</Alert>
          </div>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className={classes.CancelButton}>
            Cancel
          </button>
          <button onClick={createCompany} className={classes.CreateButton}>
            Create
          </button>
        </DialogActions>
      </Dialog>
      <div className={classes.ButtonGrp}>
        <button
          className={classes.NewSurvey}
          onClick={checkCredits}
          disabled={props.isLoading || props.error}
        >
          <Icon className={classes.Icon}>add_circle_outline</Icon> Add New
          Company
        </button>
      </div>
    </div>
  );
};

export default NewCompany;
