import * as actionTypes from "./actionsTypes";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Notiflix from "notiflix-react";
import firebase from "firebase";
import { STORAGE_CONSTANTS } from "../../ulitity/constants/storageConstants";
import { db } from "../../firebase-config";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const resetAuthError = () => {
  return {
    type: actionTypes.RESET_AUTH_STATE,
  };
};

export const authSuccess = (token, userId, email) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    email: email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authVerify = () => {
  return {
    type: actionTypes.AUTH_VERIFY,
  };
};

export const resetSuccess = (error) => {
  return {
    type: actionTypes.RESET_SUCCESS,
    error: error,
  };
};

export const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      window.selectedCompany = null;
      localStorage.removeItem("userId");
      localStorage.removeItem("companyid");
      localStorage.removeItem("email");
      localStorage.removeItem("emailencoded");
      localStorage.removeItem("redirectURL");
      localStorage.removeItem("redirectToSurvey");
    });

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (email, password, isSignup, name) => {
  return (dispatch) => {
    dispatch(authStart());
    if (isSignup) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          if (response.user) {
            db.collection("users")
              .doc(String(response.user.uid))
              .set({
                uid: response.user.uid,
                email: response.user.email,
                name: name,
                credits: 0,
              })
              .then((res) => {
                response.user.sendEmailVerification().then((res) => {
                  firebase
                    .auth()
                    .signOut()
                    .then((res) => {
                      dispatch(authVerify());
                      Notiflix.Report.Init({
                        width: "400px",
                        titleFontSize: "20px",
                        messageFontSize: "17px",
                        success: { buttonBackground: "#EE3A2C" },
                      });
                      Notiflix.Report.Success(
                        "Account Successfully Created",
                        "An email verification link has been sent to your mailbox, please first verify your email before logging in.",
                        "ok"
                      );
                    });
                });
              });
          }
        })
        .catch((err) => {
          dispatch(authFail(err));
        });
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          if (response.user) {
            if (response.user.emailVerified) {
              localStorage.setItem("email", response.user.email);
              localStorage.setItem("userId", response.user.uid);

              db.collection("users")
                .doc(String(response.user.uid))
                .get()
                .then((doc) => {
                  if (!doc.exists) return;

                  const userData = { ...doc.data() };
                  const username = userData.name;
                  firebase.auth().currentUser.updateProfile({
                    displayName: username,
                  });
                  localStorage.getItem("usersemail", username);
                  dispatch(
                    authSuccess(
                      response.user.za,
                      response.user.uid,
                      response.user.email,
                      username
                    )
                  );

                  const redirectToSurvey =
                    localStorage.getItem("redirectToSurvey");
                  const redirectToPreviousSurveyPage =
                    redirectToSurvey !== null && redirectToSurvey === "true"
                      ? true
                      : false;
                  if (redirectToPreviousSurveyPage) {
                    const isRedirectURL =
                      localStorage.getItem("redirectURL") !== null
                        ? true
                        : false;
                    if (isRedirectURL) {
                      const redirectURL = localStorage.getItem("redirectURL");
                      window.location.href = redirectURL;
                    }
                  }

                  const urlParams = new URLSearchParams(window.location.search);
                  const redirect = urlParams.get("redirectto");
                  if (redirect) {
                    window.location.href = redirect;
                    localStorage.removeItem("redirectLink");
                  }
                });
            } else {
              response.user.sendEmailVerification().then((res) => {
                firebase
                  .auth()
                  .signOut()
                  .then((res) => {
                    dispatch(authVerify());
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "Email Not Verified",
                      "Looks like you have not yet verified your email, we have resent the link to your mailbox, please first verify to continue.",
                      "ok"
                    );
                  });
              });
            }
          }
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/user-not-found":
            case "auth/wrong-password":
              let customErr = err;
              customErr.message = "Invalid Credentials";
              dispatch(authFail(customErr));
              break;
            default:
              dispatch(authFail(err));
              break;
          }
        });
    }
  };
};

export const reset = (email, isReset) => {
  return (dispatch) => {
    dispatch(authStart());
    const resetData = {
      email: email,
      requestType: "PASSWORD_RESET",
    };

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((response) => {
        dispatch(resetSuccess());
        confirmAlert({
          title: "Password Reset",
          message: "A password reset email has been sent to " + email,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

// export const setAuthRedirectPath = (path) => {
//     return {
//         type: actionTypes.SET_AUTH_REDIRECT_PATH,
//         path: path
//     };
// };
