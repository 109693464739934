import React from "react";
import { QuestionTypes } from "../../ulitity/constants/Questions";
import SingleLineText from "./Questions/SingleLineText";
import MultiLineText from "./Questions/MultiLineText";
import Checkboxes from "./Questions/Checkboxes";
import Dropdown from "./Questions/Dropdown";
import Multichoice from "./Questions/Multichoices";
import Rating from "./Questions/Rating";
import classes from "./QuestionPreviewWrapper.module.css";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import lightGreen from "@material-ui/core/colors/lightGreen";
import { withStyles } from "@material-ui/core/styles";
import { PREVIEW_WRAPPER } from "../../Store/actions/actionsTypes";
import Notiflix from "notiflix-react";

export const questionMap = {
  [QuestionTypes.SINGLE_LINE_TEXT]: (
    props,
    caller = "",
    surveyee_count = ""
  ) => {
    return (
      <SingleLineText
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
      />
    );
  },
  [QuestionTypes.MULTI_LINE_TEXT]: (
    props,
    caller = "",
    surveyee_count = ""
  ) => {
    return (
      <MultiLineText
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
      />
    );
  },
  [QuestionTypes.CHECKBOXES]: (props, caller = "", surveyee_count = "") => {
    return (
      <Checkboxes {...props} caller={caller} surveyee_count={surveyee_count} />
    );
  },
  [QuestionTypes.DROPDOWN]: (props, caller = "", surveyee_count = "") => {
    return (
      <Dropdown {...props} caller={caller} surveyee_count={surveyee_count} />
    );
  },
  [QuestionTypes.MULTI_CHOICE]: (props, caller = "", surveyee_count = "") => {
    return (
      <Multichoice {...props} caller={caller} surveyee_count={surveyee_count} />
    );
  },
  [QuestionTypes.RATING]: (props, caller = "", surveyee_count = "") => {
    return (
      <Rating {...props} caller={caller} surveyee_count={surveyee_count} />
    );
  },
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(lightGreen["A400"]),
    backgroundColor: lightGreen["A400"],
    "&:hover": {
      backgroundColor: lightGreen["A700"],
    },
  },
}))(Button);

let edit = false;
if (window.innerWidth <= 650) {
  edit = true;
}

const QuestionPreviewWrapper = (props) => {
  const {
    question,
    onActive,
    onRemove,
    onClone,
    onUp,
    onDown,
    showUp,
    showDown,
    onOpenModal,
    surveyee_count,
  } = props;
  let quest = questionMap[question.type](
    question,
    PREVIEW_WRAPPER,
    surveyee_count
  );
  return (
    <div
      onClick={() => {
        onActive(question._id);
      }}
      className={classes.QuestionPreviewWrapper}
    >
      {quest}
      <div className={classes.GroupButton}>
        {edit ? (
          <ColorButton
            variant="contained"
            size="small"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onActive(question._id);
              onOpenModal();
            }}
          >
            <Icon>edit</Icon>
          </ColorButton>
        ) : (
          ""
        )}
        <ButtonGroup
          variant="contained"
          size="large"
          color="primary"
          aria-label="Full-width contained primary button group"
        >
          {showUp ? (
            <Tooltip title="Move Question Up">
              <button
                className={classes.Upward}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onUp(question._id);
                }}
              >
                <Icon>arrow_upward</Icon>
              </button>
            </Tooltip>
          ) : (
            ""
          )}
          {showDown ? (
            <Tooltip title="Move Question Down">
              <button
                className={classes.Downward}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDown(question._id);
                }}
              >
                <Icon>arrow_downward</Icon>
              </button>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="Clone Question Below">
            <button
              className={classes.Add}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClone();
              }}
            >
              <Icon>add</Icon>
            </button>
          </Tooltip>
          <Tooltip title="Delete Question">
            <button
              className={classes.Remove}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Notiflix.Confirm.Init({
                  titleColor: "#32c6b2",
                  cancelButtonBackground: "#ea3434",
                  width: "360px",
                });
                Notiflix.Confirm.Show(
                  "Delete Question",
                  "Are you sure you want to delete this question? This action cannot be undone",
                  "Yes",
                  "No",
                  function () {
                    onRemove(question._id);
                  }
                );
              }}
            >
              <Icon>delete</Icon>
            </button>
          </Tooltip>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default QuestionPreviewWrapper;
