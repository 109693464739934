import React from "react";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classes from "./Question.module.css";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const Multichoices = (props) => {
  const {
    title,
    options,
    _id,
    isRequired,
    caller,
    surveyee_count,
    result,
    idAddOn,
  } = props;
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";
  let defaultRemarksValue = result
    ? result[_id + "_remarks"]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id + "_remarks"][idAddOn]
        : result[_id + "_remarks"]
      : ""
    : "";
  return (
    <div className={classes.Question}>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <>
          <div className={classes.GroupChoices}>
            {options.map((option, index) => {
              let valueCheck = result
                ? result[_id]
                  ? typeof idAddOn == "number" && idAddOn >= 0
                    ? result[_id][idAddOn]
                    : result[_id]
                  : ""
                : "";
              return (
                <div>
                  <div key={index}>
                    <label className={classes.RadioContainer}>
                      {option.content}
                      <Field
                        component="input"
                        type="radio"
                        name={`${_id}${addOnId}`}
                        value={option._id}
                        defaultChecked={
                          props.result ? valueCheck === option._id : false
                        }
                      />
                      <span className={classes.RadioCheckmark}></span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
          {props.otherOptionIncluded ? (
            <div>
              <Field
                type="text"
                placeholder={props.otherOptionValue}
                name={`${props._id}_remarks${addOnId}`}
                className={classes.Textarea}
                component="textarea"
                value={props.remarks}
                defaultValue={defaultRemarksValue}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Multichoices;
