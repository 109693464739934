import React from "react";
import Drawer from "@material-ui/core/Drawer";
import "./surveyDrawer.css";
import { connect } from "react-redux";
import { updateSurvey, groupQuestions } from "../../Store/actions/surveyEditer";
import { assembleSurvey } from "../../Store/actions/surveyEditer";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import EditTab from "../../Containers/EditTab/EditTab";
import Tooltip from "@material-ui/core/Tooltip";

function TemporaryDrawer(props) {
  const { open, setOpen } = props;

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      {!open && (
        <Tooltip title="Click To Edit Survey" placement="left">
          <button className="drawerOpenner">
            <ArrowLeftIcon
              className="drawerOpennerIcon"
              onClick={toggleDrawer}
            />
          </button>
        </Tooltip>
      )}
      <Drawer
        className="SurveyEditDrawer"
        anchor={"right"}
        open={open}
        onClose={toggleDrawer}
      >
        <EditTab open={open} setOpen={setOpen} />
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    survey: assembleSurvey(state.surveyEditer.survey),
    isSubmitting: state.surveyEditer.submitLoading,
    compId: state.surveyEditer.company.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (surveyId, token, data, compId) =>
      dispatch(updateSurvey(surveyId, token, data, compId)),
    updateStore: (updatedSurvey) => {
      dispatch(groupQuestions(updatedSurvey));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);
