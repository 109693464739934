import React from "react";
import classes from "./Logo.module.css";
import { Link } from "react-router-dom";

const logo = () => (
  <div className={classes.Logo}>
    <Link to="/" style={{ textDecoration: "none" }}>
      <h3 className={classes.logo}>Cimply<span className={classes.secondhalf}>Best</span></h3>
    </Link>
  </div>
);

export default logo;
