import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { COMPARATIVE } from "../../Store/actions/actionsTypes";
class EditSurveyPanel extends Component {
  handleValidation = () => {
    if (this.title.value.match(/^[a-zA-Z0-9-_. ]+$/) != null) {
      return true;
    }
    return false;
  };

  update = () => {
    // if(this.handleValidation()){ // Validation is removed.
    this.props.changeSurveyState();
    if (this.props.type === COMPARATIVE) {
      this.props.onUpdate({
        title: this.title.value,
        subTitle: this.subTitle.value,
        ratingIndex: this.ratingIndex.value,
        ratingIndexAsFootnote: this.ratingIndexAsFootnote.checked,
      });
    } else {
      this.props.onUpdate({
        title: this.title.value,
        subTitle: this.subTitle.value,
      });
    }
    // }
  };

  render() {
    let {
      title,
      subTitle,
      ratingIndex = "",
      type,
      ratingIndexAsFootnote = false,
    } = this.props;
    return (
      <>
        <div>
          <label>
            <b>Your Survey Title</b>
          </label>
          <input
            type="text"
            spellcheck="true"
            value={title}
            ref={(input) => {
              this.title = input;
            }}
            onChange={this.update.bind(this)}
          />
        </div>
        <div>
          <label>
            <b>Your Survey SubTitle</b>
          </label>
          <textarea
            type="text"
            spellcheck="true"
            value={subTitle}
            ref={(input) => {
              this.subTitle = input;
            }}
            onChange={this.update.bind(this)}
          />
        </div>
        {type === COMPARATIVE && (
          <>
            <div>
              <label>
                <b>Your Rating Index</b>
              </label>
              <textarea
                type="text"
                spellcheck="true"
                value={ratingIndex}
                ref={(input) => {
                  this.ratingIndex = input;
                }}
                onChange={this.update.bind(this)}
              />
            </div>

            <div>
              <label>
                <input
                  type="checkbox"
                  name={"ratingIndexPosition"}
                  checked={ratingIndexAsFootnote}
                  style={{
                    float: "left",
                    width: "4%",
                    "vertical-align": "middle",
                    height: "18px",
                    padding: "10px",
                    color: "aqua",
                  }}
                  ref={(input) => {
                    this.ratingIndexAsFootnote = input;
                  }}
                  onChange={this.update.bind(this)}
                />
                Add As a Footnote
              </label>
            </div>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => this.props.setOpen(false)}
        >
          Continue
        </Button>
      </>
    );
  }
}

export default EditSurveyPanel;
