import React, { Component } from "react";
import { connect } from "react-redux";

import { updateSurvey } from "../../Store/actions/surveyEditer";
import { assembleSurvey } from "../../Store/actions/surveyEditer";
import EditFooterView from "../../Components/EditPanel/EditFooter";
import Notiflix from "notiflix-react";
import { CHANGE_SURVEY_STATE } from "../../Store/actions/actionsTypes";

class EditFooter extends Component {
  render() {
    return (
      <div>
        <EditFooterView
          clicked={() =>
            this.props.onSubmit(
              this.props.surveyId,
              this.props.token,
              this.props.survey,
              this.props.compId
            )
          }
          submit={this.props.isSubmitting}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    survey: assembleSurvey(state.surveyEditer.survey),
    isSubmitting: state.surveyEditer.submitLoading,
    compId: state.surveyEditer.company.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (surveyId, token, data, compId) => {
      if (data.surveyee_list.find((obj) => obj.email == "" || obj.name == "")) {
        Notiflix.Notify.Init({ position: "center" });
        Notiflix.Notify.Failure(
          "Please Fill in All the Surveyee Details First"
        );
      } else {
        dispatch({ type: CHANGE_SURVEY_STATE, payload: true });
        dispatch(updateSurvey(surveyId, token, data, compId));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFooter);
