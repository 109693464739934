import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import classes from "./MailAuditData.module.css";

class MailAuditData extends Component {
  checkDeliveryStatusIcon = (email) => {
    const deliveredEmails = this.props.AuditValue.deliveredEmails;
    const undeliveredEmails = this.props.AuditValue.nonDeliveredEmails;
    if (undeliveredEmails) {
      for (let i = 0; i < undeliveredEmails.length; i++) {
        if (undeliveredEmails[i].email === email) {
          return 1;
        }
      }
    }

    if (deliveredEmails) {
      for (let i = 0; i < deliveredEmails.length; i++) {
        if (deliveredEmails[i] === email) {
          return 0;
        }
      }
    }

    return 2;
  };

  render() {
    let auditValues = this.props.AuditValue;
    let recipients = auditValues.recipients;
    if (typeof recipients == "undefined" || recipients == null) {
      return false;
    } else {
      return (
        <div>
          {this.props.AuditValue.recipients.map((recipient, i) => (
            <div key={this.props.AuditValue._id + "_" + i}>
              <label className={classes.EmailId}>{recipient}</label>
              &nbsp;
              {this.checkDeliveryStatusIcon(recipient) === 0 ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "#1adc1a" }}
                ></FontAwesomeIcon>
              ) : this.checkDeliveryStatusIcon(recipient) === 1 ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ color: "#d41010" }}
                ></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ color: "#e7cc28" }}
                ></FontAwesomeIcon>
              )}
            </div>
          ))}
          <div>
            Sent Time :{" "}
            {this.props.AuditValue.time
              ? new Date(this.props.AuditValue.time).toLocaleString()
              : ""}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(MailAuditData);
