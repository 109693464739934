import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Notiflix from "notiflix-react";
import { Link, withRouter, Redirect } from "react-router-dom";
import * as actionTypes from "../Store/actions/actionsTypes";
import classes from "./SurveysPage.module.css";
import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import NewSurvey from "../Containers/SurveyList/NewSurvey/NewSurvey";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Tooltip from "@material-ui/core/Tooltip";
import SurveyList from "../Containers/SurveyList/SurveyList";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import Dropdown from "react-bootstrap/Dropdown";
import { db } from "../firebase-config";

function renewCompany(compid) {
  db.collection("users")
    .doc(String(firebase.auth().currentUser.uid))
    .onSnapshot((doc) => {
      if (!doc.exists) return;
      const usersData = { ...doc.data() };
      const credits = usersData?.credits;
      if (Number(credits) > 0) {
        db.collection("company")
          .doc(String(compid))
          .get()
          .then((doc) => {
            if (!doc.exists) return;

            const date = new Date(new Date().getTime() + 3.154e10).getDate();
            const month =
              new Date(new Date().getTime() + 3.154e10).getMonth() + 1;
            const year = new Date(
              new Date().getTime() + 3.154e10
            ).getFullYear();
            let renewedData = {
              activeTill: `${date}/${month}/${year}`,
              registeredOn: new Date().getTime(),
            };
            db.collection("company")
              .doc(String(compid))
              .update(renewedData)
              .then(() => {
                db.collection("users")
                  .doc(String(firebase.auth().currentUser.uid))
                  .update({ credits: Number(credits) - 1 })
                  .then((res) => {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Init({ position: "right-bottom" });
                    Notiflix.Notify.Success("Company Renewed Successfully");
                    window.location.reload();
                  });
              });
          });
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Not Enough Credits",
          "You dont have enough credits to renew or add a new company.",
          "ok"
        );
      }
    });
}

class SurveysPage extends Component {
  componentDidMount() {
    this.props.onResetSurveyId();
    const history = this.props.history;
    const state = { ...history.location.state };
    if (state.fromSurveys) {
      delete state.fromSurveys;
      history.replace({ ...history.location, state });
    }
  }

  render() {
    let home = <h1>Login</h1>;

    let redirectPath = null;

    if (
      this.props.haveSurvId &&
      !this.props.history.location.state?.fromSurveys
    ) {
      if (this.props.role === "Admin") {
        redirectPath = (
          <Redirect to={"/user/" + this.props.surveyId + "/overview"} />
        );
      }
      if (this.props.role === "Editor") {
        redirectPath = (
          <Redirect to={"/user/" + this.props.surveyId + "/overview"} />
        );
      }
      if (this.props.role === "Director") {
        redirectPath = (
          <Redirect to={"/user/" + this.props.surveyId + "/overview"} />
        );
      }
      if (this.props.role === "Auditor") {
        redirectPath = (
          <Redirect to={"/user/" + this.props.surveyId + "/overview"} />
        );
      }
    }
    if (this.props.isAuthenticated) {
      home = (
        <div className={classes.main}>
          <div className={classes.currentCompany}>
            {/* <img src={this.props.logo} className={classes.currentlogo} alt=""></img>
                        <div className={classes.compMemb}>
                            <h4 className={classes.compname}>{this.props.name}</h4>
                            <Tooltip title="Your Current Role">
                                <Chip
                                    avatar={<Avatar style={{ backgroundColor: "#0F5293", color: "white", fontSize: "14px" }}>{this.props.role.charAt(0)}</Avatar>}
                                    label={this.props.role}
                                    clickable
                                    style={{ backgroundColor: "#1976D3", marginLeft: "15px", color: "white", fontSize: "14px" }}
                                />
                            </Tooltip>
                        </div> */}
            {/* <div className={classes.vertical}></div> */}
            <Dropdown
              style={{ marginLeft: "20px", marginTop: "15px" }}
              className={classes.dropdown}
            >
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                style={{ backgroundColor: "#34baf2" }}
              >
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    fontSize: "18px",
                    color: "white",
                  }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to={"/" + this.props.compId + "/users"}>Users</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={"/" + this.props.compId + "/logs"}>
                    Activity Log
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    display: this.props.role === "Admin" ? "normal" : "none",
                  }}
                >
                  <Link
                    to={
                      this.props.role === "Admin"
                        ? "/" + this.props.compId + "/edit"
                        : "/"
                    }
                  >
                    Company Details
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    variant="success"
                    href="https://desk.zoho.com/portal/cimplyfive/en/kb/cimplybest"
                    target="_blank"
                    style={{
                      padding: "10px",
                      marginRight: "15px",
                      height: "fit-content",
                      border: "none",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      style={{ marginRight: "10px" }}
                    />
                    Help
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={classes.BtnGroup}>
              <Link to={"/" + this.props.compId + "/users"}>
                <Button
                  variant="primary"
                  style={{
                    padding: "10px",
                    marginRight: "15px",
                    backgroundColor: "#34baf2",
                    borderColor: "#34baf2",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ marginRight: "10px" }}
                  />
                  Users
                </Button>
              </Link>
              <Link to={"/" + this.props.compId + "/logs"}>
                <Button
                  variant="primary"
                  style={{
                    padding: "10px",
                    height: "fit-content",
                    backgroundColor: "#34baf2",
                    marginRight: "15px",
                    borderColor: "#34baf2",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faListAlt}
                    style={{ marginRight: "10px" }}
                  />
                  Activity Log
                </Button>
              </Link>
              <Link
                to={
                  this.props.role === "Admin"
                    ? "/" + this.props.compId + "/edit"
                    : "/"
                }
              >
                <Button
                  variant="primary"
                  style={{
                    padding: "10px",
                    display: this.props.role === "Admin" ? "normal" : "none",
                    backgroundColor: "#34baf2",
                    marginRight: "15px",
                    borderColor: "#34baf2",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ marginRight: "10px" }}
                  />
                  Company Details
                </Button>
              </Link>
            </div>
          </div>
          <div className={classes.HomePage}>
            <div
              style={{
                display: this.props.status === "Inactive" ? "none" : null,
                marginTop: "12px",
                marginBottom: "20px",
              }}
            >
              <NewSurvey
                loading={this.props.isLoading}
                isError={this.props.isError}
              />
            </div>
            {redirectPath}
            <div
              style={{
                display: this.props.status === "Active" ? "none" : null,
                marginTop: "20px",
              }}
            >
              <Alert severity="error">
                This company is currently inactive, as a result creating new
                surveys has been blocked.
                <button
                  className={classes.renewbtn}
                  onClick={() => {
                    if (this.props.role === "Admin") {
                      confirmAlert({
                        title: "Renew Company?",
                        message:
                          "Are you sure you want to spend one credit from your account to renew this company?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: () => {
                              Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                              Notiflix.Loading.Pulse("Renewing Company");
                              renewCompany(this.props.compId);
                            },
                          },
                          {
                            label: "Cancel",
                          },
                        ],
                      });
                    } else {
                      alert(
                        "You need to have admin rights to perform this action"
                      );
                    }
                  }}
                  style={{
                    display: this.props.role === "Admin" ? null : "none",
                  }}
                >
                  Renew Company
                </button>
              </Alert>
            </div>
            <SurveyList />
            {this.props.error ? (
              <p>No internet connection or Internal Server error</p>
            ) : null}
          </div>
        </div>
      );
    }

    return <Fragment>{home}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    isLoading: state.surveys.loading,
    error: state.surveys.error,
    isError: state.surveys.error !== null,
    logo: state.surveyEditer.company.logo,
    surveyId: state.surveyEditer.survey.id,
    name: state.surveyEditer.company.name,
    status: state.surveyEditer.company.status,
    compId: state.surveyEditer.company.id,
    haveSurvId: state.surveyEditer.survey.id !== "",
    role: state.surveyEditer.company.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetSurveyId: () => dispatch({ type: actionTypes.RESET_SURVEY_ID }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveysPage)
);
