import React from "react";
import { FaStar } from "react-icons/fa";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import classes from "./QuestionPreview.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const Rating = (props) => {
  const { title, stars, _id, isRequired, caller, surveyee_count } = props;

  return (
    <>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      <div>
        <div>
          {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0
            ? ""
            : [...Array(stars)].map((star, i) => {
                return (
                  <label>
                    <FaStar
                      className="star"
                      color="#cccccc"
                      size={35}
                      // className={classes.Rating}
                    />
                  </label>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default Rating;
