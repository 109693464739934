import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../ulitity/ulitity';

const initialState = {
    logs: [],
    invitee: [],
    templates: [],
    adminlogs: [],
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOGS_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_LOGS_SUCCESS:
            return updateObject(state, {
                logs: action.logs,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_TEMPLATES_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        case actionTypes.FETCH_TEMPLATES_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_TEMPLATES_SUCCESS:
            return updateObject(state, {
                templates: action.templates,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_ADMINLOGS_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        case actionTypes.FETCH_ADMINLOGS_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_ADMINLOGS_SUCCESS:
            return updateObject(state, {
                adminlogs: action.adminlogs,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_INVITEE_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        case actionTypes.FETCH_INVITEE_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_INVITEE_SUCCESS:
            return updateObject(state, {
                invitee: action.invitee,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_LOGS_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        default:
            return state;
    };
};

export default reducer;