import React from 'react';

import RegisterView from '../Containers/Authentication/Register';

const registerPage = () => {
    return (
        <div>
            <RegisterView />
        </div>
    )
}

export default registerPage;
