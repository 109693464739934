import React from "react";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

import classes from "./QuestionPreview.module.css";

const Dropdown = (props) => {
  const { title, options, _id, isRequired, caller, surveyee_count } = props;

  return (
    <>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      <div>
        {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
          ""
        ) : (
          <select className={classes.Input} name={_id} disabled>
            {options.map((option, index) => {
              return (
                <option value={option._id} key={option._id}>
                  {option.content}
                </option>
              );
            })}
          </select>
        )}
      </div>
    </>
  );
};

export default Dropdown;
