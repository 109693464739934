//////////////////////--Survey Editer--/////////////////////////////////////////////////
export const ADD_QUESTION = "ADD_QUESTION";
export const ACTIVE_QUESTION = "ACTIVE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_SURVEY_HEADER = "UPDATE_SURVEY_HEADER";
export const SWITCH_TAB = "SWITCH_TAB";
export const CLONE_QUESTION = "CLONE_QUESTION";
export const SORT_QUESTION_UP = "SORT_QUESTION_UP";
export const SORT_GROUP_QUESTION_UP = "SORT_GROUP_QUESTION_UP";
export const SORT_QUESTION_DOWN = "SORT_QUESTION_DOWN";
export const SORT_UNGROUP_QUESTION_UP = "SORT_UNGROUP_QUESTION_UP";
export const SORT_UNGROUP_QUESTION_DOWN = "SORT_UNGROUP_QUESTION_DOWN";
export const SORT_GROUP_QUESTION_DOWN = "SORT_GROUP_QUESTION_DOWN";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const CREATE_COMPARATVE_SURVEY = "CREATE_COMPARATVE_SURVEY";
export const REMOVE_GROUP_QUESTION = "REMOVE_GROUP_QUESTION";
export const REMOVE_UNGROUP_QUESTION = "REMOVE_UNGROUP_QUESTION";
export const GROUP_QUESTIONS = "GROUP_QUESTIONS";
export const CHANGE_SURVEY_STATE = "CHANGE_SURVEY_STATE";
export const COMPARATIVE = "COMPARATIVE";
export const SURVEY_PAGE = "SURVEY_PAGE";
export const SURVEY_EDITOR_PAGE = "SURVEY_EDITOR_PAGE";
export const PREVIEW_WRAPPER = "PREVIEW_WRAPPER";
export const REGULAR = "REGULAR";
export const SURVEYEE_COUNT_CHANGE = "SURVEYEE_COUNT_CHANGE";
export const SURVEYEE_UPDATE = "SURVEYEE_UPDATE";
export const SURVEYEE_REMOVE = "SURVEYEE_REMOVE";
export const ADD_EMAIL_GROUP = "ADD_EMAIL_GROUP";
export const SURVEYEE_REARRANGE = "SURVEYEE_REARRANGE";
export const CLONE_GROUP_QUESTION = "CLONE_GROUP_QUESTION";
export const CLONE_UNGROUP_QUESTION = "CLONE_UNGROUP_QUESTION";
export const ADD_UNGROUP_QUESTION = "ADD_UNGROUP_QUESTION";
export const INDIVIDUAL = "INDIVIDUAL";
export const CUMULATIVE = "CUMULATIVE";
export const SELECT_ALL = "SELECT_ALL";
//---------Manager Survey in Survey Editer-------
export const GET_SURVEY_ID = "GET_SURVEY_ID";
export const RESET_OPENTILL = "RESET_OPENTILL";
export const RESET_SURVEY_ID = "RESET_SURVEY_ID";
export const RESET_COMPANY_ID = "RESET_COMPANY_ID";
export const REDIRECT_PAGE = "REDIRECT_PAGE";
export const GET_COMPANY_ID = "GET_COMPANY_ID";

//----------FETCH Controller in Survey Editer--------
export const FETCH_SURVEY_START = "FETCH_SURVEY_START";
export const FETCH_SURVEY_SUCCESS = "FETCH_SURVEY_SUCCESS";
export const FETCH_SURVEY_FAIL = "FETCH_SURVEY_FAIL";

//----------UPDATE Controller in survey Editer------
export const UPDATE_SURVEY_START = "UPDATE_SURVEY_START";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAIL = "UPDATE_SURVEY_FAIL";

//----------TOGGLE Submitting in Survey Editer-------
export const TOGGLE_SUBMIT_SUCCESS = "TOGGLE_SUBMIT_SUCCESS";
export const TOGGLE_SUBMIT_FAIL = "TOGGLE_SUBMIT_FAIL";

export const OPEN_MODAL_EDITER = "OPEN_MODAL_EDITER";
export const CLOSE_MODAL_EDITER = "CLOSE_MODAL_EDITER";
/////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////--Authentication--////////////////////////////////////////////////////
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_VERIFY = "AUTH_VERIFY";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Surveys--/////////////////////////////////////////////////////
export const FETCH_SURVEYS_START = "FETCH_SURVEYS_START";
export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS";
export const FETCH_SURVEYS_FAIL = "FETCH_SURVEYS_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Companies--/////////////////////////////////////////////////////
export const FETCH_COMPANIES_START = "FETCH_COMPANIES_START";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAIL = "FETCH_COMPANIES_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Survey for fill--////////////////////////////////////////////
export const FETCH_USER_SURVEY_START = "FETCH_USER_SURVEY_START";
export const FETCH_USER_SURVEY_SUCCESS = "FETCH_USER_SURVEY_SUCCESS";
export const FETCH_USER_SURVEY_FAIL = "FETCH_USER_SURVEY_FAIL";

export const SUBMIT_SURVEY_START = "SUBMIT_SURVEY_START";
export const SUBMIT_SURVEY_SUCCESS = "SUBMIT_SURVEY_SUCCESS";
export const SUBMIT_SURVEY_FAIL = "SUBMIT_SURVEY_FAIL";
export const SUBMIT_SURVEY_REQUIRED = "SUBMIT_SURVEY_REQUIRED";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--User Roles--//////////////////////////////////////////////////
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Activity Log--//////////////////////////////////////////////////
export const FETCH_LOGS_START = "FETCH_LOGS_START";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_FAIL = "FETCH_LOGS_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Invitee List--//////////////////////////////////////////////////
export const FETCH_INVITEE_START = "FETCH_INVITEE_START";
export const FETCH_INVITEE_SUCCESS = "FETCH_INVITEE_SUCCESS";
export const FETCH_INVITEE_FAIL = "FETCH_INVITEE_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Fetch Templates--//////////////////////////////////////////////////
export const FETCH_TEMPLATES_START = "FETCH_TEMPLATES_START";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_FAIL = "FETCH_TEMPLATES_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////--Admin Logs--//////////////////////////////////////////////////
export const FETCH_ADMINLOGS_START = "FETCH_ADMINLOGS_START";
export const FETCH_ADMINLOGS_SUCCESS = "FETCH_ADMINLOGS_SUCCESS";
export const FETCH_ADMINLOGS_FAIL = "FETCH_ADMINLOGS_FAIL";
////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_REPORTAUDIT_START = "FETCH_REPORTAUDIT_START";
export const FETCH_REPORTAUDIT_SUCCESS = "FETCH_REPORTAUDIT_SUCCESS";
export const FETCH_REPORTAUDIT_FAIL = "FETCH_REPORTAUDIT_FAIL";

////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_MAILAUDIT_START = "FETCH_MAILAUDIT_START";
export const FETCH_MAILAUDIT_SUCCESS = "FETCH_MAILAUDIT_SUCCESS";
export const FETCH_MAILAUDIT_FAIL = "FETCH_MAILAUDIT_FAIL";

////////////////////////--Data--////////////////////////////////////////////////////////
export const FETCH_DATA_START = "FETCH_DATA_START";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_DATA_SURVEY_SUCCESS = "FETCH_DATA_SURVEY_SUCCESS";
export const FETCH_DATA_FAIL = "FETCH_DATA_FAIL";

export const TOGGLE_ROW_SELECT = "TOGGLE_ROW_SELECT";
export const ROW_SET_ALL = "ROW_SET_ALL";
export const DELETE_ROW = "DELETE_ROW";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const SET_FILTER = "SET_FILTER";

export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
