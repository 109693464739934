import firebase from "firebase";
import * as actionTypes from "./actionsTypes";
import { confirmAlert } from "react-confirm-alert";
import Notiflix from "notiflix-react";

export const logout = () => {
  window.selectedCompany = null;
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
  };
};

export const autoLogin = () => {
  return (dispatch) => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      Notiflix.Loading.Init({ svgColor: "#34baf2" });
      Notiflix.Loading.Pulse("Signing In...");
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          window.localStorage.removeItem("emailForSignIn");
          const exporationDate = new Date(
            // Setting session timeout to 1Hr
            new Date().getTime() + 3600 * 1000
          );
          localStorage.setItem("expirationDate", exporationDate);
          localStorage.setItem("email", result.user.email);
          localStorage.setItem("userId", result.user.uid);
          Notiflix.Loading.Remove();
          dispatch(authSuccess(result.user.xa, result.user.uid));
          dispatch(checkAuthTimeout(3600));
        })
        .then((res) => {
          if (
            localStorage.getItem("redirectLink") !== null &&
            localStorage.getItem("redirectLink") !== "null"
          ) {
            window.location.href = localStorage.getItem("redirectLink");
            localStorage.removeItem("redirectLink");
          }
        })
        .catch(function (error) {
          Notiflix.Loading.Remove();
          confirmAlert({
            title: "Email Signin Failed",
            message: error.message,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        });
    }
  };
};
