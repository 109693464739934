import React from "react";

import ResetView from "../Containers/Authentication/Reset";

const ResetPage = () => {
  return (
    <div>
      <ResetView />
    </div>
  );
};

export default ResetPage;
