import React, { Component } from "react";
import { connect } from "react-redux";
import EditTabView from "../../Components/EditPanel/EditTab";

import { switchTab } from "../../Store/actions/surveyEditer";
import tabTypes from "../../ulitity/constants/TabTypes";
import QuestionListPanel from "../QuestionListPanel/QuestionListPanel";
import EditQuestionPanel from "../EditQuestionPanel/EditQuestionPanel";
import EditSurveyPanel from "../EditSurveyPanel/EditSurveyPanel";

class EditTabViewContainer extends Component {
  render() {
    return <EditTabView {...this.props} />;
  }
}
export default connect(
  (state) => {
    return {
      activeTab: state.tab,
      tabs: [
        {
          type: tabTypes.QUESTIONS_TAB,
          text: "Add Question",
          panel: (open, setOpen) => (
            <QuestionListPanel open={open} setOpen={setOpen} />
          ),
        },
        {
          type: tabTypes.EDIT_QUESTION_TAB,
          text: "Edit Question",
          panel: (open, setOpen) => (
            <EditQuestionPanel open={open} setOpen={setOpen} />
          ),
        },
        {
          type: tabTypes.EDIT_SURVEY_TAB,
          text: "Edit Survey Title",
          panel: (open, setOpen) => (
            <EditSurveyPanel open={open} setOpen={setOpen} />
          ),
        },
      ],
    };
  },
  (dispatch) => {
    return {
      onUpdateTab: (tab) => dispatch(switchTab(tab)),
    };
  }
)(EditTabViewContainer);
