import React, { Component } from "react";
import { read, utils, writeFileXLSX } from "xlsx";
import { db } from "../../firebase-config";

import classes from "./ResultsTable.module.css";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import firebase from "firebase";
import { lastSubmitDate } from "../../ulitity/ulitity";
import HtmlTableToExcelGenerator from "../../ulitity/HtmlTableToExcelGenerator";
import HtmlTableToXlsx from "../../ulitity/HtmlTableToXlsx";
import { orderBy } from "lodash";

class ResultsTable extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      companyDetails: "",
      surveyDetails: "",
      surveySentCount: 0,
      lastSubmissionDate: "",
      resultData: "",
    };
  }

  componentDidMount() {
    let self = this;

    db.collection("company")
      .doc(String(this.props.compId))
      .onSnapshot((doc) => {
        if (!doc.exists) return;

        const fetchedCompanyData = { ...doc.data() };
        self.setState({ companyDetails: fetchedCompanyData });
      });

    db.collection(`mailAudit/${this.props.surveyId}/mailAuditList`)
      .where("type", "==", "SURVEY")
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => data.push({ ...doc.data() }));
        let fetchInvitee = [];
        for (let val of data) {
          if (val["deliveredEmails"]?.length > 0) {
            val["deliveredEmails"].forEach((email, i) => {
              if (fetchInvitee.indexOf(email) < 0) {
                fetchInvitee.push(email);
              }
            });
          }
        }
        fetchInvitee.reverse();
        self.setState({ surveySentCount: fetchInvitee.length });
      });

    db.collection(`surveys/${this.props.compId}/surveysList`)
      .doc(String(this.props.surveyId))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const data = { ...doc.data() };
        let surveyData = data.content;
        self.setState({ surveyDetails: surveyData });

        db.collection(`results/${this.props.surveyId}/resultsList`).onSnapshot(
          (snapshot) => {
            if (!doc.exists) return;

            let results = [];
            snapshot.forEach((doc) => results.push({ ...doc.data() }));
            results = orderBy(results, ["id"], ["asc"]);
            let lastSubmissionDate = lastSubmitDate(
              results,
              surveyData.creatorDate
            );
            self.setState({ lastSubmissionDate: lastSubmissionDate });
          }
        );
      });
  }

  toggleSelectAll() {
    let {
      onSelectAll,
      onUnSelectAll,
      grid: { results },
    } = this.props;
    if (this.props.allSelect) {
      onUnSelectAll(results);
    } else {
      onSelectAll(results);
    }
  }

  render() {
    let { companyDetails, surveyDetails } = this.state;
    let excelFileName;

    if (
      companyDetails !== "" &&
      companyDetails.name &&
      this.props.surveyTitle
    ) {
      excelFileName = `${companyDetails.name}_${this.props.surveyTitle}`;
    } else {
      excelFileName = `${this.props.surveyTitle}`;
    }

    let { onSelectRow, rowSelects, allSelected, onDeleteRow, surveyId } =
      this.props;
    let { columns, results } = this.props.grid;
    let ratingQuestFound = false;
    columns.map((col, index) =>
      col.type && col.type === "RATING" ? (ratingQuestFound = true) : ""
    );
    let anySelected = Object.keys(rowSelects).some((k) => rowSelects[k]);
    let isDisable = true;
    if (anySelected) {
      isDisable = false;
    }

    let avgDetails = {};

    if (results) {
      columns.map((column, index) => {
        if (column && column.type === "RATING") {
          let totalCount = 0;
          let totalAns = 0;
          results.map((res, i) => {
            if (res[column.columnName]) {
              totalCount += parseInt(res[column.columnName]);
              totalAns++;
            }
          });
          avgDetails[column.columnName] = (totalCount / totalAns).toFixed(2);
        }
      });
    }

    return (
      <div className={classes.ResultsTable}>
        <div className={classes.TableWrapper}>
          <div className={classes.Toolbar}>
            {/* <Button
              disabled={isDisable}
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() =>
                onDeleteRow(
                  results.filter(r => rowSelects[r.id]),
                  surveyId
                )
              }
            >
              <Icon>delete</Icon> Delete
            </Button> */}
            <div className={classes.ConvertBtn}>
              <HtmlTableToXlsx
                tableRef={this.tableRef}
                filename={`${excelFileName}_${new Date()
                  .toLocaleString()
                  .split(",")[0]
                  .replaceAll("/", "-")}`}
                className="download-table-xls-button"
                buttonText="Download as Excel Sheet"
              />
            </div>
          </div>
          <div className={classes.NotDisplay}>
            <table ref={this.tableRef} id="table-to-xls">
              <tr>
                <th
                  colspan={6}
                  style={{ textAlign: "center", fontSize: "24px" }}
                >
                  {companyDetails ? companyDetails.name : ""}
                </th>
              </tr>
              <tr>
                <th
                  colspan={6}
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  {this.props.surveyTitle}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  Created On :{" "}
                  {this.state.surveyDetails
                    ? this.state.surveyDetails.creatorDate
                    : ""}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  Survey Closed On :{" "}
                  {this.state.surveyDetails
                    ? this.state.surveyDetails.openTill
                    : ""}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  No. of Respondents : {this.state.surveySentCount}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  Number of Responses Received : {results.length}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  Last Submit At :{" "}
                  {this.state.lastSubmissionDate
                    ? this.state.lastSubmissionDate
                    : ""}
                </th>
              </tr>
              <tr>
                <th colspan={6} style={{ textAlign: "left" }}>
                  Report Generated On :{" "}
                  {new Date().toLocaleString("en-US", {
                    timeZone: "Asia/Kolkata",
                  })}
                </th>
              </tr>
              <thead>
                <tr>
                  <th className={classes.Index}>#</th>
                  {results.map((res, index) => {
                    return <th key={index}>{index + 1}</th>;
                  })}
                  {ratingQuestFound ? <th key="avg">Average</th> : ""}
                </tr>
              </thead>
              <tbody>
                {columns.map((col, index) => (
                  <tr key={index}>
                    <td className={classes.Index}>{col.displayName}</td>
                    {results.map((result, idx) => (
                      <td onClick={() => this.props.onClickRow(result, idx)}>
                        {result[col.columnName]}
                      </td>
                    ))}
                    {ratingQuestFound ? (
                      col.type && col.type === "RATING" ? (
                        <td>{avgDetails[col.columnName]}</td>
                      ) : (
                        <td></td>
                      )
                    ) : (
                      ""
                    )}
                    A
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/*-----------------------------------------------------------------------------*/}
          <table style={{ width: "97%" }}>
            <thead>
              <tr>
                {/* <th className={classes.SelectBox}>
                  <input
                    type="checkbox"
                    onClick={e => e.stopPropagation()}
                    onChange={this.toggleSelectAll.bind(this)}
                    checked={allSelected}
                  />
                </th> */}
                <th className={classes.Index}>#</th>
                {results.map((res, index) => {
                  return <th key={index}>{index + 1}</th>;
                })}
                {ratingQuestFound ? <th key="avg">Average</th> : ""}
              </tr>
            </thead>
            <tbody>
              {columns.map((col, index) => (
                <tr key={index}>
                  <td className={classes.Index}>{col.displayName}</td>
                  {results.map((result, idx) => (
                    <td onClick={() => this.props.onClickRow(result, idx)}>
                      {result[col.columnName]}
                    </td>
                  ))}
                  {ratingQuestFound ? (
                    col.type && col.type === "RATING" ? (
                      <td>{avgDetails[col.columnName]}</td>
                    ) : (
                      <td></td>
                    )
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ResultsTable;
