import React, { Component } from "react";

import { QuestionTypes } from "../../ulitity/constants/Questions";
import MultichoiceEditer from "./QuestionEditer/MultiChoiceEditer";
import TextEditer from "./QuestionEditer/TextEditer";
import RatingchoiceEditer from "./QuestionEditer/RatingChoiceEditor";
import Button from "@material-ui/core/Button";

const questionEditorMap = {
  [QuestionTypes.SINGLE_LINE_TEXT]: (question, updateQuestion) => {
    return <TextEditer {...question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.MULTI_LINE_TEXT]: (question, updateQuestion) => {
    return <TextEditer {...question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.CHECKBOXES]: (question, updateQuestion) => {
    return <MultichoiceEditer {...question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.DROPDOWN]: (question, updateQuestion) => {
    return <MultichoiceEditer {...question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.MULTI_CHOICE]: (question, updateQuestion) => {
    return <MultichoiceEditer {...question} updateQuestion={updateQuestion} />;
  },
  [QuestionTypes.RATING]: (question, updateQuestion) => {
    return <RatingchoiceEditer {...question} updateQuestion={updateQuestion} />;
  },
};

class EditQuestionPanel extends Component {
  render() {
    const { question, updateQuestion, setOpen } = this.props;
    let mapped = "";

    if (questionEditorMap[question.type]) {
      mapped = questionEditorMap[question.type](question, updateQuestion);
    }
    return (
      <>
        {mapped}
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => setOpen(false)}
        >
          Continue
        </Button>
      </>
    );
  }
}

export default EditQuestionPanel;
