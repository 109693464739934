import React, { useEffect } from "react";
import firebase from "firebase";
import classes from "./MailAuditData.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../firebase-config";
import { ContactsOutlined } from "@material-ui/icons";

export default function ConsolidatedAudit(props) {
  let compid = props.compid;
  let surveys = props.surveys;

  const [audit, setAudit] = React.useState([]);
  useEffect(() => {
    db.collection(`consolidatedMailAudit/${compid}/consolidatedMailAuditList`)
      .get()
      .then((snapshot) => {
        const data = snapshot.docs?.map((doc) => ({
          ...doc.data(),
        }));

        if (data === null || data === undefined) setAudit([]);
        else setAudit(data);
      });
  }, [props.surveys]);
  return (
    <div>
      {audit?.length === 0 ? (
        <h6>No Mail Audits To show</h6>
      ) : (
        audit.reverse().map((a) => {
          let map = a;
          let arraytitles = "";
          for (let r in map.surveyids) {
            if (surveys[surveys.findIndex((x) => x._id == map.surveyids[r])]) {
              arraytitles +=
                surveys[surveys.findIndex((x) => x._id == map.surveyids[r])]
                  .content.title + ", ";
            }
          }
          if (arraytitles.length > 0) {
            return (
              <div className={classes.audititem}>
                <p>
                  <b>Surveys Titles: </b>
                  {arraytitles}
                </p>
                <p>
                  <b>Recipients: </b>
                </p>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  {map.recipients
                    ? map.recipients.map((rec) => {
                        let icon = null;
                        if (!map.deliveredEmails && map.nonDeliveredEmails) {
                          icon =
                            map.nonDeliveredEmails.indexOf(rec) > -1 ? (
                              <FontAwesomeIcon
                                icon={faClock}
                                style={{ color: "#e7cc28" }}
                              ></FontAwesomeIcon>
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ color: "#d41010" }}
                              />
                            );
                        }

                        if (!map.nonDeliveredEmails && map.deliveredEmails) {
                          icon =
                            map.deliveredEmails.indexOf(rec) > -1 ? (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ color: "#1adc1a" }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faClock}
                                style={{ color: "#e7cc28" }}
                              ></FontAwesomeIcon>
                            );
                        }

                        if (map.deliveredEmails && map.nonDeliveredEmails) {
                          icon =
                            map.deliveredEmails.indexOf(rec) > -1 ? (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ color: "#1adc1a" }}
                              />
                            ) : map.nonDeliveredEmails.indexOf(rec) > -1 ? (
                              <FontAwesomeIcon
                                icon={faClock}
                                style={{ color: "#e7cc28" }}
                              ></FontAwesomeIcon>
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{ color: "#d41010" }}
                              />
                            );
                        }

                        if (!map.deliveredEmails && !map.nonDeliveredEmails) {
                          icon = (
                            <FontAwesomeIcon
                              icon={faClock}
                              style={{ color: "#e7cc28" }}
                            ></FontAwesomeIcon>
                          );
                        }

                        return (
                          <div>
                            <p>
                              <b style={{ marginRight: 10 }}>{rec}</b>
                              {icon}
                            </p>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <p>
                  <b>Date: </b>
                  {new Date(Number(map.time)).toLocaleDateString()} -{" "}
                  {new Date(Number(map.time)).toLocaleTimeString()}
                </p>
              </div>
            );
          }
        })
      )}
    </div>
  );
}
