import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SurveyPreview from "../../Components/SurveyPreview/SurveyPreview";
import QuestionList from "../../Components/SurveyPreview/QuestionList";
import {
  activeQuestion,
  cloneQuestion,
  openModal,
  cloneUngroupQuestion,
  cloneGroupQuestion,
} from "../../Store/actions/surveyEditer";
import * as actionTypes from "../../Store/actions/actionsTypes";
import Spinner from "../../Components/UI/Spinner/Spinner";
import ComparativeTable from "../../Components/ComparativeTable/ComparativeTable";
import classes from "./SurveyPreviewContainer.module.css";
import UseRefWrapper from "./UseRefWrapper";
import Notiflix from "notiflix-react";

class SurveyPreviewContainer extends Component {
  render() {
    let { survey, ...rest } = this.props;

    if (this.props.onSubmitting) {
      return <Spinner />;
    }

    if (this.props.isError) {
      return <h3>ERR: This Survey Does Not Exist</h3>;
    }
    return (
      <SurveyPreview {...this.props}>
        <UseRefWrapper
          caller={actionTypes.SURVEY_EDITOR_PAGE}
          {...survey}
          {...rest}
          {...this.props}
        >
          {/* <QuestionList {...survey} {...rest} {...this.props} />
            {this.props.survey?.type === actionTypes.COMPARATIVE &&
              this.props.survey?.surveyee_count > 0 && (
                <ComparativeTable {...survey} {...rest} />
              )} */}
        </UseRefWrapper>
      </SurveyPreview>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    survey: state.surveyEditer.survey,
    onSubmitting: state.surveyEditer.submitLoading,
    isError: state.surveyEditer.error !== null,
    emailGroups: state.surveyEditer.company.emailGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHeaderActive: () => {
      dispatch(activeQuestion("header"));
    },
    onActive: bindActionCreators(activeQuestion, dispatch),
    onUp: (question) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.SORT_QUESTION_UP,
        questionId: question._id,
      });
    },
    onGroupQuestionUp: (groupInd, questInd) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.SORT_GROUP_QUESTION_UP,
        payload: { groupInd, questInd },
      });
    },
    onDown: (question) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.SORT_QUESTION_DOWN,
        questionId: question._id,
      });
    },
    onGroupQuestionDown: (groupInd, questInd) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.SORT_GROUP_QUESTION_DOWN,
        payload: { groupInd, questInd },
      });
    },
    onUngroupQuestionUp: (questInd, questId) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });

      dispatch({
        type: actionTypes.SORT_UNGROUP_QUESTION_UP,
        payload: { questInd, questId },
      });
    },
    onUngroupQuestionDown: (questInd, questId) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.SORT_UNGROUP_QUESTION_DOWN,
        payload: { questInd, questId },
      });
    },
    onClone: bindActionCreators(cloneQuestion, dispatch),
    onCloneGroupQuestion: bindActionCreators(cloneGroupQuestion, dispatch),
    onCloneUnGroupQuestion: bindActionCreators(cloneUngroupQuestion, dispatch),
    onRemove: (question) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.REMOVE_QUESTION,
        questionId: question._id,
      });
    },
    onRemoveGroupQuestion: (groupInd, questInd) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.REMOVE_GROUP_QUESTION,
        payload: { groupInd, questInd },
      });
    },
    onRemoveUngroupQuestion: (questInd) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({
        type: actionTypes.REMOVE_UNGROUP_QUESTION,
        payload: { questInd },
      });
    },

    onOpenModal: bindActionCreators(openModal, dispatch),
    createComparativeSurvey: (surveyType) => {
      if (surveyType === actionTypes.COMPARATIVE) {
        Notiflix.Confirm.Init({
          titleColor: "#32c6b2",
          cancelButtonBackground: "#ea3434",
          width: "360px",
        });
        Notiflix.Confirm.Show(
          "Uncheck",
          'Are you sure you want to uncheck? It will become a regular survey if you click on "Yes"',
          "Yes",
          "No",
          function () {
            dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
            dispatch({ type: actionTypes.CREATE_COMPARATVE_SURVEY });
          }
        );
      } else {
        dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
        dispatch({ type: actionTypes.CREATE_COMPARATVE_SURVEY });
      }
    },

    onSurveyeeCountChange: (type) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      type === "INC"
        ? dispatch({ type: actionTypes.SURVEYEE_COUNT_CHANGE, payload: "INC" })
        : dispatch({
            type: actionTypes.SURVEYEE_COUNT_CHANGE,
            payload: "DEC",
          });
    },

    surveyeeUpdate: (value) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({ type: actionTypes.SURVEYEE_UPDATE, payload: { value } });
    },
    // surveyeeRearrange: (newOrder) =>
    //   dispatch({ type: actionTypes.SURVEYEE_REARRANGE, payload: { newOrder } }),
    removeSurveyee: (finalList) => {
      dispatch({ type: actionTypes.CHANGE_SURVEY_STATE, payload: false });
      dispatch({ type: actionTypes.SURVEYEE_REMOVE, payload: { finalList } });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyPreviewContainer);
