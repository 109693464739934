import React from 'react';

import LoginView from '../Containers/Authentication/Login'; 

const LoginPage = () => {
  return (
    <div>
      <LoginView />
    </div>
  )
}

export default LoginPage;
