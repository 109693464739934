import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { fetchCompanies } from "../../Store/actions/companies";
import newId from "../../ulitity/idGenerator";
import firebase from "firebase";
import classes from "./NewCompany.module.css";
import * as actionTypes from "../../../src/Store/actions/actionsTypes";
import NewCompanyView from "../../Components/NewCompany/NewCompany";
import { db } from "../../firebase-config";

class NewCompany extends Component {
  state = {
    _id: "",
    userId: "",
    content: {
      name: "Company Name Here",
      logo: "",
      address: "",
      cin: "",
      creatorDate: "",
      lastModified: "",
    },
  };

  componentDidMount() {
    this.props.onFetchCompanies(this.props.token, this.props.user_Id);
    let newStateValue = {
      _id: newId(),
      userId: this.props.user_Id,
      content: {
        name: "Company Name",
        address: "Company Address",
        creatorDate: new Date(),
        lastModified: new Date(),
        cin: "cin",
      },
    };

    this.setState(newStateValue);
    return newStateValue;
  }

  clickHandler = () => {
    const newCompany = { ...this.state };

    db.collection("surveys")
      .add(newCompany)
      .then((docRef) => {
        this.props.onGetSurveyId(docRef.id);
      })
      .catch((error) => {
        alert(error);
      });
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        <NewCompanyView
          clicked={this.clickHandler}
          isLoading={this.props.loading}
          error={this.props.isError}
        />
        <div className={classes.compcont}>
          <h5>
            {this.props.complength +
              (this.props.complength === 1 ? " Company" : " Companies")}
          </h5>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    complength: state.companies.companies.length,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanies: (token, userId) =>
      dispatch(fetchCompanies(token, userId)),
    onGetSurveyId: (surveyId) =>
      dispatch({ type: actionTypes.GET_SURVEY_ID, surveyId: surveyId }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewCompany)
);
