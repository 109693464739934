import axios from "axios";
import firebase from "firebase";

import CONSTANTS from "../constants";

const http = axios.create({
  baseURL: CONSTANTS.URL_CONFIG.FUNCTION_URL,
});

http.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult();
    config.headers.Authorization = `Bearer ${idTokenResult?.token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
