import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../ulitity/ulitity';

const initialState = {
    users: [],
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_USERS_SUCCESS:
            return updateObject(state, {
                users: action.users,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_USERS_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        default:
            return state;
    };
};

export default reducer;