import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReportPie from "./ReportPie";
import classes from "./ReportItem.module.css";
import ResizableTextarea from "../../ulitity/ResizableTextarea";
class OnlyPieReportItem extends Component {
  state = {
    width: 500,
  };

  componentWillMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    let { data } = this.props;
    return (
      <div className={classes.ReportItemWrapper}>
        {/* <h3 className={classes.Title}>{`Q. ${data.title}`}</h3> */}
        <ResizableTextarea
          additionalClass={classes.Title}
          title={`Q. ${data.title}`}
        />
        <div key={data.title} className={classes.ReportItem}>
          <div className={classes.pie}>
            <ReportPie data={data.stats} />
          </div>
        </div>
      </div>
    );
  }
}

OnlyPieReportItem.defaultProps = {};

export default OnlyPieReportItem;
