import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import classes from "./SurveyItem.module.css";
import { Formik, Field, Form } from "formik";
import { Checkbox } from "@material-ui/core";

class CompanyModal extends Component {
  state = {
    checked: new Set(),
  };

  componentDidMount() {
    this.props.handleModalOpen();
  }

  handleChange = (e) => {
    let copy = this.state.checked;
    let val = e.target.value;
    if (copy.has(val)) {
      copy.delete(val);
    } else {
      copy.add(val);
    }
    this.setState({ checked: copy });
  };

  render() {
    const mapping = this.props.companyListAsAdmin.map((company) => {
      return (
        <div key={company._id}>
          <Field
            type="checkbox"
            name="checked"
            value={company._id}
            onChange={this.handleChange}
            as={Checkbox}
          />
          <label forhtml={company._id}> {company.name}</label>
          <br />
        </div>
      );
    });
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.props.showModal}
          onClose={this.props.handleModalClose}
          closeAfterTransition
          disableBackdropClick={true}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.props.showModal}>
            <Formik
              onSubmit={() => {
                this.props.onSubmit(this.state.checked);
                this.props.handleModalClose();
              }}
            >
              {({ values }) => (
                <div className={classes.paper}>
                  <Form>
                    <h2 className={classes.modal_title}>
                      Please Select Company To Clone The Survey
                    </h2>
                    <div className={classes.modal_description}>
                      <div
                        className={classes.checkbox_list}
                        role="group"
                        aria-labelledby="checkbox-group"
                      >
                        {mapping}
                      </div>
                    </div>
                    <button
                      className="btn btn-success btn-sm"
                      style={{ marginRight: "30px", float: "right" }}
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      style={{ marginRight: "10px", float: "right" }}
                      onClick={this.props.handleModalClose}
                      type="submit"
                    >
                      Cancel
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default CompanyModal;
