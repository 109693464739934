import React, { Fragment } from "react";
import NavigationItems from "../NavigationItems/NavigationItems";
import TopMenuItems from "../TopMenu/TopMenuItems/TopMenuItems";
import classes from "./SideDrawer.module.css";
import firebase from "firebase";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { Link } from "react-router-dom";

const sideDrawer = props => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }
  return (
    <Fragment>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={attachedClasses.join(" ")} onClick={props.closed}>
        <div className={classes.Logo}>
          <h5 className={classes.LogoText}>CimplyBest</h5>
          {props.isAuth && props.onUser ? (
            <div>
              <h6>{firebase.auth().currentUser.email}</h6>
              <Link to="/logout">
                <button>Logout</button>
              </Link>
            </div>
          ) : null}
        </div>
        <hr />
        <nav>
          <NavigationItems isAuthenticated={props.isAuth} />
          {props.isAuth && props.onUser ? (
            <div>
              <TopMenuItems />
            </div>
          ) : null}
        </nav>
      </div>
    </Fragment>
  );
};

export default sideDrawer;
