import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Legend,
  Label,
} from "recharts";

import classes from "./ReportTable.module.css";

const reportPie = (props) => {
  const { data, width } = props;
  const COLORS = [
    "#0088FE",
    "#B276B2",
    "#FFBB28",
    "#FF8042",
    "#00C49F",
    "#FFCC33",
    "#FF66FF",
    "#CCFF66",
    "#66FF99",
    "#FF6699",
  ];
  let renderLabel = function (entry) {
    return entry.name;
  };
  let toolTipStyle = {
    maxWidth: "320px",
    padding: "2px",
    borderRadius: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  };

  let toolTipItemStyle = {
    maxWidth: "300px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <div className={classes.pieContainer}>
      <div
        style={{
          width: "200",
          height: "300px",
          marginBottom: "40px",
          backgroundColor: "white",
        }}
      >
        <PieChart width={820} height={340}>
          <Pie
            data={data}
            isAnimationActive={false}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            fill="#8884d8"
            innerRadius={50}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip contentStyle={toolTipStyle} labelStyle={toolTipItemStyle} />
          <Legend verticalAlign="bottom" height={20} />
        </PieChart>
      </div>
    </div>
  );
};

export default reportPie;
