import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../ulitity/ulitity';

const initialState = {
    companies: [],
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COMPANIES_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_COMPANIES_SUCCESS:
            return updateObject(state, {
                companies: action.companies,
                loading: false,
                error: null
            });
        case actionTypes.FETCH_COMPANIES_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        default:
            return state;
    };
};

export default reducer;