import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../../ulitity/ulitity";

const initialState = {
  token: null,
  userId: null,
  email: null,
  name: null,
  error: null,
  loading: false,
  authRedirectPath: "/"
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    email: action.email,
    name: action.email,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authVerify = (state, action) => {
  return updateObject(state, { error: null, loading: false });
};

const resetSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, userId: null });
};

const resetAuthState = (state, action) => {
  return updateObject(state, {error: null, loading: false});
}

// const setAuthRedirectPath = (state, action) => {
//     return updateObject(state, {authRedirectPath: action.path})
// }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_VERIFY:
      return authVerify(state, action);
    case actionTypes.RESET_SUCCESS:
      return resetSuccess(state, action);
    case actionTypes.RESET_AUTH_STATE:
      return resetAuthState(state, action);
    // case actionTypes.SET_AUTH_REDIRECT_PATH:
    //     return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;
