import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../ulitity/ulitity';

const initialState = {
    mailAudits:[],
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
       
        case actionTypes.FETCH_MAILAUDIT_FAIL:
            return updateObject(state, { loading: false, error: action.error });
        case actionTypes.FETCH_MAILAUDIT_START:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_MAILAUDIT_SUCCESS:
            return updateObject(state, {
                mailAudits: action.mailAudit,
                loading: false,
                error: null
            });
        default:
            return state;
    };
};

export default reducer;