import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import Paper from "@material-ui/core/Paper";
import classes from "./ReportPage.module.css";
import Wrapper from "../Containers/Report/Wrapper";
import OnlyPieReportContainer from "../Containers/Report/OnlyPieReportContainer";
import OnlyTableReportContainer from "../Containers/Report/OnlyTableReportContainer";
import OpenResultTableContainer from "../Containers/Results/OpenResultTableContainer";
import { fetchData } from "../Store/actions/data";
import { fetchInvitee } from "../Store/actions/logs";
import { fetchSurvey, toggleSubmit } from "../Store/actions/surveyEditer";
import Notiflix from "notiflix-react";
import Spinner from "../Components/UI/Spinner/Spinner";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import GreenSwitcher from "../Components/UI/Switcher/GreenSwitch";
import { lastSubmitDate, currentResultToday } from "../ulitity/ulitity";
import { db } from "../firebase-config";

class ReportPage extends Component {
  state = {
    openended: true,
    onlypie: true,
    onlytable: true,
  };
  componentDidMount() {
    firebase.analytics().logEvent("Report page is rendered");
    db.collection(`logs/${this.props.compId}/logsList`)
      .add({
        name: firebase.auth().currentUser.displayName,
        email: firebase.auth().currentUser.email,
        timestamp: new Date().getTime(),
        entity: "Results",
        payload: { survey: this.props.title },
        operation: "READ",
      })
      .then((res) => {
        this.props.onFetchInvitee(this.props.compId, this.props.surveyId);
        this.props.onFetchData(this.props.surveyId, this.props.token);
        this.props.onfetchSurvey(
          this.props.surveyId,
          this.props.token,
          this.props.compId
        );
      })
      .catch((err) => {
        alert("Could Not Visualize Data");
      });
  }

  render() {
    if (this.props.isLoading) {
      return <Spinner />;
    }
    return (
      <div>
        <div style={{ alignItems: "center" }} className={classes.reportgenitem}>
          <div
            style={{
              display: "flex",
              marginLeft: "25px",
              verticalAlign: "middle",
            }}
          >
            <GreenSwitcher
              checked={this.state.openended}
              label="Include Open Ended Questions"
              onChange={() => {
                if (this.state.openended === true) {
                  this.setState({ openended: false });
                } else {
                  this.setState({ openended: true });
                }
              }}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "25px",
              verticalAlign: "middle",
            }}
          >
            <GreenSwitcher
              checked={this.state.onlypie}
              label="Include Table"
              onChange={() => {
                if (this.state.onlypie === true) {
                  this.setState({ onlypie: false });
                } else {
                  this.setState({ onlypie: true });
                }
              }}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "25px",
              verticalAlign: "middle",
            }}
          >
            <GreenSwitcher
              checked={this.state.onlytable}
              label="Include Pie Chart"
              onChange={() => {
                if (this.state.onlytable === true) {
                  this.setState({ onlytable: false });
                } else {
                  this.setState({ onlytable: true });
                }
              }}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <ReactToPrint
            content={() => this.componentRef}
            documentTitle={
              "Report_" +
              this.props.title +
              "_" +
              this.props.compName +
              "_" +
              new Date().toLocaleString()
            }
            copyStyles={true}
            suppressErrors={true}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <button
                  onClick={() => {
                    let questions = this.props.questions;
                    for (let i = 0; i < questions.length; i++) {
                      if (questions[i]?.type === "RATING") {
                        if (questions[i]?.stars == 10) {
                          document.querySelector(
                            ".ReportTable_pieContainer__3aLWy"
                          ).style.padding = "0px";
                          break;
                        }
                      }
                    }
                    if (
                      !this.state.onlypie &&
                      !this.state.onlytable &&
                      this.state.openended
                    ) {
                      confirmAlert({
                        title: "Generate Report",
                        message:
                          "You have only selected open ended questions, as a result the other questions will not be shown in the report.Continue?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: handlePrint,
                          },
                          {
                            label: "No",
                          },
                        ],
                      });
                    } else if (
                      !this.state.onlypie &&
                      !this.state.onlytable &&
                      !this.state.openended
                    ) {
                      Notiflix.Report.Init({
                        width: "400px",
                        titleFontSize: "20px",
                        messageFontSize: "17px",
                        success: { buttonBackground: "#EE3A2C" },
                      });
                      Notiflix.Report.Failure(
                        "Choose Correct Values",
                        "Please choose atleast one option before trying to generate the report.",
                        "ok"
                      );
                    } else {
                      handlePrint();
                    }
                  }}
                  style={{
                    fontSize: 15,
                    color: "#ffffff",
                    padding: 10 + "px",
                    backgroundColor: "#EE3A2C",
                    border: "none",
                    verticalAlign: "middle",
                  }}
                >
                  Generate PDF
                </button>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
        <div
          className={classes.tobeprinted}
          ref={(el) => (this.componentRef = el)}
          id="print"
        >
          <div className={classes.firstpage}>
            <div className={classes.header}>
              <img
                src={this.props.logo}
                alt="company logo"
                className={classes.logo}
              />
              <h1 className={classes.companyname}>{this.props.compName}</h1>
              <h3 className={classes.survtit}>{this.props.title}</h3>
            </div>
            <div className={classes.details}>
              <h4 className={classes.survtitt}>
                <strong>Created On: </strong>
                {this.props.creatorDate}
              </h4>
              <h4 className={classes.survtitt}>
                <strong>Survey Closed On: </strong>
                {this.props.openTill.split("T")[0] +
                  ", " +
                  this.props.openTill.split("T")[1]}
              </h4>
              <h4 className={classes.survtitt}>
                <strong>No. of Respondents: </strong>
                {this.props.sentCount.length}
              </h4>
              <h4 className={classes.survtitt}>
                <strong>Number of Responses Received: </strong>
                {this.props.submitCount}
              </h4>
              <h4 className={classes.survtitt}>
                <strong>Last Submit At: </strong>
                {this.props.lastDate}
              </h4>
              <h4 className={classes.survtitt}>
                <strong>Report Generated On: </strong>
                {new Date().toLocaleString()}
              </h4>
            </div>
            <div className={classes.poweredby}>
              <h2 className={classes.powered}>
                Report generated by CimplyBest
              </h2>
            </div>
          </div>
          <div
            style={{
              display:
                this.state.onlypie && this.state.onlytable ? null : "none",
            }}
          >
            <Wrapper />
          </div>
          <div
            style={{
              display:
                this.state.onlypie ||
                (this.state.onlypie === false && this.state.onlytable === false)
                  ? "none"
                  : null,
            }}
          >
            <OnlyPieReportContainer />
          </div>
          <div
            style={{
              display:
                this.state.onlytable ||
                (this.state.onlypie === false && this.state.onlytable === false)
                  ? "none"
                  : null,
            }}
          >
            <OnlyTableReportContainer />
          </div>
          <div>
            <div style={{ display: this.state.openended ? null : "none" }}>
              <OpenResultTableContainer />
            </div>
          </div>
        </div>
        <div className={classes.dontprint}>
          <Wrapper />
        </div>
        <div className={classes.openhead}>
          <h5>Open Ended Questions:</h5>
        </div>

        <div className={classes.openques}>
          <OpenResultTableContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.data.survey.questions,
    surveyId: state.surveyEditer.survey.id,
    token: state.auth.token,
    role: state.surveyEditer.company.role,
    compId: state.surveyEditer.company.id,
    isLoading: state.data.isLoading,
    logo: state.surveyEditer.company.logo,
    openTill: state.surveyEditer.survey.openTill,
    compName: state.surveyEditer.company.name,
    title: state.surveyEditer.survey.title,
    sentCount: state.logs.invitee,
    submitCount: state.data.results.length,
    creatorDate: state.surveyEditer.survey.creatorDate,
    lastDate: lastSubmitDate(
      state.data.results,
      state.surveyEditer.survey.creatorDate
    ),
    lastModified: state.surveyEditer.survey.lastModified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (surveyId, token) => dispatch(fetchData(surveyId, token)),
    onfetchSurvey: (surveyId, token, compId) =>
      dispatch(fetchSurvey(surveyId, token, compId)),
    onFetchInvitee: (compId, surveyId) =>
      dispatch(fetchInvitee(compId, surveyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
