import React from "react";
import Icon from "@material-ui/core/Icon";
import classes from "./NewUser.module.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from '@material-ui/lab/Alert';
import "react-toastify/dist/ReactToastify.css";
import Popover from '@material-ui/core/Popover';

const NewUser = props => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        localStorage.removeItem("newuseremail");
        localStorage.removeItem("newusername");
        localStorage.removeItem("newuserrole");
    }
    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClose = () => {
        setAnchorEl(null);
    };

    const openn = Boolean(anchorEl);
    const id = openn ? 'simple-popover' : undefined;

    return (
        <div className={classes.CreateUser}>
            <Popover
                id={id}
                open={openn}
                anchorEl={anchorEl}
                onClose={handleClickClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <table>
                    <tr>
                        <th className={classes.thead}>Role</th>
                        <th className={classes.thead}>Create/Edit Survey</th>

                        <th className={classes.thead}>Add new users</th>
                        <th className={classes.thead}>Overview Data</th>
                        <th className={classes.thead}>See Data and Reports</th>
                        <th className={classes.thead}>Share Report</th>
                    </tr>
                    <tr>
                        <td>Admin</td>
                        <td><span role="img" >&#x2705;</span></td>

                        <td><span role="img">&#x2705;</span></td>
                        <td><span role="img" >&#x2705;</span></td>
                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img" >&#x2705;</span></td>
                    </tr>
                    <tr>
                        <td>Editor</td>
                        <td><span role="img">&#x2705;</span></td>

                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img">&#x2705;</span></td>
                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img" >&#x2705;</span></td>
                    </tr>
                    <tr>
                        <td>Director</td>
                        <td><span role="img">&#10060;</span></td>

                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img" >&#x2705;</span></td>
                        <td><span role="img">&#x2705;</span></td>
                        <td><span role="img">&#10060;</span></td>
                    </tr>
                    <tr>
                        <td>Auditor</td>
                        <td><span role="img">&#10060;</span></td>

                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img" >&#x2705;</span></td>
                        <td><span role="img">&#10060;</span></td>
                        <td><span role="img">&#10060;</span></td>
                    </tr>
                </table>
            </Popover>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                className={classes.Dialog}
            >
                <DialogTitle className={classes.DialogTitle}>
                    Create New User
             </DialogTitle>
                <h6 className={classes.Info}>
                    Please fill all the details to create a new user
                    <div style={{
                        marginTop: "15px"
                    }}>
                        <Alert severity="info">To edit a user, please first delete the user and add again with the new role.</Alert>
                    </div>
                </h6>
                <DialogContent className={classes.content}>
                    <input type="name" placeholder="Full Name" id="name" className={classes.name}></input>
                    <input type="email" placeholder="Email Address" id="emaill" className={classes.email}></input>
                    <select id="role" className={classes.role} onChange={e => {
                        if (e.target.value === "Admin") {
                            document.getElementById("Admin").setAttribute("style", "display:block;");
                            document.getElementById("Editor").setAttribute("style", "display:none;");
                            document.getElementById("Auditor").setAttribute("style", "display:none;");
                            document.getElementById("Director").setAttribute("style", "display:none;");
                        } else if (e.target.value === "Editor") {
                            document.getElementById("Editor").setAttribute("style", "display:block;");
                            document.getElementById("Admin").setAttribute("style", "display:none;");
                            document.getElementById("Auditor").setAttribute("style", "display:none;");
                            document.getElementById("Director").setAttribute("style", "display:none;");
                        } else if (e.target.value === "Director") {
                            document.getElementById("Director").setAttribute("style", "display:block;");
                            document.getElementById("Admin").setAttribute("style", "display:none;");
                            document.getElementById("Auditor").setAttribute("style", "display:none;");
                            document.getElementById("Editor").setAttribute("style", "display:none;");
                        } else if (e.target.value === "Auditor") {
                            document.getElementById("Auditor").setAttribute("style", "display:block;");
                            document.getElementById("Admin").setAttribute("style", "display:none;");
                            document.getElementById("Director").setAttribute("style", "display:none;");
                            document.getElementById("Editor").setAttribute("style", "display:none;");
                        }
                    }}>
                        <option value="Admin">Admin</option>
                        <option value="Editor">Editor</option>
                        <option value="Director">Director</option>
                        <option value="Auditor">Auditor</option>
                    </select>
                    <Alert icon={false} severity="warning" id="Admin">As an Admin the user will be able to Add/Delete users, Create/Edit Surveys and Control Survey Status</Alert>
                    <Alert icon={false} severity="warning" style={{ display: "none" }} id="Editor">As a Editor the user will be able to Create/Edit Surveys and Control Survey Status</Alert>
                    <Alert icon={false} severity="warning" style={{ display: "none" }} id="Director">As a Director the user will only be able to see Data and Reports</Alert>
                    <Alert icon={false} severity="warning" style={{ display: "none" }} id="Auditor">As an Auditor the user will be able to view Survey Information and the Survey Template sent out.</Alert>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => {
                        var e = document.getElementById("role");
                        var text = e.options[e.selectedIndex].text;
                        props.clicked(document.getElementById("emaill").value, document.getElementById("name").value, text);
                        handleClose();

                    }} className={classes.CreateButton} type="submit">
                        Create
                    </button>
                    <button onClick={handleClose} className={classes.CancelButton}>
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
            <div className={classes.crbtns}>
                <button
                    className={classes.NewSurvey}
                    onClick={handleClickOpen}
                    disabled={props.isLoading || props.error}
                >
                    <Icon className={classes.Icon}>add_circle_outline</Icon> Create New User
      </button>
                <button
                    className={classes.RoleBreak}
                    onClick={handleClick}
                    disabled={props.isLoading || props.error}
                >
                    User Rights
      </button>
            </div>
        </div >

    );
};

export default NewUser;