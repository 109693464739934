import React, { Component } from "react";
import { Route, Redirect, Switch, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./Containers/Layout/Layout";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import Logout from "./Containers/Authentication/Logout";
import UserSurveyPage from "./pages/UserSurveyPage";
import ResetPage from "./pages/ResetPage";
import ErrorPage from "./pages/ErrorPage";
import AdminPage from "./pages/AdminPage";
import SurveysPage from "./pages/SurveysPage";
import EditCompanyPage from "./pages/EditCompanyPage";
import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import LogsPage from "./pages/LogPage";
import ConsolidatedViewPage from "./pages/ConsolidatedViewPage";
import firebase from "firebase";
import { authSuccess, logout } from "./Store/actions/authentication";
import Modal from "react-bootstrap/Modal";
import AppLogout from "./Components/AutoLogout/AutoLogout";

class App extends Component {
  componentDidMount() {
    const userId = localStorage.getItem("userId");
    const email = localStorage.getItem("email");
    if (userId && email)
      this.props.onAuthSuccess(this.props.token, userId, email);
  }

  render() {
    let routes = (
      <Switch>
        <Route path="/:hash/:metaid/start" component={UserSurveyPage} />
        <Route
          path="/consolidated/:hash/:consolidatedid/:metaid/show"
          component={ConsolidatedViewPage}
        />
        <Route path="/login" component={LoginPage} />
        <Route path="/register" exact component={RegisterPage} />
        <Route path="/reset" exact component={ResetPage} />
        <Route path="/" exact component={HomePage} />
        <Route path="*" component={ErrorPage} />
        <Redirect to="/" />
        {/* <Redirect to='/' />   */}
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route
            path={"/" + this.props.compId + "/surveys"}
            component={SurveysPage}
          />
          <Route
            path={"/" + this.props.compId + "/users"}
            component={UsersPage}
            exact
          />
          <Route
            path={"/" + this.props.compId + "/logs"}
            component={LogsPage}
          />
          <Route path={"/user/" + this.props.surveyId} component={UserPage} />
          <Route path="/logout" component={Logout} />
          <Route path="/:hash/:metaid/start" component={UserSurveyPage} />
          <Route
            path="/consolidated/:hash/:consolidatedid/:metaid/show"
            component={ConsolidatedViewPage}
          />
          <Route
            path={"/" + this.props.compId + "/edit"}
            component={EditCompanyPage}
          />
          <Route path="/" exact component={HomePage} />
          <Redirect to="/" />
          <Redirect path="*" component={HomePage} />
        </Switch>
      );
    }

    if (
      this.props.isAuthenticated &&
      this.props.email === "evaladmin03092014@cimplyfive.com"
    ) {
      routes = (
        <Switch>
          <Route
            path={"/" + this.props.compId + "/surveys"}
            component={SurveysPage}
          />
          <Route
            path={"/" + this.props.compId + "/users"}
            component={UsersPage}
            exact
          />
          <Route
            path={"/" + this.props.compId + "/logs"}
            component={LogsPage}
          />
          <Route path={"/user/" + this.props.surveyId} component={UserPage} />
          <Route path="/logout" component={Logout} />
          <Route path="/admincontrolspanel" component={AdminPage} />
          <Route path="/:hash/:metaid/start" component={UserSurveyPage} />
          <Route
            path="/consolidated/:hash/:consolidatedid/:metaid/show"
            component={ConsolidatedViewPage}
          />
          <Route
            path={"/" + this.props.compId + "/edit"}
            component={EditCompanyPage}
          />
          <Route path="/" exact component={HomePage} />
          <Redirect to="/" />
          <Redirect path="*" component={HomePage} />
        </Switch>
      );
    }

    return (
      <AppLogout>
        <div>
          <Layout>{routes}</Layout>
        </div>
      </AppLogout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    compId: state.surveyEditer.company.id,
    isAuthenticated: state.auth.token !== null,
    surveyId: state.surveyEditer.survey.id,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(logout()),
    onAuthSuccess: (token, userId, email) =>
      dispatch(authSuccess(token, userId, email)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
