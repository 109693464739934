import React from "react";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from 'react-bootstrap/Modal';

import classes from "./OverviewData.module.css";

const overviewData = props => {

  const submit = () => {
    confirmAlert({
      title: "Delete Survey",
      message:
        "Are you sure you want to delete this survey? This action cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: props.delete
        },
        {
          label: "No"
        }
      ]
    });
  };

  return (
    <div className={classes.Container}>
      <div className={classes.OverviewData}>
        <div style={{ width: "100%" }} className={classes.divitm}>
          <div className={classes.item} style={{ marginRight: "20px" }}>
            <p>Total Number Of Responses:</p>
            <div>
              <h4>{props.count}</h4>
              {props.responders && props.responders.length > 0 ? 
              <label className={classes.ResponderDetail} onClick={props.handleShow}>Click here</label> : ''}
            </div>
          </div>
          <div className={classes.item} style={{ marginLeft: "20px" }}>
            <p>Number Of Responses Today:</p>
            <div>
              <h4>{props.currentCount}</h4>
            </div>
          </div>
        </div>

      </div>
      <div className={classes.item} style={{ width: "100vw", marginTop: "20px", marginBottom: "20px" }}>
        <p>Last Submit At:</p>
        <div>
          <p>{props.lastSubmit}</p>
        </div>
      </div>

      <button onClick={submit} className={classes.DeleteButton} style={{ display: props.role === "Admin" ? null : "none" }}>
        <Icon className={classes.Delete}>delete</Icon> Delete Survey
      </button>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Responders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.responders.map(responder => (
              <p>{responder}</p>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default overviewData;
