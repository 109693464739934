import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../Store/actions/actionsTypes";
import LogItem from "../../Components/LogList/LogItem";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { fetchLogs } from "../../Store/actions/logs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

class LogList extends Component {
    componentDidMount() {
        this.props.onFetchLogs(this.props.compId);
    }

    render() {
        let logList = <Spinner />;
        if (!this.props.loading) {
            logList =
                <div>
                    <LogItem
                        logs={this.props.logs}
                    />
                </div>
        }
        return <div>{logList}</div>;
    }
}

const mapStateToProps = state => {
    return {
        compId: state.surveyEditer.company.id,
        logs: state.logs.logs,
        loading: state.logs.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLogs: (compId) => dispatch(fetchLogs(compId))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogList));