import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TransferList from "./TransferList";
import { COMPARATIVE } from "../../Store/actions/actionsTypes";
import "./GroupingDialog.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  let {
    openGrouping,
    setOpenGrouping,
    surveyRaw,
    submitSurvey,
    updateStore,
    editGroup,
  } = props;
  const [groupName, setGroupName] = React.useState(
    editGroup ? editGroup.obj.groupName : ""
  );
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(
    surveyRaw.type === COMPARATIVE
      ? surveyRaw.ungrouped_questions
      : surveyRaw.question_order
  );
  const [right, setRight] = React.useState(
    editGroup ? editGroup.obj.questions : []
  );

  const handleClose = (e, caller = "") => {
    e.stopPropagation();
    if (caller === "SAVE") {
      let updatedSurvey = { ...surveyRaw };
      if (editGroup) {
        updatedSurvey["grouped_questions"].splice(editGroup.ind, 1, {
          groupName: groupName,
          questions: right,
        });
        updatedSurvey["ungrouped_questions"] = [...left];
      } else {
        if (updatedSurvey["grouped_questions"]) {
          updatedSurvey["grouped_questions"].push({
            groupName: groupName,
            questions: right,
          });
          updatedSurvey["ungrouped_questions"] = [...left];
        } else if (!updatedSurvey["grouped_questions"]) {
          updatedSurvey["grouped_questions"] = [];
          updatedSurvey["grouped_questions"].push({
            groupName: groupName,
            questions: right,
          });
          // updatedSurvey["type"] = COMPARATIVE;
          updatedSurvey["ungrouped_questions"] = [...left];
        }
      }
      updateStore(updatedSurvey);
    }

    setOpenGrouping(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openGrouping}
        maxWidth={"md"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Group Questions
        </DialogTitle>
        <DialogContent dividers>
          <label>
            Group Name:{" "}
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            ></input>
          </label>
          <TransferList
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
            checked={checked}
            setChecked={setChecked}
            survey={surveyRaw}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={(e) => handleClose(e, "SAVE")}
            color="primary"
            disabled={!(groupName && right.length > 0)}
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
