import React from "react";
import { QuestionTypes } from "../../ulitity/constants/Questions";
import SingleLineText from "./Questions/SingleLineText";
import MultiLineText from "./Questions/MultiLineText";
import Multichoices from "./Questions/Multichoices";
import Checkboxes from "./Questions/Checkboxes";
import Dropdown from "./Questions/Dropdown";
import RatingStars from "./Questions/Rating";
import { PREVIEW_WRAPPER } from "../../Store/actions/actionsTypes";

export const questionMap = {
  [QuestionTypes.SINGLE_LINE_TEXT]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <SingleLineText
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
  [QuestionTypes.MULTI_LINE_TEXT]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <MultiLineText
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
  [QuestionTypes.MULTI_CHOICE]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <Multichoices
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
  [QuestionTypes.CHECKBOXES]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <Checkboxes
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
  [QuestionTypes.DROPDOWN]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <Dropdown
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
  [QuestionTypes.RATING]: (
    props,
    caller = "",
    surveyee_count = "",
    idAddOn = ""
  ) => {
    return (
      <RatingStars
        {...props}
        caller={caller}
        surveyee_count={surveyee_count}
        idAddOn={idAddOn}
      />
    );
  },
};

const questionWrapper = (props) => {
  let { question, surveyee_count } = props;
  const { id } = props;
  const { result } = props;
  if (result && result.result) {
    question.result = result.result;
  }
  let QuestionComponent = questionMap[question.type](
    question,
    PREVIEW_WRAPPER,
    surveyee_count
  );
  return (
    <div style={{ display: "flex", wordWrap: "break-word" }}>
      <div
        style={{
          padding: "10px",
          fontSize: "20px",
          marginTop: "2px",
          marginLeft: "10px",
        }}
      >
        {id + 1 + ")"}
      </div>
      {QuestionComponent}
    </div>
  );
};

export default questionWrapper;
