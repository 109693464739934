import React from "react";
import { Field } from "formik";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";

import classes from "./Question.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const Dropdown = (props) => {
  const {
    title,
    options,
    _id,
    isRequired,
    caller,
    surveyee_count,
    idAddOn,
    result,
  } = props;
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";
  let valueCheck = result
    ? result[_id]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id][idAddOn]
        : result[_id]
      : ""
    : "";
  return (
    <div className={classes.Question}>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <div>
          <Field
            component="select"
            name={`${_id}${addOnId}`}
            className={classes.Input}
            defaultValue={valueCheck}
          >
            <option></option>
            {options.map((option, index) => {
              return (
                <option value={option._id} key={option._id}>
                  {option.content}
                </option>
              );
            })}
          </Field>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
