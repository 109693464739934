import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import * as actionTypes from "../Store/actions/actionsTypes";
import OverviewEdit from "../Components/Overview/OverviewEdit";
import OverviewData from "../Components/Overview/OverviewData";
import Spinner from "../Components/UI/Spinner/Spinner";
import classes from "./OverviewPage.module.css";
import firebase from "firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSurvey, toggleSubmit } from "../Store/actions/surveyEditer";
import { fetchData } from "../Store/actions/data";
import {
  lastSubmitDate,
  currentResultToday,
  getResponders,
} from "../ulitity/ulitity";
import { fetchInvitee } from "../Store/actions/logs";
import { db } from "../firebase-config";

const notify = () => {
  toast.error("Survey Deleted Successfully", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

var today = new Date().toISOString().split("T")[0];

class OverviewPage extends Component {
  componentDidMount() {
    this.props.onfetchSurvey(
      this.props.surveyId,
      this.props.token,
      this.props.compId
    );
    this.props.onfetchData(
      this.props.surveyId,
      this.props.token,
      this.props.compId
    );
    this.props.onFetchInvitee(this.props.compId, this.props.surveyId);
    firebase.analytics().logEvent("Survey Details page is rendered");
  }

  state = {
    show: false,
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  deleteSurveyHandler = (surveyId) => {
    db.collection(`surveys/${this.props.compId}/surveysList`)
      .doc(String(surveyId))
      .delete()
      .then(() => {
        notify();
        this.props.onRedirect();
      });
  };

  render() {
    let overview = <Spinner />;
    if (!this.props.isLoading) {
      overview = (
        <div className={classes.OverviewPage}>
          {this.props.redirect ? <Redirect to="/" /> : null}

          <OverviewEdit
            title={this.props.title}
            creatorDate={this.props.creatorDate}
            lastModified={this.props.lastModified}
            role={this.props.role}
            userId={this.props.userId}
            update={(opentill) => {
              this.props.onUpdateOpenTill(this.props.surveyId, opentill);
            }}
            openTill={this.props.openTill}
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            submit={this.props.submitting}
            onToggle={() => {
              if (this.props.role === "Admin" || this.props.role === "Admin") {
                this.props.onToggle(
                  this.props.surveyId,
                  this.props.token,
                  !this.props.submitting,
                  this.props.compId,
                  this.props.title
                );
              } else {
                alert("Rights Mismatch");
              }
            }}
            responders={this.props.responders.length}
            sentCount={this.props.sentCount.length}
          />
          <OverviewData
            delete={() => {
              if (this.props.role === "Admin") {
                this.deleteSurveyHandler(this.props.surveyId);
              } else {
                alert("Rights Mismatch");
              }
            }}
            count={this.props.submitCount}
            lastSubmit={this.props.lastDate}
            currentCount={this.props.currentCount}
            role={this.props.role}
            responders={this.props.responders}
            handleShow={this.handleShow}
            handleClose={this.handleClose}
            showModal={this.state.show}
          />
        </div>
      );
    }

    return <Fragment>{overview}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    surveyId: state.surveyEditer.survey.id,
    token: state.auth.token,
    userId: state.auth.userId,
    title: state.surveyEditer.survey.title,
    creatorDate: state.surveyEditer.survey.creatorDate,
    lastModified: state.surveyEditer.survey.lastModified,
    compId: state.surveyEditer.company.id,
    role: state.surveyEditer.company.role,
    openTill: state.surveyEditer.survey.openTill,
    redirect: state.surveyEditer.redirect,
    isLoading: state.surveyEditer.surveyLoading,
    submitting: state.surveyEditer.survey.submitting,
    submitCount: state.data.results.length,
    lastDate: lastSubmitDate(
      state.data.results,
      state.surveyEditer.survey.creatorDate
    ),
    currentCount: currentResultToday(state.data.results),
    responders: getResponders(state.data.results),
    sentCount: state.logs.invitee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onfetchSurvey: (surveyId, token, compId) =>
      dispatch(fetchSurvey(surveyId, token, compId)),
    onUpdateOpenTill: (surveyId, openTill) =>
      dispatch({
        type: actionTypes.RESET_OPENTILL,
        openTill: openTill,
        surveyId: surveyId,
      }),
    onfetchData: (surveyId, token, compId) =>
      dispatch(fetchData(surveyId, token, compId)),
    onRedirect: () => dispatch({ type: actionTypes.REDIRECT_PAGE }),
    onToggle: (surveyId, token, submit, compId, title) =>
      dispatch(toggleSubmit(surveyId, token, submit, compId, title)),
    onFetchInvitee: (compId, surveyId) =>
      dispatch(fetchInvitee(compId, surveyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
