import React, { useEffect, useRef } from "react";
import classes from "./ResizableTextarea.module.css";

const ResizableTextarea = (props) => {
  const { title = "", additionalClass = "", conditionalStyle = "" } = props;
  const textRef = useRef({});

  useEffect(() => {
    if (Object.keys(textRef.current).length > 0) {
      textRef.current.style.height = "5px";
      textRef.current.style.height = textRef.current.scrollHeight + "px";
    }
  }, [title]);

  return (
    <textarea
      ref={textRef}
      disabled
      style={conditionalStyle ? conditionalStyle : {}}
      className={`${classes.Text} ${additionalClass ? additionalClass : ""}`}
      value={title}
    ></textarea>
  );
};
export default ResizableTextarea;
