import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminLogList from "../Containers/AdminLogList/AdminLogList";
import classes from "./AdminPage.module.css";
import Paper from "@material-ui/core/Paper";
import Notiflix from "notiflix-react";
import firebase from "firebase";
import http from "../lib/http-service";
import { STORAGE_CONSTANTS } from "../ulitity/constants/storageConstants";
import { db } from "../firebase-config";

class AdminPage extends Component {
  state = {
    email: "",
    name: "",
    credits: "",
    disabled: "",
    verified: "",
    created: "",
    login: "",
    uid: "",
    token: "",
  };

  componentDidMount() {
    this.setState({ token: this.props.token });
  }

  getUser = async (email, token) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Searching User");
    http.get("/testuser?email=" + email).then((response) => {
      const { data } = response;
      if (data.response === "Successful") {
        Notiflix.Loading.Remove();
        this.setState({
          email: data.email,
          name: data.displayName,
          credits: data.credits,
          disabled: data.disabled === false ? "False" : "True",
          created: data.creationTime,
          login: data.lastSigninTime,
          uid: data.uid,
          verified: data.emailVerified === false ? "False" : "True",
        });
      } else {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Search Failed",
          "Either this user does not exist or an unhandled error has ocurred",
          "ok"
        );
      }
    });
  };

  addcredit = (uid) => {
    var comp = this;
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Adding Credits");

    db.collection("users")
      .doc(String(uid))
      .get()
      .then((doc) => {
        const userData = { ...doc.data(), id: doc.id };
        let totalcreds = Number(userData.credits) + 1;

        db.collection("users")
          .doc(String(uid))
          .update({ credits: totalcreds })
          .then(() => {
            this.setState({ credits: totalcreds });

            db.collection("adminlogs")
              .add({
                message: "One credit was added to " + comp.state.email,
                time: new Date().toLocaleString(),
              })
              .then((res) => {
                Notiflix.Loading.Remove();
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Success(
                  "One Credit Added Successfully",
                  "This user has been granted one credit.",
                  "ok"
                );
              });
          })
          .catch((err) => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Failure(
              "Add Credit Failed",
              "Credits could not be added because: " + err,
              "ok"
            );
          });
      })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Operation Failed",
          "This operation failed, please try again.ERR: " + err,
          "ok"
        );
      });
  };

  removecredit = (uid) => {
    var comp = this;
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Removing Credits");

    db.collection("users")
      .doc(String(uid))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const docData = { ...doc.data() };
        if (Number(docData.credits) > 0) {
          let totalCredits = Number(docData.credits) - 1;
          db.collection("users")
            .doc(String(uid))
            .update({ credits: totalCredits })
            .then(() => {
              this.setState({ credits: totalCredits });
              db.collection("adminlogs")
                .add({
                  message: "One credit was deducted from " + comp.state.email,
                  time: new Date().toLocaleString(),
                })
                .then((res) => {
                  Notiflix.Loading.Remove();
                  Notiflix.Report.Init({
                    width: "400px",
                    titleFontSize: "20px",
                    messageFontSize: "17px",
                    success: { buttonBackground: "#EE3A2C" },
                  });
                  Notiflix.Report.Success(
                    "One Credit Removed Successfully",
                    "One credit deducted from user successfully",
                    "ok"
                  );
                });
            })
            .catch((err) => {
              Notiflix.Loading.Remove();
              Notiflix.Report.Init({
                width: "400px",
                titleFontSize: "20px",
                messageFontSize: "17px",
                success: { buttonBackground: "#EE3A2C" },
              });
              Notiflix.Report.Failure(
                "Remove Credit Failed",
                "Credits could not be removed because: " + err,
                "ok"
              );
            });
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Report.Init({
            width: "400px",
            titleFontSize: "20px",
            messageFontSize: "17px",
            success: { buttonBackground: "#EE3A2C" },
          });
          Notiflix.Report.Failure(
            "Remove Credit Failed",
            "User already has 0 credits",
            "ok"
          );
        }
      })
      .catch((err) => {
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Operation Failed",
          "This operation failed, please try again.ERR: " + err,
          "ok"
        );
      });
  };

  render() {
    return (
      <div>
        <div className={classes.Header}>
          <h5 className={classes.HeaderText}>Welcome Admin!</h5>
          <div className={classes.vertical}></div>
          <Link to={"/"}>
            <button className={classes.Back}>Back</button>
          </Link>
        </div>
        <div className={classes.Search}>
          <input
            id="emailval"
            type="text"
            placeholder="Search Users By Email"
            className={classes.searchbox}
          ></input>
          <button
            className={classes.searchbtn}
            onClick={() => {
              this.getUser(
                document.getElementById("emailval").value,
                this.props.token
              );
            }}
          >
            Search
          </button>
        </div>
        <div>
          <Paper elevation={2} className={classes.UserInfo}>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Email:</h4>
              <h4 className={classes.Value} id="email">
                {this.state.email}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Display Name:</h4>
              <h4 className={classes.Value} id="name">
                {this.state.name}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Credits:</h4>
              <h4 className={classes.Value} id="credits">
                {this.state.credits}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Disabled:</h4>
              <h4 className={classes.Value} id="disabled">
                {this.state.disabled}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Creation Date:</h4>
              <h4 className={classes.Value} id="created">
                {this.state.created}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Last Login On:</h4>
              <h4 className={classes.Value} id="login">
                {this.state.login}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>UID:</h4>
              <h4 className={classes.Value} id="uid">
                {this.state.uid}
              </h4>
            </div>
            <div style={{ display: "flex" }}>
              <h4 className={classes.Attribute}>Email Verified:</h4>
              <h4 className={classes.Value} id="verified">
                {this.state.verified}
              </h4>
            </div>
            <button
              className={classes.addhbtn}
              style={{
                display: this.state.email === "" ? "none" : null,
                backgroundColor: "#28A745",
              }}
              onClick={() => {
                this.addcredit(this.state.uid);
              }}
            >
              Add One Credit
            </button>
            <button
              className={classes.addhbtn}
              style={{
                display: this.state.email === "" ? "none" : null,
                marginLeft: "20px",
              }}
              onClick={() => {
                this.removecredit(this.state.uid);
              }}
            >
              Remove One Credit
            </button>
          </Paper>
        </div>
        <AdminLogList />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    compId: state.surveyEditer.company.id,
    compname: state.surveyEditer.company.name,
    survname: state.surveyEditer.survey.title,
    openTill: state.surveyEditer.survey.openTill,
  };
};

export default connect(mapStateToProps)(AdminPage);
