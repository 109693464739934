import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { fetchData } from "../Store/actions/data";
import Spinner from "../Components/UI/Spinner/Spinner";
import ResultTableContainer from "../Containers/Results/ResultTableContainer";
import ModalContainer from "../Containers/Results/ModalContainer";
import { db } from "../firebase-config";

class DataPage extends Component {
  componentDidMount() {
    firebase.analytics().logEvent("Data page is rendered");
    db.collection(`logs/${this.props.compId}/logsList`)
      .add({
        name: firebase.auth().currentUser.displayName,
        email: firebase.auth().currentUser.email,
        timestamp: new Date().getTime(),
        payload: { survey: this.props.title },
        entity: "Data",
        operation: "READ",
      })
      .then((res) => {
        this.props.onFetchData(
          this.props.surveyId,
          this.props.token,
          this.props.compId
        );
      })
      .catch((err) => {
        alert("Could not fetch Data");
      });
  }

  render() {
    if (this.props.isLoading) {
      return <Spinner />;
    }

    return (
      <Fragment>
        <ResultTableContainer {...this.props} />
        <ModalContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    isLoading: state.data.isLoading,
    title: state.surveyEditer.survey.title,
    compId: state.surveyEditer.company.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (surveyId, token, compId) =>
      dispatch(fetchData(surveyId, token, compId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataPage);
