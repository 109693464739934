import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InviteeItem from "../../Components/InviteeList/InviteeItem";
import * as actionTypes from "../../Store/actions/actionsTypes";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { fetchMailAudits } from "../../Store/actions/mailAudit";
import classes from "./InviteeList.module.css";

class InviteeList extends Component {
  componentDidMount() {
    this.props.onFetchMailAudits(
      this.props.surveyId,
      this.props.currentAuditType
    );
  }

  render() {
    let mailAudits = <Spinner />;
    if (!this.props.loading) {
      if (Object.keys(this.props.mailAudits).length > 0) {
        mailAudits = (
          <div>
            <InviteeItem
              IsReportAudit={this.props.currentAuditType ? true : false}
              MailAuditsData={this.props.mailAudits}
            />
          </div>
        );
      } else {
        mailAudits = (
          <div>
            <h2 className={classes.NoData}>No Data Found...</h2>
          </div>
        );
      }
    }
    return <div key={this.props.mailAudits}>{mailAudits}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    compId: state.surveyEditer.company.id,
    invitee: state.logs.invitee,
    loading: state.mailAudit.loading,
    surveyId: state.surveyEditer.survey.id,
    mailAudits: state.mailAudit.mailAudits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMailAudits: (surveyId, currentAuditType) =>
      dispatch(fetchMailAudits(surveyId, currentAuditType)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InviteeList)
);
