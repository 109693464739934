import React, {Component} from 'react';
import PropsTypes from 'prop-types';
import classes from './Flex.module.css';

class RatingField extends Component {
    render() { 
        const { key, onChange, value } = this.props;
        return (
            <input
                style = {{backgroundColor: 'lightgray'}}
                type = "button"
                id = {key}
                onClick = {onChange}
                value = {value}
            />
        );
    }
}

RatingField.PropsTypes = {
    content: PropsTypes.string,
    onChange: PropsTypes.func,
    key: PropsTypes.string,
    name: PropsTypes.string,
}
 
export default RatingField;