import { useEffect } from "react";
import { logout } from "../../Store/actions/authentication";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  let timer;

  // this function sets the timer that logs out the user after 30 mins
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1800000); // 1800000ms = 30 mins. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 30 mins of inactivity resets.
  // However, if none of the event is triggered within 30 mins, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    events.forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user and redirecting url to /logout page.
  const logoutAction = () => {
    window.location.pathname = "/";
    logout();
  };

  return children;
};

export default AppLogout;
