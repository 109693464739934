import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./UserItem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import firebase from "firebase";

class UserItem extends Component {
  render() {
    let isDeleteEnabled = true;
    let loggedInUserEmail = firebase.auth().currentUser.email;
    // const filteredAdmins = this.props.users.filter(
    //   (user) => user.role == "Admin"
    // );
    // if (filteredAdmins.length > 1) {
    //   isDeleteEnabled = true;
    // }
    return (
      <div className={classes.UserItem}>
        <Paper elevation={1}>
          <div className={classes.OneItem}>
            <div className={classes.User}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead
                    style={{ border: "1px solid #ddd" }}
                    className={classes.head}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ color: "white", fontSize: "17px" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "white", fontSize: "17px" }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "white", fontSize: "17px" }}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "white", fontSize: "17px" }}
                      >
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.users.map((user) => (
                      <TableRow key={user.name} className={classes.row}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          className={classes.td}
                        >
                          {user.name}
                        </TableCell>
                        <TableCell align="center" className={classes.td}>
                          {user.email}
                        </TableCell>
                        <TableCell align="center" className={classes.td}>
                          <Chip
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor: "#0F5293",
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {user.role.charAt(0)}
                              </Avatar>
                            }
                            label={user.role}
                            clickable
                            style={{
                              backgroundColor: "#1976D3",
                              marginLeft: "15px",
                              color: "white",
                              fontSize: "14px",
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.td}>
                          {isDeleteEnabled && loggedInUserEmail !== user.email ? (
                            <button
                              className={classes.Delete}
                              style={{
                                display:
                                  this.props.UserRole !== "Admin"
                                    ? "none"
                                    : "normal",
                              }}
                              onClick={() => this.props.delete(user)}
                            >
                              Delete
                            </button>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    UserRole: state.surveyEditer.company.role,
  };
};

export default connect(mapStateToProps)(UserItem);
