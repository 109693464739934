import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import classes from "./Login.module.css";
import { Form } from "bootstrap-4-react";
import { confirmAlert } from "react-confirm-alert";
import Input from "../../Components/UI/Input/Input";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { auth, resetAuthError } from "../../Store/actions/authentication";
import firebase from "firebase";
import blissdocs from "../../assets/images/bliss-docs-website.png";
import blisslisted from "../../assets/images/BLISSLISTED.png";
import { Alert, AlertTitle } from "@material-ui/lab";
import Notiflix from "notiflix-react";
import { autoLogin } from "../../Store/actions/autoLogin";

const CONSTANTS = require("../../constants");

class Login extends Component {
  state = {
    controls: {
      email: {
        elementLabel: "Email Adress",
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Registered Email Address",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        validity: false,
        touched: false,
      },
      password: {
        elementLabel: "Password",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
        },
        value: "",
        validation: {
          required: true,
          minLength: 6,
        },
        validity: false,
        touched: false,
      },
    },
    isSignup: false,
  };

  componentDidMount() {
    this.props.onTryAutoLogin();
    if (!this.props.buildingBurger && this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    return isValid;
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        validity: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();

    this.props.onAuth(
      this.state.controls.email.value,
      this.state.controls.password.value,
      this.state.isSignup
    );
  };

  sendLink = (event) => {
    if (this.state.controls.email.value.trim() === "") {
      confirmAlert({
        title: "Email Missing",
        message: "Please enter an email address",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      Notiflix.Loading.Init({ svgColor: "#3bbfde" });
      Notiflix.Loading.Pulse("Sending Link...");
      var linkEmail = this.state.controls.email.value;
      var actionCodeSettings = {
        url: CONSTANTS.URL_CONFIG.BASE_URL + "login",
        handleCodeInApp: true,
      };
      event.preventDefault();
      firebase
        .auth()
        .sendSignInLinkToEmail(linkEmail, actionCodeSettings)
        .then(function () {
          window.localStorage.setItem("emailForSignIn", linkEmail);
          Notiflix.Loading.Remove();
          confirmAlert({
            title: "Email Signin Link",
            message:
              "An email signin link has been successfully sent to the provided email address",
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        })
        .catch(function (error) {
          Notiflix.Loading.Remove();
          confirmAlert({
            title: "Email Signin Link Failed",
            message: error.message,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        });
    }
  };

  render() {
    const formElementArray = [];
    for (let key in this.state.controls) {
      formElementArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (
        <Alert variant="filled" severity="error">
          {this.props.error.message}
        </Alert>
      );
    }

    let form = (
      <div>
        <Form onSubmit={this.submitHandler}>
          {formElementArray.sort().map((formElement) => (
            <>
              <Input
                key={formElement.id}
                className={classes.fullWidth}
                //label={formElement.config.elementLabel}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.validity}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={(event) =>
                  this.inputChangedHandler(event, formElement.id)
                }
                placeholder={formElement.config.elementLabel}
              />
            </>
          ))}
          <Link onClick={() => this.props.onResetClick()} to="/reset">
            <h6 className={classes.ForgotLink}>Forgot Password?</h6>
          </Link>
          {errorMessage}
          <button type="submit" className={classes.SubmitButton}>
            Login With Password
          </button>
        </Form>
        <button
          type="submit"
          className={classes.SubmitButton}
          onClick={this.sendLink}
        >
          Send Email Signin Link
        </button>
      </div>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <div className={classes.pageWrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.formWrapper}>
            <h2 className={classes.Welcome}>Welcome Back!</h2>
            {authRedirect}
            {form}
            <hr></hr>

            <Link to="/register">
              <button className={classes.Register}>Register Now</button>
            </Link>
            <p className={classes.Terms}>
              By continuing you agree to Cimplyfive's{" "}
              <span className={classes.terms_link}>
                <a
                  href="https://www.cimplyfive.com/cimplyfives-terms-and-conditions/"
                  target="_blank"
                >
                  terms and conditions
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className={classes.imageWrapper}>
          <div className={classes.sidetext}>
            <h2 className={classes.headerside}>Board Evaluation Redefined.</h2>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isSignup) =>
      dispatch(auth(email, password, isSignup)),
    onTryAutoLogin: () => {
      dispatch(autoLogin());
    },
    onResetClick: () => {
      dispatch(resetAuthError());
    },
    //onSetAuthRedirectPath: () => dispatch(setAuthRedirectPath('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
