import React from "react";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";

import classes from "./QuestionPreview.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const Checkboxes = (props) => {
  const { title, options, _id, isRequired, caller, surveyee_count } = props;

  return (
    <>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      <div>
        {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0
          ? ""
          : options.map((option, index) => {
              return (
                <div key={index}>
                  <label>
                    <input
                      type="checkbox"
                      disabled
                      name={`${_id}[]`}
                      value={option._id}
                      className={classes.check}
                    />
                    {option.content}
                  </label>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default Checkboxes;
