import React from "react";
import firebase from "firebase";
import GreenSwitcher from "../UI/Switcher/GreenSwitch";
import GreySwitcher from "../UI/Switcher/GreySwitch";
import Notiflix from "notiflix-react";
import moment from "moment";
import classes from "./OverviewEditer.module.css";
import { db } from "../../firebase-config";

var today =
  new Date().toISOString().split("T")[0] +
  "T" +
  new Date().getHours() +
  ":" +
  new Date().getMinutes();
const overviewEdit = (props) => {
  const creatorDate = new Date(props.creatorDate).toString();

  const lastModified =
    typeof props.lastModified == "object"
      ? props.lastModified?.toDate().toString()
      : new Date(props.lastModified).toString();
  const responders = props.responders;
  const sentCount = props.sentCount;
  let openTill = props.openTill;
  function changeDate() {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Updating End Date");
    var x = document.getElementById("dateval").value;
    let difference =
      moment(x).format("x") - moment(new Date().toISOString()).format("x");

    db.collection(`surveys/${props.compId}/surveysList`)
      .doc(String(props.surveyId))
      .get()
      .then((doc) => {
        if (!doc?.exists) return;

        const data = {
          ...doc.data(),
          content: { ...doc.data().content, openTill: x },
        };

        db.collection(`surveys/${props.compId}/surveysList`)
          .doc(String(props.surveyId))
          .set(data)
          .then(() => {
            document.getElementById("dateval").value = x;
            props.update(x);
            Notiflix.Loading.Remove();
            Notiflix.Notify.Init({ position: "right-bottom" });
            Notiflix.Notify.Success("End Date Updated Successfully");
          });
      });
  }

  function checExpiryDate() {
    var expiryDate = new Date(props.openTill);
    if (expiryDate < new Date()) {
      return true;
    }
    return false;
  }

  return (
    <div className={classes.OverviewEditer}>
      <h2>{props.title}</h2>
      <p>
        <strong>Created At: </strong>
        {creatorDate}
      </p>
      <p>
        <strong>Last Modified At: </strong>
        {lastModified}
      </p>
      <p></p>
      <div>
        <label
          style={{
            display:
              props.role === "Auditor" || props.role === "Director"
                ? "none"
                : null,
            marginRight: "15px ",
          }}
        >
          <b>Survey End Date: </b>
        </label>
        <input
          type="datetime-local"
          min={today}
          value={openTill}
          id="dateval"
          style={{
            display:
              props.role === "Auditor" || props.role === "Director"
                ? "none"
                : null,
          }}
          onChange={() => changeDate()}
          className={classes.datepick}
        />
        <p
          style={{
            display:
              props.role === "Auditor" || props.role === "Director"
                ? null
                : "none",
          }}
        >
          <strong>Survey End Date:</strong>
          {props.openTill}
        </p>
      </div>
      <p>
        <strong>Number Of Invitees: </strong>
        {sentCount}
      </p>
      <p>
        <strong>Number Of Respondents: </strong>
        {responders}
      </p>

      <div style={{ display: props.role === "Admin" ? null : "none" }}>
        {checExpiryDate() ? (
          <GreySwitcher checked={false} label="Not Accepting Responses" />
        ) : (
          <GreenSwitcher
            checked={props.submit}
            onChange={props.onToggle}
            label="Accepting Responses"
          />
        )}
      </div>
    </div>
  );
};

export default overviewEdit;
