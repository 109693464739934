import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./AdminLogItem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Alert from "@material-ui/lab/Alert";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

class AdminLogItem extends Component {
  render() {
    return (
      <div>
        <div className={classes.item} style={{ margin: "25px" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                style={{ border: "1px solid #ddd" }}
                className={classes.head}
              >
                <TableRow>
                  {/* <TableCell align="center" style={{ color: "white", fontSize: "17px" }}>Name</TableCell> */}
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Credit Log Message
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Date And Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.adminlogs.map((log, index) => (
                  <TableRow key={index} className={classes.row}>
                    {/* <TableCell component="th" scope="row" align="center" className={classes.td}>
                                        {log.name}
                                    </TableCell> */}
                    <TableCell align="center" className={classes.td}>
                      {log.message}
                    </TableCell>
                    <TableCell align="center" className={classes.td}>
                      {log.time}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(AdminLogItem);
