import React from 'react';

import classes from './CompanyLogo.module.css';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';


const companyLogo = () => (
    <div className={classes.companyBox}>
        <img src={window.selectedCompany?.logo} className={classes.toolbarLogo} alt="" />
        <div className={classes.compMemb}>
            <div>
                <h4 className={classes.compname}>{window.selectedCompany?.name}</h4>
            </div>
            <div>
                <p className={classes.compname}>{window.selectedCompany?.role}</p>
            </div>
            {/* <Tooltip title="Your Current Role">
                <Chip
                    label={window.selectedCompany?.role}
                    clickable
                    style={{ backgroundColor: "#34baf2", marginLeft: "15px", color: "white", fontSize: "12px", display: "inline-flex", "margin-bottom": "4px" }}
                />
            </Tooltip> */}
        </div>
    </div>
);

export default companyLogo;
