import React, { Component } from "react";
import { connect } from "react-redux";

import { updateSurvey, groupQuestions } from "../../Store/actions/surveyEditer";
import { assembleSurvey } from "../../Store/actions/surveyEditer";
import GroupingDialog from "../../Components/SurveyPreview/GroupingDialog";
import {
  COMPARATIVE,
  CHANGE_SURVEY_STATE,
} from "../../Store/actions/actionsTypes";

class GroupingDialogContainer extends Component {
  preventPropagation = (e) => {
    e.stopPropagation();
  };
  render() {
    return (
      <div onClick={this.preventPropagation}>
        <GroupingDialog
          openGrouping={this.props.openGrouping}
          setOpenGrouping={this.props.setOpenGrouping}
          surveyRaw={this.props.surveyRaw}
          submitSurvey={() =>
            this.props.onSubmit(
              this.props.surveyId,
              this.props.token,
              this.props.survey,
              this.props.compId
            )
          }
          editGroup={this.props.editGroup}
          submit={this.props.isSubmitting}
          updateStore={(updatedSurvey) => this.props.updateStore(updatedSurvey)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    survey: assembleSurvey(state.surveyEditer.survey),
    isSubmitting: state.surveyEditer.submitLoading,
    compId: state.surveyEditer.company.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (surveyId, token, data, compId) =>
      dispatch(updateSurvey(surveyId, token, data, compId)),
    updateStore: (updatedSurvey) => {
      dispatch(groupQuestions(updatedSurvey));
      dispatch({ type: CHANGE_SURVEY_STATE, payload: false });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupingDialogContainer);
