import { connect } from "react-redux";

import QuestionListMobileView from "../../Components/EditPanel/QuestionListMoblie";

import { addQuestion } from "../../Store/actions/surveyEditer";
import { QuestionDescriptions } from "../../ulitity/constants/Questions";

export default connect((state) => {
  return {
    questions: QuestionDescriptions.map((q) => {
      return {
        text: q.text,
        action: () => addQuestion(q.type, state.surveyEditer.survey?.type),
      };
    }),
  };
})(QuestionListMobileView);
