import React from "react";

import OnlyPieReportItem from "./OnlyPieReportItem";
import classes from "./Report.module.css";

const report = props => {
    const { reportResult, results } = props;

    return (
        <div className={classes.Report}>
            {/* <h2 className={classes.Counter}>No. Of Responses: {results.length}</h2> */}
            {reportResult.map((d, index) => {
                return <OnlyPieReportItem data={d} key={d._id} />;
            })}
        </div>
    );
};

report.defaultProps = {};

export default report;
