import EditQuestionPanelView from "../../Components/EditPanel/EditQuestionPanel";
import { connect } from "react-redux";
import { updateQuestion } from "../../Store/actions/surveyEditer";
import { getActiveQuestion } from "../../Store/reducers/getActiveQuestion";
import React, { Component } from "react";
import { CHANGE_SURVEY_STATE } from "../../Store/actions/actionsTypes";
class EditQuestionPanelViewContainer extends Component {
  render() {
    return <EditQuestionPanelView {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    question: getActiveQuestion(state.surveyEditer),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuestion: (qid, params) => {
      dispatch({ type: CHANGE_SURVEY_STATE, payload: false });
      dispatch(updateQuestion(qid, params));
    },
  };
};

const EditQuestionPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditQuestionPanelViewContainer);

export default EditQuestionPanel;
