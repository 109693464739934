import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Notiflix from "notiflix-react";
import NewUserView from "../../Components/NewUser/NewUser";
import { fetchUsers } from "../../Store/actions/users";
import firebase from "firebase";
import http from "../../lib/http-service";
import { db } from "../../firebase-config";

class NewUser extends Component {
  state = {
    name: "",
    email: "",
    role: "",
    created: "",
  };

  clickHandler = (email, name, text) => {
    const emaill = email;
    const namee = name;
    const textt = text;
    if (this.props.role !== "Admin") {
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "Rights Mismatch",
        "Only Admins can Create/Edit users. You do not have the correct rights.",
        "ok"
      );
    } else {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emaill
        )
      ) {
        if (namee !== "" && textt !== "") {
          if (emaill !== this.props.email) {
            Notiflix.Loading.Init({ svgColor: "#3bbfde" });
            Notiflix.Loading.Pulse("Adding User..");
            const user = {
              name: namee,
              email: emaill,
              role: textt,
              companyID: this.props.compId,
            };

            const email = emaill;
            var comp = this;
            const encodedemail = email.replace(/\./g, ".");

            db.collection(`company/${this.props.compId}/roles`)
              .doc(String(encodedemail))
              .get()
              .then((doc) => {
                if (!doc.exists) {
                  db.collection(`company/${comp.props.compId}/roles`)
                    .doc(String(encodedemail))
                    .set(user)
                    .then(() => {
                      http
                        .get(
                          `/sendUserAddedMail?dest=${emaill}&email=${comp.props.email}&company=${comp.props.compName}&role=${textt}&name=${namee}`
                        )
                        .then((res) => {
                          if (res.status === 200) {
                            Notiflix.Loading.Remove();
                            comp.props.onFetchUsers(
                              comp.props.token,
                              comp.props.compId
                            );
                            Notiflix.Report.Init({
                              width: "400px",
                              titleFontSize: "20px",
                              messageFontSize: "17px",
                              success: { buttonBackground: "#EE3A2C" },
                            });
                            Notiflix.Report.Success(
                              "User Successfully Created",
                              "This user has been successfully created and can start accessing this company on next refresh",
                              "ok"
                            );
                          } else {
                            Notiflix.Loading.Remove();
                            comp.props.onFetchUsers(
                              comp.props.token,
                              comp.props.compId
                            );
                            Notiflix.Report.Init({
                              width: "400px",
                              titleFontSize: "20px",
                              messageFontSize: "17px",
                              success: { buttonBackground: "#EE3A2C" },
                            });
                            Notiflix.Report.Failure(
                              "User Successfully Created, Email Not Sent",
                              "This user has been successfully created but email could not be sent.",
                              "ok"
                            );
                          }
                        })
                        .then(
                          db
                            .collection(`logs/${comp.props.compId}/logsList`)
                            .add({
                              name: firebase.auth().currentUser.displayName,
                              email: firebase.auth().currentUser.email,
                              timestamp: new Date().getTime(),
                              entity: "Users",
                              operation: "CREATED",
                              payload: user,
                            })
                        );
                    })
                    .catch(() => {
                      Notiflix.Loading.Remove();
                      Notiflix.Report.Init({
                        width: "400px",
                        titleFontSize: "20px",
                        messageFontSize: "17px",
                        success: { buttonBackground: "#EE3A2C" },
                      });
                      Notiflix.Report.Failure(
                        "Add User Failed",
                        "This user could not be added, please try again.",
                        "ok"
                      );
                    });
                } else {
                  Notiflix.Loading.Remove();
                  Notiflix.Report.Init({
                    width: "400px",
                    titleFontSize: "20px",
                    messageFontSize: "17px",
                    success: { buttonBackground: "#EE3A2C" },
                  });
                  Notiflix.Report.Failure(
                    "Add User Failed",
                    "This user is already a part of this company, to edit the current role, please first remove the user and readd with new role.",
                    "ok"
                  );
                  return;
                }
              })
              .catch(() => {
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Failure(
                  "Add User Failed",
                  "This user could not be added.Please try again.",
                  "ok"
                );
              });
          } else {
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Failure(
              "Add User Failed",
              "You cannot edit your own rights.",
              "ok"
            );
          }
        } else {
          Notiflix.Report.Init({
            width: "400px",
            titleFontSize: "20px",
            messageFontSize: "17px",
            success: { buttonBackground: "#EE3A2C" },
          });
          Notiflix.Report.Failure(
            "All Fields Required",
            "Please enter all the details",
            "ok"
          );
        }
      } else {
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure(
          "Invalid Email",
          "Please enter a valid email address",
          "ok"
        );
      }
    }
  };

  render() {
    return (
      <div
        style={{
          display: this.props.role !== "Admin" ? "none" : "normal",
        }}
      >
        <NewUserView
          clicked={this.clickHandler}
          isLoading={this.props.loading}
          error={this.props.isError}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    email: state.auth.email,
    compId: state.surveyEditer.company.id,
    role: state.surveyEditer.company.role,
    compName: state.surveyEditer.company.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUsers: (token, compId) => dispatch(fetchUsers(token, compId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewUser)
);
