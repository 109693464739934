import React from "react";
import Icon from "@material-ui/core/Icon";
import classes from "./NewSurvey.module.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import firebase from "firebase";
import Notiflix from "notiflix-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from 'react-bootstrap/Alert'
import "react-toastify/dist/ReactToastify.css";
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

const NewSurvey = props => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.CreateSurvey}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullWidth={true}
        fullScreen={true}
        className={classes.Dialog}
      >
        <DialogTitle className={classes.DialogTitle}>
          Create New Survey
        </DialogTitle>
        <h6 className={classes.secondary} style={{ marginLeft: "10px", marginBottom: "10px", padding: "15px" }}>
          Choose a template or start with a blank survey
        </h6>
        <DialogContent>
          <Alert variant="secondary" style={{ backgroundColor: "#F6F7F9" }}>
            <Alert.Heading>Blank Survey</Alert.Heading>
            <div style={{ display: "flex" }}>
              <p>
                A basic blank survey with one sample question
  </p>
              <hr />
              <div style={{ display: "flex" }}>
                <h6>Questions:
            <Badge variant="dark" style={{ marginLeft: "5px", marginTop: "16px" }}>1</Badge>
                </h6>
                <button
                  onClick={() => {
                    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                    Notiflix.Loading.Pulse('Creating Survey');
                    props.clicked();
                  }}
                  className={classes.Blank}
                  style={{ marginLeft: "15px", backgroundColor: "#28A745" }}
                >
                  <FontAwesomeIcon icon={faPencilAlt} style={{
                    fontSize: "14px",
                    color: "white",
                    marginRight: "7px"
                  }} />
                  Choose
          </button>
              </div>
            </div>

          </Alert>
          {props.templates.map((template) => (
            <Alert variant="secondary" style={{ backgroundColor: "#F6F7F9" }}>
              <Alert.Heading>{template.content.title}</Alert.Heading>
              <div style={{ display: "flex" }}>
                <p>
                  {template.content.subTitle}
                </p>
                <hr />
                <div style={{ display: "flex" }}>
                  <h6>Questions:
            <Badge variant="dark" style={{ marginLeft: "5px", marginTop: "16px" }}>{template.content.questions.length}</Badge>
                  </h6>
                  <button
                    onClick={() => {
                      Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                      Notiflix.Loading.Pulse('Creating Survey');
                      props.clicked(template._id);
                    }}
                    className={classes.Blank}
                    style={{ marginLeft: "15px", backgroundColor: "#28A745" }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} style={{
                      fontSize: "14px",
                      color: "white",
                      marginRight: "7px"
                    }} />
                    Choose
          </button>
                  <button
                    onClick={() => {
                      if (firebase.auth().currentUser.email === "evaladmin03092014@cimplyfive.com") {
                        Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                        Notiflix.Loading.Pulse('Deleting Template');
                        Notiflix.Confirm.Init({ titleColor: "#32c6b2", cancelButtonBackground: "#ea3434", });
                        Notiflix.Confirm.Show(
                          "Delete Template",
                          "Are you sure you want to delete this template? This action cannot be undone",
                          "Yes",
                          "No",
                          function () {
                            props.deleteTemplate(template._id)
                          },
                        )
                      } else {
                        alert("This option is reserved for Cimplybest Admins only")
                      }
                    }}
                    style={{ display: firebase.auth().currentUser.email === "evaladmin03092014@cimplyfive.com" ? null : "none", marginLeft: "15px", backgroundColor: "#EE3A2C" }}
                    className={classes.Blank}
                  >
                    Delete Template
          </button>
                </div>
              </div>

            </Alert>
          ))}
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className={classes.CancelButton}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      <button
        className={classes.NewSurvey}
        onClick={handleClickOpen}
        disabled={props.isLoading || props.error}
      >
        <Icon className={classes.Icon}>add_circle_outline</Icon> Create New
        Survey
      </button>
    </div>
  );
};

export default NewSurvey;
