import React, { Component } from "react";
import PropsTypes from "prop-types";

import RatingField from "./RatingField";
import newId from "../../../ulitity/idGenerator";
import GreenSwitcher from "../../UI/Switcher/GreenSwitch";
import classes from "./Switcher.module.css";
import { QuestionTypes } from "../../../ulitity/constants/Questions";

class RatingchoiceEditer extends Component {
  constructor(props) {
    super(props);
    this.inputs = {};
  }

  state = {
    required: false,
  };

  componentDidMount() {
    this.setState({ required: this.props.isRequired });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props._id !== prevProps._id) {
      this.setState({ required: this.props.isRequired });
    }
  }

  toggleRequire = () => {
    const value = {
      title: this.title_node.value,
      isRequired: !this.state.required,
    };
    this.props.updateQuestion(this.props._id, value);
    this.setState((prevState) => {
      return { required: !prevState.required };
    });
  };

  update() {
    this.props.updateQuestion(this.props._id, {
      title: this.title_node.value,
    });
  }

  render() {
    const { _id, title, options, stars } = this.props;
    return (
      <div>
        <div className={classes.Switcher}>
          <GreenSwitcher
            checked={this.state.required}
            onChange={() => this.toggleRequire()}
            label="Is Mandatory"
          />
        </div>
        <form>
          <div>
            <label>
              <b>Type your question</b>
            </label>
            <textarea
              type="text"
              spellcheck="true"
              value={title}
              ref={(node) => {
                this.title_node = node;
              }}
              onChange={() => this.update()}
            />
          </div>
          {/* other remarks implementation */}

          <div>
            <label>
              <input
                type="checkbox"
                name={"Others"}
                checked={this.props.otherOptionIncluded}
                className={classes.check}
                style={{
                  float: "left",
                  width: "4%",
                  "vertical-align": "middle",
                  height: "18px",
                }}
                onChange={(e) => {
                  this.props.updateQuestion(_id, {
                    otherOptionIncluded: e.target.checked,
                    otherOptionValue: "Remarks",
                  });
                }}
              />
              Add 'Other' field
            </label>
          </div>
          {this.props.otherOptionIncluded ? (
            <div>
              <input
                type="text"
                spellcheck="true"
                value={this.props.otherOptionValue}
                onChange={(e) => {
                  this.props.updateQuestion(_id, {
                    otherOptionValue: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}

          {/* other remarks implementation ends */}

          <div>
            <label>
              <b>Options</b>
            </label>
            {options.map((option, index) => {
              return (
                <div>
                  <RatingField
                    key={option._id}
                    content={option.content}
                    name={title}
                    value={option.value}
                    acceptZero={option.acceptZero}
                    onChange={(e) => {
                      this.props.updateQuestion(_id, {
                        stars: parseInt(e.target.value),
                      });
                    }}
                  />
                  <br />
                </div>
              );
            })}
          </div>
        </form>
      </div>
    );
  }
}

RatingchoiceEditer.PropsTypes = {
  _id: PropsTypes.string,
  title: PropsTypes.string,
  options: PropsTypes.array,
  updateQuestion: PropsTypes.func,
  stars: PropsTypes.number,
};

export default RatingchoiceEditer;
