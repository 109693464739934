import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Notiflix from "notiflix-react";
import classes from "./LogPage.module.css";
import { fetchLogs } from "../Store/actions/logs";
import { Link, withRouter, Redirect, Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import ScrollTop from '@nzambello/react-scrolltop'
import LogList from "../Containers/LogList/LogList";

class LogsPage extends Component {

    render() {

        let user = (
            <h1>Login</h1>
        );

        let redirectPath = null;


        if (this.props.isAuthenticated) {
            user = (<div>
                <ScrollTop />
                <div className={classes.Header}>
                    <h5 className={classes.HeaderText}>Activity Log</h5>
                    <div className={classes.vertical}></div>
                    <Link to={"/" + this.props.compId + "/surveys"}>
                        <button className={classes.Back}>Back</button>
                    </Link>
                </div>
                <LogList />
            </div>
            );
        }

        return <Fragment>{user}</Fragment>;
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        isLoading: state.surveys.loading,
        error: state.surveys.error,
        isError: state.surveys.error !== null,
        logo: state.surveyEditer.company.logo,
        surveyId: state.surveyEditer.survey.id,
        name: state.surveyEditer.company.name,
        status: state.surveyEditer.company.status,
        compId: state.surveyEditer.company.id,
        haveSurvId: state.surveyEditer.survey.id !== "",
        role: state.surveyEditer.company.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLogs: (compId) => dispatch(fetchLogs(compId))
    };
};


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LogsPage)
);