import React, { Component } from "react";
import classes from "./EmailGroups.module.css";
import { Icon } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { connect } from "react-redux";
import firebase from "firebase";
import Notiflix from "notiflix-react";
import { confirmAlert } from "react-confirm-alert";
import { db } from "../../firebase-config";

class EmailGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddGroupModal: false,
      emails: [],
      groupName: "",
      allEmailGroups: [],
      editingEmailGroupId: null,
    };
  }

  handleSave = () => {
    if (this.state.groupName.length < 1) {
      Notiflix.Notify.Failure("Enter group name");
    } else if (this.state.emails.length < 1) {
      Notiflix.Notify.Failure("Enter Email Ids");
    } else {
      if (this.state.editingEmailGroupId) {
        db.collection(`company/${this.props.compId}/emailGroups`)
          .doc(String(this.state.editingEmailGroupId))
          .update({
            name: this.state.groupName,
            emails: this.state.emails,
          })
          .finally(() => Notiflix.Notify.Init({ position: "right-bottom" }))
          .then(() => {
            Notiflix.Notify.Success("Email Group Updated Successfully");
          })
          .catch(() => {
            Notiflix.Notify.Failure("Failed to Update Email Group");
          });
      } else {
        db.collection(`company/${this.props.compId}/emailGroups`)
          .add({
            name: this.state.groupName,
            emails: this.state.emails,
          })
          .finally(() => Notiflix.Notify.Init({ position: "right-bottom" }))
          .then(() => {
            Notiflix.Notify.Success("Email Group Added Successfully");
          })
          .catch(() => {
            Notiflix.Notify.Failure("Failed to Add Email Group");
          });
      }
      this.setState({
        showAddGroupModal: false,
        emails: [],
        groupName: "",
        editingEmailGroupId: null,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      showAddGroupModal: false,
      emails: [],
      groupName: "",
      editingEmailGroupId: null,
    });
  };

  handleEdit = (emailGroup) => {
    this.setState({
      editingEmailGroupId: emailGroup.id,
      emails: emailGroup.emails,
      groupName: emailGroup.name,
      showAddGroupModal: true,
    });
  };

  confirmDelete = (emailGroup) => {
    db.collection(`company/${this.props.compId}/emailGroups`)
      .doc(String(emailGroup.id))
      .delete()
      .finally(() => Notiflix.Notify.Init({ position: "right-bottom" }))
      .then(() => Notiflix.Notify.Success("Email Group Deleted Successfully"))
      .catch(() => {
        Notiflix.Notify.Failure("Failed to Delete Email Group");
      });
  };

  handleDelete = (emailGroup) => {
    confirmAlert({
      title: `Delete ${emailGroup.name}`,
      message:
        "Are you sure you want to delete this group? This action cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmDelete(emailGroup),
        },
        {
          label: "No",
        },
      ],
    });
  };

  getEmailGroups = () => {
    db.collection(`company/${this.props.compId}/emailGroups`).onSnapshot(
      (snapshot) => {
        const results = [];
        if (snapshot.size > 0) {
          snapshot.forEach((doc) =>
            results.push({ ...doc.data(), id: doc.id })
          );
          this.setState({ allEmailGroups: results });
          this.props.setEmailGroups(results);
        } else {
          this.setState({ allEmailGroups: [] });
          this.props.setEmailGroups([]);
        }
      }
    );
  };
  componentDidMount() {
    this.getEmailGroups();
  }

  render() {
    return (
      <div>
        <button
          onClick={() => this.setState({ showAddGroupModal: true })}
          className={classes.AddButton}
          //   style={{ display: props.role === "Admin" ? null : "none" }}
        >
          <Icon className={classes.AddIcon}>add</Icon>
          Add Group
        </button>

        {this.state.allEmailGroups?.length > 0 && (
          <table style={{ width: "73%", marginTop: 25 }}>
            <thead>
              <tr>
                <th className={classes.Index}>Group Name</th>
                <th>Members</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.allEmailGroups.map((emailGroup) => (
                <tr>
                  <td className={classes.Index}>{emailGroup.name}</td>
                  <td>{emailGroup.emails.length}</td>
                  <td>
                    <button
                      className={classes.ActionButton}
                      style={{ marginRight: 5 }}
                      onClick={() => this.handleEdit(emailGroup)}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => this.handleDelete(emailGroup)}
                      className={classes.ActionButton}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Modal
          show={this.state.showAddGroupModal}
          onHide={() => this.setState({ showAddGroupModal: false })}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label> Group Name </label>
              <br />
              <input
                className={classes.GroupName}
                placeholder="Enter Group Name"
                value={this.state.groupName}
                onChange={(e) =>
                  this.setState({
                    groupName: e.target.value,
                  })
                }
              />
              <label style={{ marginTop: 15 }}>
                Member Email-Id (Press Space/Enter after each email)
              </label>
              <br />
              <ReactMultiEmail
                placeholder="Enter Recipients"
                style={{
                  width: "80vw",
                  border: "2px solid #34baf2",
                  borderRadius: "3px",
                  minWidth: "200px",
                  minHeight: "30px",
                  marginTop: "2px",
                  marginBottom: "15px",
                  fontSize: "20px",
                  padding: "7px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
                emails={this.state.emails}
                onChange={(_emails) => {
                  this.setState({ emails: _emails });
                }}
                validateEmail={(email) => {
                  return isEmail(email); // return boolean
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <p
                        style={{
                          fontSize: "17px",
                          marginTop: "13px",
                          color: "#000",
                        }}
                      >
                        {email}
                      </p>
                      <span
                        data-tag-handle
                        onClick={() => removeEmail(index)}
                        style={{ fontSize: "20px" }}
                      >
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.handleCancel}
              className={classes.CancelButton}
              //   style={{ display: props.role === "Admin" ? null : "none" }}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSave}
              className={classes.SaveButton}
              //   style={{ display: props.role === "Admin" ? null : "none" }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    compId: state.surveyEditer.company.id,
    compname: state.surveyEditer.company.name,
    survname: state.surveyEditer.survey.title,
    status: state.surveyEditer.company.status,
    openTill: state.surveyEditer.survey.openTill,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setEmailGroups: (emailGroups) =>
      dispatch({ type: "SET_EMAIL_GROUPS", emailGroups }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailGroups);
