import * as actionTypes from "./actionsTypes";
import firebase from "firebase";
import { db } from "../../firebase-config";

export const fetchSurveysSuccess = (surveys, groupedSurveys) => {
  return {
    type: actionTypes.FETCH_SURVEYS_SUCCESS,
    surveys: surveys,
    groupedSurveys,
  };
};

export const fetchSurveysFail = (err) => {
  return {
    type: actionTypes.FETCH_SURVEYS_FAIL,
    error: err,
  };
};

export const fetchSurveysStart = () => {
  return {
    type: actionTypes.FETCH_SURVEYS_START,
  };
};

// Grouping the list surveys according to financial year
const groupSurveysByFY = (surveys) => {
  if (!surveys?.length) return [];

  const results = [];
  const firstSurveyDate = new Date(surveys[0].content.creatorDate);
  const lastSurveyDate = new Date(
    surveys[surveys.length - 1].content.creatorDate
  );
  const endYear =
    firstSurveyDate.getMonth() > 2
      ? firstSurveyDate.getFullYear() + 1
      : firstSurveyDate.getFullYear();
  const startYear =
    lastSurveyDate.getMonth() <= 2
      ? lastSurveyDate.getFullYear() - 1
      : lastSurveyDate.getFullYear();

  for (let i = endYear; i >= startYear; i--) {
    results.push({
      title: `FY ${i} - ${i + 1}`,
      data: [],
    });
  }

  for (let survey of surveys) {
    const surveyDate = new Date(survey.content.creatorDate);
    const yearVal =
      surveyDate.getMonth() <= 2
        ? `FY ${surveyDate.getFullYear() - 1} - ${surveyDate.getFullYear()}`
        : `FY ${surveyDate.getFullYear()} - ${surveyDate.getFullYear() + 1}`;
    const surveyIdx = results.findIndex((res) => res.title === yearVal);
    if (surveyIdx >= 0) results[surveyIdx].data.push(survey);
  }

  for (let i = 0; i < results.length; i++) {
    if (results[i].data.length === 0) results.splice(i, 1);
  }

  return results;
};

export const fetchSurveys = (token, userId) => {
  return (dispatch) => {
    dispatch(fetchSurveysStart());
    const companyid = window.location.href.split("/")[3];
    db.collection(`surveys/${companyid}/surveysList`).onSnapshot(
      (docs) => {
        if (window.location.href.split("/")[3] === companyid) {
          const fetchSurveys = [];
          docs.forEach((doc) =>
            fetchSurveys.push({ ...doc.data(), id: doc.id })
          );
          fetchSurveys.reverse();
          const groupedSurveys = groupSurveysByFY(fetchSurveys);
          dispatch(fetchSurveysSuccess(fetchSurveys, groupedSurveys));
        }
      },
      (err) => console.log(err)
    );
  };
};
