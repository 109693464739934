import firebase from "firebase";
import { db } from "../../firebase-config";
import * as actionTypes from "./actionsTypes";

export const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users,
  };
};

export const fetchUsersFail = (err) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error: err,
  };
};

export const fetchUsersStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START,
  };
};

export const fetchUsers = (token, compId) => {
  return (dispatch) => {
    dispatch(fetchUsersStart());

    db.collection(`company/${compId}/roles`)
      .get()
      .then((docs) => {
        const fetchUsers = [];
        docs.forEach((doc) => fetchUsers.push({ ...doc.data(), id: doc.id }));
        dispatch(fetchUsersSuccess(fetchUsers));
      })
      .then(
        db.collection(`logs/${compId}/logsList`).add({
          name: firebase.auth().currentUser.displayName,
          email: firebase.auth().currentUser.email,
          timestamp: new Date().getTime(),
          entity: "Users",
          operation: "READ",
        })
      )
      .catch((err) => {
        dispatch(fetchUsersFail(err));
      });
  };
};
