import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import classes from "./Login.module.css";
import Input from "../../Components/UI/Input/Input";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { auth } from "../../Store/actions/authentication";
import { checkValidity } from "../../ulitity/ulitity";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faUsers, faTachometerAlt, faFile, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { Alert, AlertTitle } from '@material-ui/lab';
import PasswordStrengthBar from 'react-password-strength-bar';

class Login extends Component {
  state = {
    controls: {
      name: {
        elementLabel: "Full Name",
        elementType: "input",
        elementConfig: {
          type: "input",
          placeholder: "Full Name"
        },
        value: "",
        validation: {
          required: true,
          minLength: 3
        },
        validity: false,
        touched: false
      },
      email: {
        elementLabel: "Email Adress*",
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "E-mail address*"
        },
        value: "",
        validation: {
          required: true,
          isEmail: true
        },
        validity: false,
        touched: false
      },
      password: {
        elementLabel: "Password*",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password*"
        },
        value: "",
        validation: {
          required: true,
          minLength: 6
        },
        validity: false,
        touched: false
      },
    },
    bar: {
      words: ['Weak', 'Weak', 'Okay', 'Good', 'Strong']
    },
    isSignup: true,
    formIsValid: false
  };

  // componentDidMount() {
  //     if (!this.props.buildingBurger && this.props.authRedirectPath !== '/') {
  //         this.props.onSetAuthRedirectPath();
  //     }
  // }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        validity: checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    };
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].validity && formIsValid;
    }
    this.setState({ controls: updatedControls, formIsValid: formIsValid });
  };

  submitHandler = event => {
    event.preventDefault();
    this.props.onAuth(
      this.state.controls.email.value,
      this.state.controls.password.value,
      this.state.isSignup,
      this.state.controls.name.value
    );
  };

  switchAuthModeHandler = () => {
    this.setState(prevState => {
      return { isSignup: !prevState.isSignup };
    });
  };

  render() {
    const formElementArray = [];
    for (let key in this.state.controls) {
      formElementArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (
        <Alert variant="filled" severity="error">
          {this.props.error.message}
        </Alert>
      );
    }

    let form = (
      <form onSubmit={this.submitHandler}>
        {formElementArray.map(formElement => (
          <Input
            key={formElement.id}
            //label={formElement.config.elementLabel}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.validity}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={event => this.inputChangedHandler(event, formElement.id)}
          />
        ))}
        <PasswordStrengthBar password={this.state.controls.password.value} scoreWords={this.state.bar.words} minLength={6} />
        {errorMessage}
        <button
          disabled={!this.state.formIsValid}
          className={classes.SubmitButton}
        >
          SUBMIT
        </button>
      </form>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }



    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <div className={classes.pageWrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.formWrapper}>
            <h2 className={classes.Welcome}>Get Started.</h2>
            {authRedirect}
            {form}

            <Link to="/login">
              <button className={classes.Register}>Back To Login</button>
            </Link>

            <p className={classes.Terms}>
              By continuing you agree to Cimplyfive's
              <span className={classes.terms_link}> <a href="https://www.cimplyfive.com/cimplyfives-terms-and-conditions/" target="_blank">terms and conditions</a></span>
            </p>
          </div>
        </div>
        <div className={classes.registerWrapper}>
          <div className={classes.headerside}>
            <h2 className={classes.sidetext}> What We Offer</h2>
          </div>
          <div className={classes.features}>
            <div className={classes.featurebody}>
              <div className={classes.featureitem}>
                <FontAwesomeIcon icon={faCheck} style={{
                  fontSize: "30px",
                  color: "#34baf2"
                }} />
                <h5 className={classes.featuredesc}>Ease Of Use</h5>

              </div>
              <div className={classes.featureitem}>
                <FontAwesomeIcon icon={faUsers} style={{
                  fontSize: "30px",
                  color: "#34baf2"
                }} />
                <h5 className={classes.featuredesc}>Assign Multiple Roles</h5>

              </div>
              <div className={classes.featureitem}>
                <FontAwesomeIcon icon={faTachometerAlt} style={{
                  fontSize: "30px",
                  color: "#34baf2"
                }} />
                <h5 className={classes.featuredesc}>Fully Configurable</h5>

              </div>
              <div className={classes.featureitem}>
                <FontAwesomeIcon icon={faFile} style={{
                  fontSize: "30px",
                  color: "#34baf2",
                  marginLeft: "6px"
                }} />
                <h5 className={classes.featuredesc}>Preconfigured Templates</h5>

              </div>
              <div className={classes.featureitem}>
                <FontAwesomeIcon icon={faChartPie} style={{
                  fontSize: "30px",
                  color: "#34baf2",
                  marginLeft: "10px"
                }} />
                <h5 className={classes.featuredesc}>Customizable Report Generated</h5>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password, isSignup, name) =>
      dispatch(auth(email, password, isSignup, name))
    // onSetAuthRedirectPath: () => dispatch(setAuthRedirectPath('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
