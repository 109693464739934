import * as actionTypes from "./actionsTypes";
import { db } from "../../firebase-config";

export const fetchMailAuditsSuccess = (mailAudits) => {
  return {
    type: actionTypes.FETCH_MAILAUDIT_SUCCESS,
    mailAudit: mailAudits,
  };
};

export const fetchMailAuditsFail = (err) => {
  return {
    type: actionTypes.FETCH_MAILAUDIT_FAIL,
    error: err,
  };
};

export const fetchMailAuditsStart = () => {
  return {
    type: actionTypes.FETCH_MAILAUDIT_START,
  };
};

export const fetchMailAudits = (surveyId, currentAuditType) => {
  if (currentAuditType && currentAuditType === "REPORT") {
    return (dispatch) => {
      dispatch(fetchMailAuditsStart());

      let switchOverDateStrValue = localStorage.getItem(
        "switchOverDateStrValue"
      );
      let reportSyncStartStrValue = localStorage.getItem(
        "reportSyncStartStrValue"
      );
      let reportSyncEndStrValue = localStorage.getItem("reportSyncEndStrValue");

      ///////// START - If mail audit switch over data is not there in local storage then fetch it from DB//////////////

      if (
        switchOverDateStrValue === null ||
        reportSyncStartStrValue === null ||
        reportSyncEndStrValue === null
      ) {
        db.collection("config")
          .doc("reportConfig")
          .onSnapshot((doc) => {
            if (!doc.exists) return;

            let configResp = { ...doc.data() };
            switchOverDateStrValue =
              configResp.REPORT_TO_MAIL_AUDIT_COLLECTION_SWITCH_OVER_DATE;
            reportSyncStartStrValue = configResp.REPORT_SYNC_START;
            reportSyncEndStrValue = configResp.REPORT_SYNC_END;

            localStorage.setItem(
              "switchOverDateStrValue",
              switchOverDateStrValue
            );
            localStorage.setItem(
              "reportSyncStartStrValue",
              reportSyncStartStrValue
            );
            localStorage.setItem(
              "reportSyncEndStrValue",
              reportSyncEndStrValue
            );
          });
      }
      ///////// END - If mail audit switch over data is not there in local storage then fetch it from DB//////////////

      let switchOverDate = new Date(switchOverDateStrValue);
      let currentDate = Date.now();

      if (currentDate >= switchOverDate.getTime()) {
        try {
          db.collection(`mailAudit/${surveyId}/mailAuditList`)
            .where("type", "==", "REPORT")
            .onSnapshot((docs) => {
              const receivedMailAudits = [];
              docs.forEach((doc) =>
                receivedMailAudits.push({ ...doc.data(), id: doc.id })
              );
              receivedMailAudits.reverse();
              dispatch(fetchMailAuditsSuccess(receivedMailAudits));
            });
        } catch (error) {
          console.log(error);
        }
      }
    };
  } else {
    return (dispatch) => {
      dispatch(fetchMailAuditsStart());

      try {
        db.collection(`mailAudit/${surveyId}/mailAuditList`)
          .where("type", "==", "SURVEY")
          .onSnapshot((docs) => {
            const fetchMailAudits = [];
            docs.forEach((doc) =>
              fetchMailAudits.push({ ...doc.data(), id: doc.id })
            );
            fetchMailAudits.reverse();

            dispatch(fetchMailAuditsSuccess(fetchMailAudits));
          });
      } catch (error) {
        console.log(error);
      }
    };
  }
};
