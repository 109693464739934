import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Link, Redirect } from "react-router-dom";
import FileInputComponent from "react-file-input-previews-base64";
import classes from "./EditCompanyPage.module.css";
import * as actionTypes from "../Store/actions/actionsTypes";
import Notiflix from "notiflix-react";
import firebase from "firebase";
import { db, storage } from "../firebase-config";
import { confirmAlert } from "react-confirm-alert";

class EditCompanyPage extends Component {
  state = {
    name: this.props.name,
    cin: this.props.cin,
    address: this.props.address,
    logo: this.props.logo,
    logoDetails: null,
  };

  componentDidMount() {
    localStorage.removeItem("logoupdatebase64");
    firebase.analytics().logEvent("Company Edir page is rendered");
  }

  saveDataToDB(logoURL) {
    const query = "?auth=" + this.props.token;
    const UpdateDetails = {
      name: document.getElementById("name").value || this.props.name,
      cin: document.getElementById("cin").value || this.props.cin,
      address: document.getElementById("address").value || this.props.address,
      logo: logoURL || this.props.logo,
    };
    db.collection("company")
      .doc(String(this.props.compId))
      .update(UpdateDetails)
      .then(() => {
        this.props.onGetCompId(
          UpdateDetails.name,
          UpdateDetails.cin,
          UpdateDetails.address,
          UpdateDetails.logo
        );
        Notiflix.Loading.Remove();
        Notiflix.Notify.Init({
          position: "right-bottom",
          fontSize: "17px",
        });
        Notiflix.Notify.Success("Details Updated Successfully");

        db.collection(`logs/${this.props.compId}/logsList`).add({
          name: firebase.auth().currentUser.displayName,
          email: firebase.auth().currentUser.email,
          timestamp: new Date().getTime(),
          entity: "Company",
          operation: "UPDATED",
          payload: UpdateDetails,
        });
      })
      .catch((err) => {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Init({
          position: "right-bottom",
          fontSize: "17px",
        });
        Notiflix.Notify.Failure(err);
      });
  }

  handleSaveDetails = () => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Editing Details");

    if (!localStorage.getItem("logoupdatebase64")) return this.saveDataToDB();

    // Create the file metadata
    const metadata = {
      contentType: this.state.logoDetails.type,
    };
    const logoType = this.state.logoDetails.type.split("/")[1];

    // Upload file and metadata to the object
    const storageRef = storage.ref();
    const uploadTask = storageRef
      .child(
        `companyLogo/${this.props.compId}/${this.props.compId}.${logoType}`
      )
      .put(this.state.logoDetails.file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {},
      (error) => {
        Notiflix.Loading.Remove();
        Notiflix.Notify.Init({
          position: "right-bottom",
          fontSize: "17px",
        });
        Notiflix.Notify.Failure("Something went wrong...");
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          this.saveDataToDB(downloadURL);
        });
      }
    );
  };

  render() {
    return (
      <div>
        <div className={classes.Header}>
          <h5 className={classes.HeaderText}>Edit Company Details</h5>
          <div className={classes.vertical}></div>
          <Link to={"/" + this.props.compId + "/surveys"}>
            <button className={classes.Back}>Back</button>
          </Link>
        </div>
        <div className={classes.EditWrapper}>
          <div className={classes.InnerWrapper}>
            <div className={classes.Logo}>
              {this.state.logo != "" ? (
                <img
                  src={this.state.logo}
                  alt=""
                  className={classes.Clogo}
                ></img>
              ) : (
                ""
              )}
              <FileInputComponent
                labelText="Update Logo:"
                labelStyle={{ fontSize: 17, marginRight: "15px" }}
                className={classes.uploadLogo}
                multiple={false}
                imagePriview={true}
                callbackFunction={(file_arr) => {
                  if (file_arr.size > 1000) {
                    alert(
                      "File size exceeds 1mb limit,this image will not be uploaded"
                    );
                  } else {
                    localStorage.removeItem("logoupdatebase64");
                    localStorage.setItem("logoupdatebase64", file_arr.base64);
                    this.setState({ logo: "", logoDetails: file_arr });
                  }
                }}
                accept="image/*"
              />
            </div>
            <div className={classes.CompanyDetails}>
              <label for="name">Company Name:</label>
              <div>
                <input
                  type="text"
                  value={this.state.name}
                  className={classes.Input}
                  id="name"
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                ></input>
              </div>
              <label for="cin">CIN(21 Digits):</label>
              <div>
                <input
                  type="text"
                  value={this.state.cin}
                  className={classes.Input}
                  id="cin"
                  onChange={(e) =>
                    this.setState({
                      cin: e.target.value,
                    })
                  }
                ></input>
              </div>
              <label for="address">Registered Address:</label>
              <div>
                <textarea
                  type="text"
                  value={this.state.address}
                  className={classes.Input}
                  id="address"
                  onChange={(e) =>
                    this.setState({
                      address: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className={classes.Save}>
                <Link to={"/" + this.props.compId + "/surveys"}>
                  <button className={classes.cancelbtn}>Cancel</button>
                </Link>
                <button
                  className={classes.SaveBtn}
                  onClick={this.handleSaveDetails}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCompId: (name, cin, address, logo) =>
      dispatch({
        type: actionTypes.GET_COMPANY_ID,
        name: name,
        cin: cin,
        address: address,
        logo: logo,
      }),
  };
};

const mapStateToProps = (state) => {
  return {
    logo: state.surveyEditer.company.logo,
    name: state.surveyEditer.company.name,
    status: state.surveyEditer.company.status,
    address: state.surveyEditer.company.address,
    cin: state.surveyEditer.company.cin,
    compId: state.surveyEditer.company.id,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyPage);
