import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/actions/actionsTypes";
import UserItem from "../../Components/UserList/UserItem";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { fetchUsers } from "../../Store/actions/users";
import Notiflix from "notiflix-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import classes from "./UserList.module.css";
import firebase from "firebase";
import { stat } from "fs";
import http from "../../lib/http-service";
import { db } from "../../firebase-config";

class UserList extends Component {
  componentDidMount() {
    this.props.onFetchUsers(this.props.token, this.props.compId);
  }

  render() {
    let userList = <Spinner />;
    let loggedInUserEmail = firebase.auth().currentUser.email;
    if (!this.props.loading) {
      let isDeleteDisabled = false;
      let adminUsersLength = 1;
      // const filteredAdmins = this.props.users.filter(
      //   (user) => user.role == "Admin"
      // );
      adminUsersLength = this.props.users.length;
      // console.log(filteredAdmins);
      // if (filteredAdmins.length > 1) {
      //   isDeleteDisabled = false;
      // }
      userList = (
        <div className={classes.companies}>
          <UserItem
            users={this.props.users}
            delete={(user) => {
              console.log(
                `${loggedInUserEmail} === ${user.email}`,
                loggedInUserEmail === user.email
              );
              // if (user.role == "Admin") {
              if (loggedInUserEmail === user.email) {
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Failure(
                  "Self Deletion Not Allowed",
                  "You can not delete yourself.",
                  "ok"
                );
              } else if (adminUsersLength <= 1) {
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Failure(
                  "Failed To Delete User",
                  "There should be atleast one admin.",
                  "ok"
                );
              } else {
                confirmAlert({
                  title: "Delete User",
                  message:
                    "Are you sure you want to delete this user? This action cannot be undone.",
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                        Notiflix.Loading.Pulse("Removing User..");
                        firebase
                          .auth()
                          .currentUser.getIdToken()
                          .then((idToken) => {
                            db.collection(`company/${this.props.compId}/roles`)
                              .doc(String(user.email.replace(/\./g, ".")))
                              .delete()
                              .then(() => {
                                http
                                  .get(
                                    `/sendUserRemovedMail?dest=${user.email}&email=${this.props.email}&company=${this.props.compName}&role=${user.role}&name=${user.name}`
                                  )
                                  .then((res) => {
                                    if (res.status == 200) {
                                      this.props.onFetchUsers(
                                        this.props.token,
                                        this.props.compId
                                      );
                                      Notiflix.Loading.Remove();
                                      Notiflix.Report.Init({
                                        width: "400px",
                                        titleFontSize: "20px",
                                        messageFontSize: "17px",
                                        success: {
                                          buttonBackground: "#EE3A2C",
                                        },
                                      });
                                      Notiflix.Report.Success(
                                        "User Successfully Deleted",
                                        "This user has been successfully deleted.",
                                        "ok"
                                      );
                                    } else {
                                      Notiflix.Report.Init({
                                        width: "400px",
                                        titleFontSize: "20px",
                                        messageFontSize: "17px",
                                        success: {
                                          buttonBackground: "#EE3A2C",
                                        },
                                      });
                                      Notiflix.Report.Failure(
                                        "Deleted User, Email Not Sent",
                                        "This user has been deleted but email could not be sent.",
                                        "ok"
                                      );
                                    }
                                  });
                              })
                              .catch((err) => {
                                console.log(`Error in deleting ${err}`);
                              });
                          })
                          .then(
                            db
                              .collection(`logs/${this.props.compId}/logsList`)
                              .add({
                                name: firebase.auth().currentUser.displayName,
                                email: firebase.auth().currentUser.email,
                                timestamp: new Date().getTime(),
                                entity: "Users",
                                operation: "DELETED",
                                payload: user,
                              })
                          );
                      },
                    },
                    {
                      label: "No",
                    },
                  ],
                });
              }
            }}
          />
        </div>
      );
    }
    return <div>{userList}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    token: state.auth.token,
    userId: state.auth.userId,
    role: state.surveyEditer.company.role,
    compId: state.surveyEditer.company.id,
    email: state.auth.email,
    compName: state.surveyEditer.company.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUsers: (token, compId) => dispatch(fetchUsers(token, compId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
