import React, { Component } from "react";
import ReportContainer from "./ReportContainer";
class Wrapper extends Component {
  render() {
    return (
      <React.Fragment>
        <ReportContainer />
      </React.Fragment>
    );
  }
}

export default Wrapper;
