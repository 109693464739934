import React, { useEffect, useRef, useState } from "react";
import Notiflix from "notiflix-react";
import classes from "./QuestionListPanel.module.css";
import Icon from "@material-ui/core/Icon";
import { activeQuestion } from "../../Store/actions/surveyEditer";
import Button from "@material-ui/core/Button";
import QuestionsDropdown from "./QuestionsDropdown";
import { useDispatch } from "react-redux";
import { CHANGE_SURVEY_STATE } from "../../Store/actions/actionsTypes";
const textToIcon = (text) => {
  switch (text) {
    case "Single Line Answer":
      return "title";
    case "Multiple Line Answer":
      return "notes";
    case "Multiple Choice":
      return "radio_button_checked";
    case "Checkboxes":
      return "check_box";
    case "Dropdown":
      return "toc";
    case "Rating":
      return "star-circle";
    default:
      return text;
  }
};

const QuestionListPanel = (props) => {
  let {
    questions,
    dispatch,
    current_question_id,
    updated_current_question_id,
    setOpen,
    totalQuestions = 0,
  } = props;
  const changeSurveyState = useDispatch();
  const [number, setNumber] = useState(totalQuestions + 1);
  const onAddButtonClick = (question) => {
    // var scrollingElement = document.scrollingElement || document.body;
    // scrollingElement.scrollTop = scrollingElement.scrollHeight;
    changeSurveyState({ type: CHANGE_SURVEY_STATE, payload: false });
    dispatch(question.action(number));
    Notiflix.Notify.Init({ position: "right-bottom" });
    Notiflix.Notify.Success("Question Added Successfully");
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      dispatch(activeQuestion(updated_current_question_id));
    }
  }, [questions, dispatch, current_question_id, setOpen, totalQuestions]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#F0F0F0",
          padding: 10,
          marginTop: 15,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon className={classes.Icon}>{"question_answer"}</Icon>
        <b>Choose a question type:</b>
      </div>
      <QuestionsDropdown
        totalQuestions={totalQuestions + 1}
        number={number}
        setNumber={setNumber}
      />
      <div className={classes.QuestionListPanel}>
        {questions.map((question) => {
          return (
            <div>
              <button
                key={question.text}
                onClick={() => {
                  onAddButtonClick(question);
                }}
                className={classes.NewQuestion}
              >
                <Icon className={classes.Icon}>
                  {textToIcon(question.text)}
                </Icon>
                {question.text}
              </button>
            </div>
          );
        })}
      </div>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => setOpen(false)}
      >
        Close & Continue
      </Button>
    </>
  );
};

export default QuestionListPanel;
