import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./CompanyItem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

class CompanyItem extends Component {
    state = {
        activeState: false
    };

    componentDidMount() {
        this.setState({ activeState: this.props.status });
    }

    render() {
        return (
            <div className={classes.item} >
                {/* <div
                    className={classes.CompanyItem}
                    style={{
                        backgroundColor: this.state.activeState
                            ? "#e7f7fd"
                            : "rgba(204, 204, 204, 0.15)",
                        border: this.state.activeState
                            ? "2px solid #34BAF2"
                            : "2px solid #ccc"
                    }}
                > */}
                <Paper elevation={10} style={{ backgroundColor: this.props.status === "Inactive" ? "#F6F7F9" : null }}>
                    <div className={classes.CompanyHeader}>
                        <img src={this.props.logo} alt="company logo" className={classes.logo}></img>
                        <div className={classes.statusMemb}>
                                <Tooltip title={this.props.name} arrow placement="top-start">
                                    <div>
                                        <h3 className={classes.name}>{this.props.name}</h3>
                                    </div>
                                </Tooltip>
                            <div className={classes.cin}>
                                <h5 className={classes.cintitle}>CIN: </h5>
                                <h5 className={classes.cinno}>{this.props.cin}</h5>
                            </div>
                            <div className={classes.activetill}>
                                <h5 className={classes.cintitle}>Active Till:</h5>
                                <h5 className={classes.cinno}>{this.props.activetill}</h5>
                                <Chip
                                    avatar={<Avatar style={{ backgroundColor: this.props.status === "Active" ? "#0F5293" : "#d41e11", color: "white", fontSize: "14px" }}><span style={{ color: "white", display: this.props.status === "Inactive" ? "none" : null }}>&#10004;</span><span style={{ color: "white", display: this.props.status === "Active" ? "none" : null }}>&#10006;</span></Avatar>}
                                    label={this.props.status}
                                    style={{
                                        backgroundColor: this.props.status === "Active" ? "#1976D3" : "#EE3A2C"
                                        , color: "white",
                                        fontSize: "15px",
                                        marginLeft: "15px"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    {/* <div className={classes.cin}>
                        <h5 className={classes.cintitle}>CIN: </h5>
                        <h5 className={classes.cinno}>{this.props.cin}</h5>
                    </div> */}
                    {/* <div className={classes.activetill}>
                        <h5 className={classes.cintitle}>Active Till:</h5>
                        <h5 className={classes.cinno}>{this.props.activetill}</h5>
                    </div> */}
                    <div className={classes.OpenCompanyBtn}>
                        <button onClick={() => this.props.open()} className={classes.ViewButton} style={{ backgroundColor: this.props.status === "Inactive" ? "#CCCCCC" : null, color: this.props.status === "Inactive" ? "black" : null }}>View
            </button>
                    </div>
                </Paper>
            </div>
            // </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.auth.token
    };
};

export default connect(mapStateToProps)(CompanyItem);