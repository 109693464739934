import React from "react";
import { Link } from "react-router-dom";
import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import firebase from "firebase"
import cairrlogo from "./cairrlogo.png"
import Dropdown from 'react-bootstrap/Dropdown';
import { onLoginClickFromUserSurvey } from "../../../ulitity/ulitity";


const navigationItems = props => (
  <ul className={classes.NavigationItems}>
    {!props.isAuthenticated ? (
      <NavigationItem onLoginClick={()=>onLoginClickFromUserSurvey()} link="/login">
        <strong className={classes.LoginBt}>Login</strong>
      </NavigationItem>
    ) : (
        <div className={classes.navmain}>
          <Tooltip title="Statutory Requirements">
            <div>
              <a href="https://ca2013.com/134-financial-statement-boards-report-etc/" target="_blank">
                <img src={cairrlogo} className={classes.cairrlog} />
              </a>
            </div>
          </Tooltip>
          <div className={classes.navbar}>
            <div className={classes.dropdown}>
              <Dropdown style={{ marginRight: "20px" }}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <FontAwesomeIcon icon={faUserAlt} style={{
                    fontSize: "18px",
                    color: "white"
                  }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  <Dropdown.Item>{localStorage.getItem("email")}</Dropdown.Item>
                  <Dropdown.Item><Link to="/logout">Logout</Link></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Link to={props.email === "evaladmin03092014@cimplyfive.com" ? "/admincontrolspanel" : "/"}>
            <button className={classes.adminbtn} style={{ display: props.email === "evaladmin03092014@cimplyfive.com" ? null : "none" }}>
              Admin Panel
            </button>
          </Link>
        </div>
      )}

  </ul>
);

export default navigationItems;
