import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import group from "./group.svg";
import data from "./data.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter, Redirect } from "react-router-dom";
import * as actionTypes from "../Store/actions/actionsTypes";
import classes from "./HomePage.module.css";
import MenuItem from '@material-ui/core/MenuItem';
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";
import NewCompany from "../Containers/NewCompany/NewCompany";
import Select from '@material-ui/core/Select';
import CompanyList from "../Containers/CompanyList/CompanyList";
import { fetchCompaniesInactive } from "../Store/actions/companies";
import { fetchCompanies } from "../Store/actions/companies";
import { fetchCompaniesActive } from "../Store/actions/companies"
import Tooltip from '@material-ui/core/Tooltip';
import ScrollTop from '@nzambello/react-scrolltop'
import Card from 'react-bootstrap/Card'
import CardContent from '@material-ui/core/CardContent';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from 'firebase';

class HomePage extends Component {
  componentDidMount() {
    this.props.onResetSurveyId();
    this.props.onResetCompanyId();
    firebase.analytics().logEvent('Homepage is rendered');
  }

  render() {
    let home = (
      <div>
        <div className={classes.HomePageWelcome}>
          <div className={classes.GetStarted}>
            <div>
              <h1 className={classes.headtext} style={{ color: "white" }}>Board Evaluation Made Easy.</h1>
              <h3 className={classes.subtext}>Get started with your first survey in minutes</h3>
              <Link to="/register">
                <button className={classes.RegisterButton}><span>Get Started </span></button>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.features}>
          <h4 className={classes.featmaintxt}>Why Us?</h4>
          <Card
            className={classes.card}
            style={{ border: "none" }}
          >

            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#34baf2", padding: "30px", border: "none" }}>
                    <FontAwesomeIcon icon={faCheck} style={{
                      fontSize: "30px",
                      color: "white"
                    }} />
                  </div>
                  <div className={classes.cardcont}>
                    <h6 className={classes.cardtitle}>Ease of Use</h6>
                    <p>Using CimplyBest is quick and easy, </p>
                  </div>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className={classes.card}
            style={{ border: "none" }}
          >

            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#EE382B", padding: "30px", border: "none" }}>
                    <FontAwesomeIcon icon={faUsers} style={{
                      fontSize: "30px",
                      color: "white"
                    }} />
                  </div>
                  <div className={classes.cardcont}>
                    <h6 className={classes.cardtitle}>Multiple Roles</h6>
                    <p>Assign different roles such as Admin, Auditor, Editor and Director</p>
                  </div>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className={classes.card}
            style={{ border: "none" }}
          >

            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#34baf2", padding: "30px", border: "none" }}>
                    <FontAwesomeIcon icon={faTachometerAlt} style={{
                      fontSize: "30px",
                      color: "white"
                    }} />
                  </div>
                  <div className={classes.cardcont}>
                    <h6 className={classes.cardtitle}>Fully Configurable</h6>
                    <p>Create unique links for every respondent, set survey end date and switch on/off responses at any time</p>
                  </div>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className={classes.card}
            style={{ border: "none" }}
          >

            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#EE382B", padding: "35px", border: "none" }}>
                    <FontAwesomeIcon icon={faFileAlt} style={{
                      fontSize: "30px",
                      color: "white"
                    }} />
                  </div>
                  <div className={classes.cardcont}>
                    <h6 className={classes.cardtitle}>Preconfigured Surveys</h6>
                    <p>Choose from a list of predefined templates or start from scratch!</p>
                  </div>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className={classes.card}
            style={{ border: "none" }}
          >

            <Card.Body style={{ padding: 0 }}>
              <Card.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#34baf2", padding: "30px", border: "none" }}>
                    <FontAwesomeIcon icon={faChartBar} style={{
                      fontSize: "30px",
                      color: "white"
                    }} />
                  </div>
                  <div className={classes.cardcont}>
                    <h6 className={classes.cardtitle}>Customizable Report Generator</h6>
                    <p>Visualize the received responses as pie charts for deep analysis</p>
                  </div>
                </div>

              </Card.Text>
            </Card.Body>
          </Card>



        </div>
        <div className={classes.GetStartedd}>
          <div>
            <h1 className={classes.headtext} style={{ color: "white" }}>Want to see CimplyBest in action?</h1>
            <a href="https://calendly.com/cimplyfive/60min?month=2020-08" target="_blank"><button className={classes.RegisterButton} style={{ width: "fit-content", marginTop: "25px" }}> <span>Request a free demo </span></button></a>
          </div>
        </div>
      </div>
    );



    if (this.props.isAuthenticated) {
      const handleChange = (event) => {
        if (event.target.value === "All") {
          this.props.onFetchCompanies();
        }
        if (event.target.value === "Active") {
          this.props.onFetchCompaniesActive();
        }
        if (event.target.value === "Inactive") {
          this.props.onFetchCompaniesInactive();
        }

      };
      let redirectPath = null;
      if (this.props.haveCompId !== '') {
        redirectPath = (
          <Redirect to={"/" + this.props.compId + "/surveys"} />
        );
      }
      home = (
        <div className={classes.HomePage}>
          {redirectPath}
          <div className={classes.TopGroup}>
            <NewCompany
              loading={this.props.isLoading}
              isError={this.props.isError}
            />
            <div>
              <Tooltip title="Sort Companies By" style={{
                fontSize: "240px"
              }}>
                <FilterListIcon style={{
                  fontSize: "45px",
                  marginRight: "7px",
                  color: "white",
                  backgroundColor: "#34baf2",
                  borderRadius: "5px",
                  padding: "7px"
                }} />
              </Tooltip>
              <Select
                labelId="demo-simple-select-label"
                defaultValue="All"
                id="demo-simple-select"
                onChange={handleChange}
                style={{
                  padding: "5px",
                  border: "2px solid #34baf2",
                  borderRadius: "5px",
                  height: "fit-content",
                  width: "150px"
                }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                <MenuItem value={"Active"}>Active</MenuItem>
              </Select>
            </div>
          </div>
          <Tooltip title="Scroll To Top">
            <ScrollTop />
          </Tooltip>
          <div style={{ marginBottom: "60px" }}>
            <CompanyList />
          </div>
          {this.props.error ? (
            <p>No internet connection or Internal Server error</p>
          ) : null}
        </div>
      );
    }

    return <Fragment>{home}</Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    isLoading: state.surveys.loading,
    error: state.surveys.error,
    isError: state.surveys.error !== null,
    compId: state.surveyEditer.company.id,
    surveyId: state.surveyEditer.survey.id,
    haveCompId: state.surveyEditer.company.id,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetSurveyId: () => dispatch({ type: actionTypes.RESET_SURVEY_ID }),
    onResetCompanyId: () => dispatch({ type: actionTypes.RESET_COMPANY_ID }),
    onFetchCompaniesInactive: () => dispatch(fetchCompaniesInactive()),
    onFetchCompaniesActive: () => dispatch(fetchCompaniesActive()),
    onFetchCompanies: () => dispatch(fetchCompanies())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
