import { connect } from 'react-redux';

import OnlyTableReport from '../../Components/Report/OnlyTableReport';
import { resultsToReport } from '../../Store/actions/report';

const mapStateToProps = state => {
    return {
        ...resultsToReport(state.data)
    }
}

export default connect(mapStateToProps)(OnlyTableReport);