import firebase from "firebase";
import React, { Component } from "react";
import classes from "./ShareReportPage.module.css";
import { connect } from "react-redux";
import * as actionTypes from "../Store/actions/actionsTypes";
import { withRouter } from "react-router-dom";
import Notiflix from "notiflix-react";
import "react-multi-email/style.css";
import GreenSwitcher from "../Components/UI/Switcher/GreenSwitch";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import Popover from "react-bootstrap/Popover";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import newId from "../ulitity/idGenerator";
import * as async from "async";
import EmailGroups from "../Containers/EmailGroups/EmailGroups";
import _ from "lodash";
import { Checkbox, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import InviteeList from "../Containers/InviteeList/InviteeList";
import http from "../lib/http-service";
import { db } from "../firebase-config";

const checkBoxStyles = (theme) => ({
  root: {
    color: "#34baf2",
    "&$checked": {
      color: "#34baf2",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #52d869`,
    backgroundColor: `#52d869`,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class ShareReportPage extends Component {
  componentDidMount() {
    firebase.analytics().logEvent("Survey Share report page is rendered");
    this.getPendingInviteEmails();
  }

  state = {
    email: {
      subject: `${this.props.compname} - Report for ${this.props.survname}`,
      dest: "",
      message: `Enclose please find the Report for ${this.props.survname}.

      Yours sincerely,
      ${firebase.auth().currentUser.displayName}
      `,
      token: "",
    },
    copied: false,
    emails: [],
    bind: false,
    attachments: [],
    attachment: {},
    menuItem: "SENDMAIL",
    activeEmailGroupId: "",
    isPdfOrientationLandscape: true,
    reportFormat: this.props.type === actionTypes.COMPARATIVE ? "excel" : "pdf", // options ---- 'excel', 'pdf', 'both'
    reportType:
      this.props.type === actionTypes.COMPARATIVE ? actionTypes.INDIVIDUAL : "",
    surveyee_list: this.props.surveyee_list,
    selectedSurveyees: this.props.surveyee_list
      ? [{ ...this.props.surveyee_list[0], index: 0 }]
      : [],
    includeOpenEndedQuestion: true,
    includeTable: true,
    includePieChart: true,
  };
  sendEmail = () => {
    let addDirName = "";
    let reportName =
      this.props.compname +
      "_" +
      this.props.survname +
      "_" +
      new Date().getTime() +
      addDirName;

    const { email } = this.state;
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Sending Mail");
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        const emarr = this.state.emails;
        const reportAudit = {
          _id: newId(),
          recipients: emarr,
          subject: email.subject,
          body: email.message,
          time: new Date().getTime(),
          type: "REPORT",
          targetId: this.props.surveyId,
          attachments: this.state.attachments,
          mailSender: firebase.auth().currentUser.email,
          reportDetails:
            this.props.type === actionTypes.COMPARATIVE
              ? {
                  reportName: reportName,
                  reportFormat: this.state.reportFormat,
                  pdfReportOptions: {
                    includeOpenEndedQuestion:
                      this.state.includeOpenEndedQuestion,
                    includeTable: this.state.includeTable,
                    includePieChart: this.state.includePieChart,
                  },
                  surveyType: actionTypes.COMPARATIVE,
                  reportType: this.state.reportType,

                  selectedSurveyees: JSON.stringify(
                    this.state.selectedSurveyees
                  ),
                }
              : {
                  reportName: reportName,
                  reportFormat: this.state.reportFormat,
                  pdfReportOptions: {
                    includeOpenEndedQuestion:
                      this.state.includeOpenEndedQuestion,
                    includeTable: this.state.includeTable,
                    includePieChart: this.state.includePieChart,
                  },
                  surveyType: actionTypes.REGULAR,
                },
        };

        db.collection(`mailAudit/${this.props.surveyId}/mailAuditList`)
          .doc(String(reportAudit._id))
          .set(reportAudit)
          .then((res) => {
            console.log(`RA - ${reportAudit._id}`);
            const fetcharr = [];
            const emailList = emarr.join(",");
            let emailId = emailList[0];
            Notiflix.Loading.Init({ svgColor: "#3bbfde" });
            Notiflix.Loading.Pulse("Sending Mail");

            let customPath =
              this.props.type === actionTypes.COMPARATIVE
                ? `/shareReport?dest=${emailId}&subject=${
                    email.subject
                  }&surveyid=${this.props.surveyId}&compid=${
                    this.props.compId
                  }&reportAuditId=${
                    reportAudit._id
                  }&report_name=${reportName}&companyName=${encodeURIComponent(
                    this.props.compname
                  )}&surveyTitle=${this.props.survname}&surveySubTitle=${
                    this.props.surveySubTitle
                  }&pdfOrientation=${
                    this.state.isPdfOrientationLandscape
                      ? "portrait"
                      : "landscape"
                  }&showPdfPieChart=${
                    this.state.includePieChart
                  }&showPdfOpenEndedQuestion=${
                    this.state.includeOpenEndedQuestion
                  }&showPdfTable=${this.state.includeTable}&reportFormat=${
                    this.state.reportFormat
                  }&emailList=${emailList}&isPdfOrientationLandscape=${
                    this.state.isPdfOrientationLandscape
                  }&surveyType=${this.props.type}&reportType=${
                    this.state.reportType
                  }&selectedSurveyees=${JSON.stringify(
                    this.state.selectedSurveyees
                  )}`
                : `/shareReport?dest=${emailId}&subject=${
                    email.subject
                  }&surveyid=${this.props.surveyId}&compid=${
                    this.props.compId
                  }&reportAuditId=${
                    reportAudit._id
                  }&report_name=${reportName}&companyName=${encodeURIComponent(
                    this.props.compname
                  )}&surveyTitle=${this.props.survname}&surveySubTitle=${
                    this.props.surveySubTitle
                  }&pdfOrientation=${
                    this.state.isPdfOrientationLandscape
                      ? "portrait"
                      : "landscape"
                  }&showPdfPieChart=${
                    this.state.includePieChart
                  }&showPdfOpenEndedQuestion=${
                    this.state.includeOpenEndedQuestion
                  }&showPdfTable=${this.state.includeTable}&reportFormat=${
                    this.state.reportFormat
                  }&emailList=${emailList}&isPdfOrientationLandscape=${
                    this.state.isPdfOrientationLandscape
                  }&surveyType=${this.props.type}`;

            http.get(customPath).then(async (response) => {
              if (response.status === 200) {
                let responseArr = response.data;

                let sentMailIds = [];
                let failedMailIds = [];
                responseArr.forEach((respForMail, i) => {
                  if (respForMail.status === "fulfilled") {
                    let value = respForMail.value;
                    if (value.status === "success") {
                      sentMailIds.push(value.email);
                    } else if (value.status === "failure") {
                      failedMailIds.push(value.email);
                    }
                  } else if (respForMail.status === "rejected") {
                    let value = respForMail.value;
                    failedMailIds.push(value.email);
                    console.error(
                      `email to ${value.email} failed with reason : ${value.message}`
                    );
                  }
                });
                let notiFInit = {
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#34baf2" },
                };

                db.collection(`logs/${this.props.compId}/logsList`)
                  .add({
                    name: firebase.auth().currentUser.displayName,
                    email: firebase.auth().currentUser.email,
                    timestamp: new Date().getTime(),
                    entity: "Survey Report",
                    operation: "SENT",
                    payload: {
                      email: sentMailIds.join(", "),
                      survey: this.props.survname,
                    },
                  })
                  .then((res) => {
                    this.setState({
                      email: {
                        ...email,
                        subject: `${this.props.compname} - Report for ${this.props.survname}`,
                        dest: "",
                        token: "",
                      },
                    });
                    Notiflix.Loading.Remove();
                    Notiflix.Report.Init(notiFInit);
                    Notiflix.Report.Success(
                      "Survey Report Sent.",
                      "Survey Report has been successfully sent to " +
                        sentMailIds.join(", "),
                      "ok"
                    );
                    this.setState({ emails: [] });
                    this.setState({ attachments: [] });
                  })
                  .catch((err) => {
                    Notiflix.Report.Init(notiFInit);
                    Notiflix.Report.Success(
                      "Survey Report Sent.",
                      "Survey Report has been successfully sent to " +
                        sentMailIds.join(", "),
                      "ok"
                    );
                    this.setState({ emails: [] });
                    this.setState({ attachments: [] });
                  });
              } else {
                Notiflix.Loading.Remove();
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Failure(
                  "Survey Report Unsucessful",
                  "Survey Report could not be sent!",
                  "ok"
                );
                this.setState({ emails: [] });
                this.setState({ attachments: [] });
              }
            });
          })
          .catch((error) => {
            alert(error);
          });
      });
  };

  // Function to download the file
  downloadFile = (link) => {
    window.open(link);
  };

  // Toggle between history and share
  switchMenu = (menu) => {
    this.setState({ menuItem: menu });
  };

  switchReportFormat = (format) => {
    this.setState({ reportFormat: format });
  };
  switchReportType = (type) => {
    if (type === actionTypes.CUMULATIVE) {
      this.setState({ reportType: type, selectedSurveyees: [{}] });
    } else {
      this.setState({
        reportType: type,
        selectedSurveyees: [{ ...this.props.surveyee_list[0], index: 0 }],
      });
    }
  };

  handleEmailGroup = (emailGroupId) => {
    const filterEmailGroup = this.props.emailGroups.filter(
      (emailGroup) => emailGroup.id === emailGroupId
    );

    this.setState({
      activeEmailGroupId: emailGroupId,
      emails: filterEmailGroup[0]?.emails || [],
    });
  };

  getPendingInviteEmails = () => {
    db.collection("temp")
      .where("surveyId", "==", this.props.surveyId)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => data.push({ ...doc.data() }));
        if (data.length) {
          const pendingInvites = data.filter((el) => el.status == "Incomplete");

          if (pendingInvites.length > 0) {
            const emails = _.map(pendingInvites, "emailId");
            this.props.setPendingInvites({
              name: "Pending Responders",
              emails: emails,
              id: "1",
            });
          }
        }
      });
  };

  downloadFile = (fileName) => {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      link.setAttribute("href", `${process.env.PUBLIC_URL}/${fileName}`);
      link.setAttribute("target", "_blank");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  updateSelectedSurveyees = (obj) => {
    let selectedSurveyees = [...this.state.selectedSurveyees];
    let index = selectedSurveyees.findIndex(
      (selectedSurveyeeObj) => obj.email === selectedSurveyeeObj.email
    );
    if (index >= 0) {
      selectedSurveyees.splice(index, 1);
    } else if (index < 0) {
      selectedSurveyees.push({ ...obj });
    }
    this.setState({ selectedSurveyees: [...selectedSurveyees] });
  };
  render() {
    const { email, emails } = this.state;
    const { menuItem } = this.state;
    const {
      reportFormat,
      reportType = "",
      selectedSurveyees = [],
    } = this.state;
    const spacer = {
      margin: 10,
    };
    const textArea = {
      borderRadius: 4,
    };

    let surveyHasResponse = false;

    db.collection(`results/${this.props.surveyId}/resultsList`).onSnapshot(
      (snapshot) => {
        let surveyResults = [];
        snapshot.forEach((doc) => surveyResults.push({ ...doc.data() }));
        if (surveyResults === null || typeof surveyResults === "undefined") {
          surveyHasResponse = false;
        } else {
          surveyHasResponse = true;
        }
      }
    );

    let allEmailGroups = this.props.emailGroups;
    const seen = new Set();
    let updatedEmailGroups = [];
    if (allEmailGroups.length > 0) {
      updatedEmailGroups = allEmailGroups.filter((el) => {
        const duplicateGroup = seen.has(el.id);
        seen.add(el.id);
        return !duplicateGroup;
      });
    }

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Bind Emails</Popover.Title>
        <Popover.Content>
          When this option is enabled, the user can only perform the survey when
          they have logged in with the same account to which the email is sent.
        </Popover.Content>
      </Popover>
    );

    return (
      <div className={classes.ShareForm}>
        <div className={classes.ShareComponent}>
          <div style={{ marginTop: 10 }}>
            <label
              className={
                classes.TopMenu +
                " " +
                (menuItem === "EMAIL_GROUPS" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => this.switchMenu("EMAIL_GROUPS")}
            >
              View/Add Group
            </label>
            <label
              className={
                classes.TopMenu +
                " " +
                classes.History +
                " " +
                (menuItem === "SENDMAIL" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => this.switchMenu("SENDMAIL")}
            >
              {" "}
              Share By Email{" "}
            </label>
            <label
              className={
                classes.TopMenu +
                " " +
                classes.History +
                " " +
                (menuItem === "HISTORY" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => {
                this.switchMenu("HISTORY");
              }}
            >
              Mail Audit
            </label>
          </div>
          {/* Send Mail Start*/}
          {menuItem === "SENDMAIL" && (
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  display: this.props.status === "Inactive" ? null : "none",
                  marginTop: "20px",
                  width: "80vw",
                }}
              >
                <Alert severity="error">
                  This company is currently inactive, email invites cannot be
                  sent.
                </Alert>
              </div>
              <Alert
                severity="warning"
                style={{
                  marginTop: "15px",
                  marginBottom: "30px",
                  display: this.state.bind === true ? null : "none",
                  width: "80vw",
                }}
              >
                Enabling bind will require the respondents to perform an extra
                step of logging in before taking the survey.
              </Alert>
              <div style={{ display: "flex", marginTop: 30 }}>
                {updatedEmailGroups.length > 0 && (
                  <div>
                    <label> Group Name </label>
                    <br />
                    <select
                      className={classes.SelectEmailGroup}
                      value={this.state.activeEmailGroupId}
                      onChange={(e) => this.handleEmailGroup(e.target.value)}
                    >
                      <option value={""}>Select</option>
                      {updatedEmailGroups.map((emailGroup) => (
                        <option value={emailGroup.id}>{emailGroup.name}</option>
                      ))}
                    </select>
                  </div>
                )}

                <div>
                  <label>
                    Recipients (Press Space/Enter after each email){" "}
                  </label>

                  <br />
                  <ReactMultiEmail
                    placeholder="Enter Recipients"
                    style={{
                      width: "67vw",
                      border: "2px solid #34baf2",
                      borderRadius: "3px",
                      minWidth: "200px",
                      minHeight: "30px",
                      marginTop: "2px",
                      marginBottom: "15px",
                      fontSize: "20px",
                      padding: "7px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    emails={this.state.emails}
                    onChange={(_emails) => {
                      this.setState({ emails: _emails });
                    }}
                    validateEmail={(email) => {
                      return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <p
                            style={{
                              fontSize: "17px",
                              marginTop: "13px",
                              color: "#000",
                            }}
                          >
                            {email}
                          </p>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                            style={{ fontSize: "20px" }}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div style={spacer} />
              <label> Subject </label>
              <br />
              <input
                className={classes.Subject}
                value={email.subject}
                onChange={(e) =>
                  this.setState({
                    email: { ...email, subject: e.target.value },
                  })
                }
              />
              <div style={spacer} />
              <label> Message </label>
              <br />
              <textarea
                className={classes.Message}
                rows={3}
                value={email.message}
                style={textArea}
                onChange={(e) =>
                  this.setState({
                    email: { ...email, message: e.target.value },
                  })
                }
              />
              <div style={spacer} />
              <label> Email Settings </label>
              <br />
              <div className={classes.EmailSettingsContainer}>
                {this.props.type !== actionTypes.COMPARATIVE && (
                  <div className={classes.EmailSettingIncludeOptionsContainer}>
                    <label
                      className={classes.ReportLayoutOptionText}
                      htmlFor="IncludeOpenEndedQuestions"
                    >
                      Include Open Ended Questions
                      <CustomCheckbox
                        id="IncludeOpenEndedQuestions"
                        name="IncludeOpenEndedQuestions"
                        checked={this.state.includeOpenEndedQuestion}
                        onChange={() =>
                          this.setState({
                            includeOpenEndedQuestion:
                              !this.state.includeOpenEndedQuestion,
                          })
                        }
                        iconStyle={{ fill: "#34baf2" }}
                        style={{
                          transform: "scale(1.5)",
                          marginLeft: "10px",
                          marginRight: "30px",
                        }}
                      />
                    </label>
                    <label
                      className={classes.ReportLayoutOptionText}
                      htmlFor="IncludeTable"
                    >
                      Include Table
                      <CustomCheckbox
                        id="IncludeTable"
                        name="IncludeTable"
                        checked={this.state.includeTable}
                        onChange={() =>
                          this.setState({
                            includeTable: !this.state.includeTable,
                          })
                        }
                        iconStyle={{ fill: "#34baf2" }}
                        style={{
                          transform: "scale(1.5)",
                          marginLeft: "10px",
                          marginRight: "30px",
                        }}
                      />
                    </label>
                    <label
                      className={classes.ReportLayoutOptionText}
                      htmlFor="IncludePieChart"
                    >
                      Include PieChart
                      <CustomCheckbox
                        id="IncludePieChart"
                        name="IncludePieChart"
                        checked={this.state.includePieChart}
                        onChange={() =>
                          this.setState({
                            includePieChart: !this.state.includePieChart,
                          })
                        }
                        iconStyle={{ fill: "#34baf2" }}
                        style={{
                          transform: "scale(1.5)",
                          marginLeft: "10px",
                          marginRight: "30px",
                        }}
                      />
                    </label>
                  </div>
                )}
                <div style={spacer} />
                {/* Temporarily Hiding pdf report mode selection switch */}
                <div style={spacer} />
                {this.props.type !== actionTypes.COMPARATIVE && (
                  <div>
                    <span className={classes.EmailSettingSubHeading}>
                      Report Layout
                    </span>
                    <div className={classes.ReportLayoutToggleButtonContainer}>
                      <span className={classes.ReportLayoutOptionText}>
                        Landscape Mode
                      </span>
                      <IOSSwitch
                        checked={!this.state.isPdfOrientationLandscape}
                        onChange={() =>
                          this.setState({
                            isPdfOrientationLandscape:
                              !this.state.isPdfOrientationLandscape,
                          })
                        }
                        name="checkedB"
                      />
                      <span className={classes.ReportLayoutOptionText}>
                        Potrait Mode
                      </span>
                    </div>
                  </div>
                )}
                <div style={spacer} />
                <div style={spacer} />
                <div>
                  <span className={classes.EmailSettingSubHeading}>
                    Report Format
                  </span>
                  <div className={classes.EmailSettingIncludeOptionsContainer}>
                    <label
                      className={classes.ReportLayoutOptionText}
                      htmlFor="Excel_Format"
                    >
                      Excel
                      <CustomCheckbox
                        id="Excel_Format"
                        name="Excel_Format"
                        checked={
                          this.props.type === actionTypes.COMPARATIVE
                            ? true
                            : reportFormat == "excel"
                        }
                        onChange={() =>
                          this.props.type !== actionTypes.COMPARATIVE &&
                          this.switchReportFormat("excel")
                        }
                        iconStyle={{ fill: "#34baf2" }}
                        style={{
                          // transform: "scale(1)",
                          marginLeft: "10px",
                          marginRight: "30px",
                        }}
                      />
                    </label>
                    {this.props.type !== actionTypes.COMPARATIVE && (
                      <>
                        <label
                          className={classes.ReportLayoutOptionText}
                          htmlFor="Pdf_Format"
                        >
                          Pdf
                          <CustomCheckbox
                            id="Pdf_Format"
                            name="Pdf_Format"
                            checked={reportFormat == "pdf"}
                            onChange={() => this.switchReportFormat("pdf")}
                            iconStyle={{ fill: "#34baf2" }}
                            style={{
                              // transform: "scale(1)",
                              marginLeft: "10px",
                              marginRight: "30px",
                            }}
                          />
                        </label>
                        <label
                          className={classes.ReportLayoutOptionText}
                          htmlFor="Both_Format"
                        >
                          Both
                          <CustomCheckbox
                            id="Both_Format"
                            name="Both_Format"
                            checked={reportFormat == "both"}
                            onChange={() => this.switchReportFormat("both")}
                            iconStyle={{ fill: "#34baf2" }}
                            style={{
                              // transform: "scale(1)",
                              marginLeft: "10px",
                              marginRight: "30px",
                            }}
                          />
                        </label>
                      </>
                    )}
                  </div>
                </div>
                <div style={spacer} />
                <div style={spacer} />
                {this.props.type == actionTypes.COMPARATIVE && (
                  <>
                    <div>
                      <span className={classes.EmailSettingSubHeading}>
                        Report Type
                      </span>
                      <div
                        className={classes.EmailSettingIncludeOptionsContainer}
                      >
                        <label
                          className={classes.ReportLayoutOptionText}
                          htmlFor="Individual"
                        >
                          Individual
                          <CustomCheckbox
                            id="Individual"
                            name="Individual"
                            checked={reportType == actionTypes.INDIVIDUAL}
                            onChange={() =>
                              this.switchReportType(actionTypes.INDIVIDUAL)
                            }
                            iconStyle={{ fill: "#34baf2" }}
                            style={{
                              // transform: "scale(1)",
                              marginLeft: "10px",
                              marginRight: "30px",
                            }}
                          />
                        </label>
                        <label
                          className={classes.ReportLayoutOptionText}
                          htmlFor="Cumulative"
                        >
                          Cumulative
                          <CustomCheckbox
                            id="Cumulative"
                            name="Cumulative"
                            checked={reportType == actionTypes.CUMULATIVE}
                            onChange={() =>
                              this.switchReportType(actionTypes.CUMULATIVE)
                            }
                            iconStyle={{ fill: "#34baf2" }}
                            style={{
                              // transform: "scale(1)",
                              marginLeft: "10px",
                              marginRight: "30px",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    {reportType === actionTypes.INDIVIDUAL && (
                      <div>
                        <span className={classes.EmailSettingSubHeading}>
                          Select an Individual
                        </span>
                        <div
                          className={
                            classes.EmailSettingIncludeOptionsContainer
                          }
                        >
                          {this.props.surveyee_list?.map((obj, i) => {
                            return (
                              <label
                                className={classes.ReportLayoutOptionText}
                                htmlFor="surveyee"
                              >
                                {obj?.name}
                                <CustomCheckbox
                                  id="surveyee"
                                  name="surveyee"
                                  checked={
                                    selectedSurveyees.find(
                                      (surveyeeObj) =>
                                        surveyeeObj.name === obj?.name
                                    )?.email
                                  }
                                  onChange={() =>
                                    this.updateSelectedSurveyees({
                                      ...obj,
                                      index: i,
                                    })
                                  }
                                  iconStyle={{ fill: "#34baf2" }}
                                  style={{
                                    // transform: "scale(1)",
                                    marginLeft: "10px",
                                    marginRight: "30px",
                                  }}
                                />
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={classes.DownloadSampleReportButtonContainer}>
                <div
                  className={classes.DownloadSampleReportButton}
                  onClick={() =>
                    this.downloadFile("Sample File_Survey Report in PDF.pdf")
                  }
                >
                  Download sample Pdf Report
                </div>
                <div
                  className={classes.DownloadSampleReportButton}
                  onClick={() =>
                    this.downloadFile("Sample File_Survey Report in Excel.xlsx")
                  }
                >
                  Download sample excel Report
                </div>
              </div>
              <div style={spacer} />
              <button
                onClick={() => {
                  if (this.props.status === "Inactive") {
                    alert("Company Inactive");
                  } else if (this.state.emails.length < 1) {
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "No Recipients!",
                      "Add Recipients to send survey report.",
                      "ok"
                    );
                  } else if (!surveyHasResponse) {
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "No Responses!",
                      "Currently there are no responses for this survey.",
                      "ok"
                    );
                  } else if (this.state.selectedSurveyees.length === 0) {
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "No Surveyee Selected!",
                      "Select atleast one surveyee to generate the report.",
                      "ok"
                    );
                  } else {
                    let emailarr = this.state.emails;
                    this.sendEmail();
                  }
                }}
                className={classes.SendButton}
                disabled={this.props.status === "Inactive"}
                style={{
                  display: this.props.status === "Inactive" ? "none" : null,
                }}
              >
                Send Email
              </button>
            </div>
          )}
          {/* Send Mail Ends*/}

          {/* History Items Start*/}
          {menuItem === "HISTORY" && (
            <div style={{ marginTop: 10 }}>
              <InviteeList currentAuditType="REPORT" />
            </div>
          )}
          {/* History Items Ends*/}

          {menuItem === "EMAIL_GROUPS" && (
            <div style={{ marginTop: 10 }}>
              <EmailGroups />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    compId: state.surveyEditer.company.id,
    compname: state.surveyEditer.company.name,
    emailGroups: state.surveyEditer.company.emailGroups,
    survname: state.surveyEditer.survey.title,
    surveySubTitle: state.surveyEditer.survey.subTitle,
    status: state.surveyEditer.company.status,
    openTill: state.surveyEditer.survey.openTill,
    type: state.surveyEditer.survey?.type || "",
    surveyee_list:
      state.surveys.surveys.find(
        (obj) => obj.id === state.surveyEditer.survey.id
      ).content.surveyee_list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSurveyId: (surveyId) =>
      dispatch({ type: actionTypes.GET_SURVEY_ID, surveyId: surveyId }),
    setPendingInvites: (pendingInvites) =>
      dispatch({ type: "SET_PENDING_INVITES", pendingInvites }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShareReportPage)
);
