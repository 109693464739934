import React from "react";
import { Field } from "formik";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";

import classes from "./Question.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const Checkboxes = (props) => {
  const {
    title,
    options,
    _id,
    isRequired,
    caller,
    surveyee_count,
    idAddOn,
    result,
  } = props;
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";

  return (
    <div className={classes.Question}>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <div className={classes.GroupChoices}>
          {options.map((option, index) => {
            let valueCheck = result
              ? result[_id]
                ? result[_id][option._id]
                  ? typeof idAddOn == "number" && idAddOn >= 0
                    ? result[_id][option._id][idAddOn]
                    : result[_id][option._id]
                  : ""
                : ""
              : "";
            return (
              <div key={index} style={{ display: "flex" }}>
                <label className={classes.Container}>
                  {option.content}
                  <Field
                    type="checkbox"
                    name={`${_id}[${option._id}]${addOnId}`}
                    component="input"
                    defaultChecked={
                      props.result
                        ? props.result[_id]
                          ? props.result[_id][option._id]
                            ? valueCheck === true
                              ? true
                              : false
                            : false
                          : false
                        : false
                    }
                  />
                  <span className={classes.Checkmark}></span>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Checkboxes;
