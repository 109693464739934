import React from "react";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";

import classes from "./QuestionPreview.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const SingleLineText = (props) => {
  const { title, placeholder, _id, isRequired, caller, surveyee_count } = props;

  return (
    <>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          name={_id}
          className={classes.Input}
          disabled
        />
      )}
    </>
  );
};

export default SingleLineText;
