import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function QuestionsDropdown(props) {
  const { number, setNumber, totalQuestions } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">
        Position of question
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={number}
        onChange={handleChange}
      >
        {Array.from({ length: totalQuestions }, (v, i) => i).map((d, i) => {
          return <MenuItem value={i + 1}>{i + 1}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}
