import { connect } from "react-redux";
import React, { Component } from "react";
import QuestionListPanelView from "../../Components/EditPanel/QuestionListPanel";

import { addQuestion } from "../../Store/actions/surveyEditer";
import { QuestionDescriptions } from "../../ulitity/constants/Questions";
class QuestionListPanelViewContainer extends Component {
  render() {
    return <QuestionListPanelView {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    current_question_id:
      state.surveyEditer.survey.question_order[
        state.surveyEditer.survey.question_order.length - 1
      ],
    totalQuestions: Object.keys(state.surveyEditer.survey.questions)?.length,
    updated_current_question_id: state.surveyEditer.survey.current_question_id,
    questions: QuestionDescriptions.map((q) => {
      return {
        text: q.text,
        action: (number) =>
          addQuestion(q.type, state.surveyEditer.survey?.type, number),
      };
    }),
  };
};

export default connect(mapStateToProps)(QuestionListPanelViewContainer);
