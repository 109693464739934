import newId from "../idGenerator";

export const QuestionDescriptions = [
  {
    text: "Single Line Answer",
    type: "SINGLE_LINE_TEXT"
  },
  {
    text: "Multiple Line Answer",
    type: "MULTI_LINE_TEXT"
  },
  {
    text: "Multiple Choice",
    type: "MULTI_CHOICE"
  },
  {
    text: "Checkboxes",
    type: "CHECKBOXES"
  },
  {
    text: "Dropdown",
    type: "DROPDOWN"
  },
  {
    text: "Rating",
    type: "RATING"
  }
];

export const QuestionTypes = {
  SINGLE_LINE_TEXT: "SINGLE_LINE_TEXT",
  MULTI_LINE_TEXT: "MULTI_LINE_TEXT",
  MULTI_CHOICE: "MULTI_CHOICE",
  CHECKBOXES: "CHECKBOXES",
  DROPDOWN: "DROPDOWN",
  RATING: "RATING"
};

export const InitQuestions = {
  [QuestionTypes.MULTI_CHOICE]: () => ({
    _id: newId(),
    type: QuestionTypes.MULTI_CHOICE,
    title: "Select a choice",
    isRequired: false,
    options: [
      {
        _id: newId(),
        content: "First choice"
      },
      {
        _id: newId(),
        content: "Second choice"
      },
      {
        _id: newId(),
        content: "Third choice"
      }
    ]
  }),
  [QuestionTypes.CHECKBOXES]: () => ({
    _id: newId(),
    type: QuestionTypes.CHECKBOXES,
    title: "Check All That Apply",
    isRequired: false,
    options: [
      {
        _id: newId(),
        content: "First choice"
      },
      {
        _id: newId(),
        content: "Second choice"
      },
      {
        _id: newId(),
        content: "Third choice"
      }
    ]
  }),
  [QuestionTypes.SINGLE_LINE_TEXT]: () => ({
    _id: newId(),
    type: QuestionTypes.SINGLE_LINE_TEXT,
    title: "Untitled",
    placeholder: "",
    isRequired: false
  }),
  [QuestionTypes.MULTI_LINE_TEXT]: () => ({
    _id: newId(),
    type: QuestionTypes.MULTI_LINE_TEXT,
    title: "Untitled",
    placeholder: "",
    isRequired: false
  }),
  [QuestionTypes.DROPDOWN]: () => ({
    _id: newId(),
    type: QuestionTypes.DROPDOWN,
    title: "Select a choice",
    isRequired: false,
    options: [
      {
        _id: newId(),
        content: "First choice"
      },
      {
        _id: newId(),
        content: "Second choice"
      },
      {
        _id: newId(),
        content: "Third choice"
      }
    ]
  }),
  [QuestionTypes.RATING]: () => ({
    _id: newId(),
    type: QuestionTypes.RATING,
    title: "Select Suitable Rating Scale",
    isRequired: false,
    stars: 5,
    options: [
      {
        _id: newId(),
        content: "1-5 Stars",
        value: 5,
      },
      {
        _id: newId(),
        content: "1-10 Stars",
        value: 10,
      }
    ]
  }),
  abc: () => ({
    _id: newId(),
    type: "MULTI_CHOICE",
    title: "Evaluate the following statements",
    questions: [
      {
        _id: newId(),
        content: "First Question"
      },
      {
        _id: newId(),
        content: "Second Question"
      },
      {
        _id: newId(),
        content: "Third Question"
      }
    ],
    options: [
      {
        _id: newId(),
        content: "First choice"
      },
      {
        _id: newId(),
        content: "Second choice"
      },
      {
        _id: newId(),
        content: "Third choice"
      }
    ]
  })
};
