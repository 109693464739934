import React from "react";
import { Field } from "formik";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import classes from "./Question.module.css";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const SingleLineText = ({
  isRequired,
  title,
  placeholder,
  _id,
  result,
  compReport = false,
  caller,
  surveyee_count,
  idAddOn,
}) => {
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";
  let defaultValue = result
    ? result[_id]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id][idAddOn]
        : result[_id]
      : ""
    : "";

  return (
    <div className={classes.Question}>
      {!compReport && caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <div>
          <Field
            type="text"
            placeholder={placeholder}
            name={`${_id}${addOnId}`}
            className={classes.Input}
            component="input"
            defaultValue={defaultValue}
          />
        </div>
      )}
    </div>
  );
};

export default SingleLineText;
