import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "@material-ui/core/Icon";
import GreenSwitcher from "../UI/Switcher/GreenSwitch";
import GreySwitcher from "../UI/Switcher/GreySwitch";
import { toggleSubmit } from "../../Store/actions/surveyEditer";
import "react-confirm-alert/src/react-confirm-alert.css";
import classes from "./SurveyItem.module.css";
import Notiflix from "notiflix-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Tooltip from "@material-ui/core/Tooltip";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPollH } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@material-ui/core/Checkbox";

class SurveyItem extends Component {
  state = {
    submitState: false,
    selected: false,
  };

  submit = () => {
    if (this.props.role === "Admin" || this.props.role === "Editor") {
      Notiflix.Confirm.Init({
        titleColor: "#32c6b2",
        cancelButtonBackground: "#ea3434",
        width: "360px",
      });
      Notiflix.Confirm.Show(
        "Delete Survey",
        "Are you sure you want to delete this survey? This action cannot be undone.",
        "Yes",
        "No",
        () => {
          this.props.delete();
        }
      );
    } else {
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "Rights Mismatch",
        "Only Admins and Editor can delete surveys. You do not have the correct rights",
        "ok"
      );
    }
  };

  componentDidMount() {
    this.setState({ submitState: this.props.submitting });
  }

  switchHandler = () => {
    if (this.props.role === "Admin" || this.props.role === "Editor") {
      this.setState((prevState) => {
        return { submitState: !prevState.submitState };
      });
      this.props.onToggle(
        this.props.surveyId,
        this.props.token,
        !this.state.submitState,
        this.props.compId,
        this.props.title
      );
    } else {
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "Rights Mismatch",
        "Only Admins and Editor can control survey status. You do not have the correct rights",
        "ok"
      );
    }
  };

  checExpiryDate = () => {
    var expiryDate = new Date(this.props.openTill);
    if (expiryDate < new Date()) {
      return true;
    }
    return false;
  };
  render() {
    const creatorDate = new Date(this.props.creatorDate).toDateString();
    const lastModified =
      typeof this.props.lastModified == "object"
        ? this.props.lastModified?.toDate().toDateString()
        : new Date(this.props.lastModified).toDateString();
    const openTill = new Date(this.props.openTill).toDateString();
    return (
      <div
        className={classes.SurveyItem}
        style={{
          borderBottom: this.state.submitState
            ? "7px solid #24BDBC"
            : "7px solid #EA5C5F",
          borderBottomLeftRadius: "9px",
          borderBottomRightRadius: "9px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.props.role === "Admin" ? (
            <Checkbox
              checked={this.props.checked}
              style={{ height: "fit-content", marginRight: 20 }}
              onChange={(event) => this.props.onSelectSurvey(event)}
              color="#34baf2"
            />
          ) : null}

          <div>
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faPollH}
                style={{
                  fontSize: "30px",
                  color: "#495058",
                  marginTop: "12px",
                  marginRight: "15px",
                }}
              />
              <h3>{this.props.title}</h3>
            </div>
            <div>
              <p>
                Created On: {creatorDate}{" "}
                <span style={{ color: "#DDDDDD" }}>|</span>
              </p>
              <p>Last Update: {lastModified}</p>
              <p>Survey End Date: {openTill}</p>
            </div>
          </div>
        </div>

        <div className={classes.ButtonContainer}>
          <div
            className={classes.Switcher}
            style={{
              display:
                this.props.role === "Admin" || this.props.role === "Editor"
                  ? "normal"
                  : "none",
            }}
          >
            {this.checExpiryDate() ? (
              <GreySwitcher checked={false} label="Not Accepting Responses" />
            ) : (
              <GreenSwitcher
                checked={this.state.submitState}
                onChange={() => this.switchHandler()}
                label={
                  this.state.submitState ? (
                    <span className={classes.Active}>Accepting Responses</span>
                  ) : (
                    <span className={classes.Closed}>
                      Not Accepting Responses
                    </span>
                  )
                }
              />
            )}
          </div>

          <div
            className={classes.GroupeButton}
            style={{
              marginTop:
                this.props.role === "Director" || this.props.role === "Auditor"
                  ? "25px"
                  : "0px",
              marginRight:
                this.props.role === "Director" || this.props.role === "Auditor"
                  ? "15px"
                  : "0px",
            }}
          >
            <button onClick={this.props.open} className={classes.ViewButton}>
              <Icon className={classes.View}>visibility</Icon>View
            </button>
            <Tooltip title="Clone this survey as a new survey">
              <button
                onClick={() => {
                  if (
                    this.props.role === "Admin" ||
                    this.props.role === "Editor"
                  ) {
                    confirmAlert({
                      title: "Clone Survey?",
                      message:
                        "Are you sure you want to clone this survey as a template? Only the questions will be copied over to the new survey.Continue?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: this.props.clone,
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  } else {
                    alert("Rights Mismatch");
                  }
                }}
                className={classes.ViewButton}
                style={{
                  display:
                    this.props.role === "Admin" || this.props.role === "Editor"
                      ? null
                      : "none",
                }}
              >
                <Icon className={classes.View}>add</Icon>Clone
              </button>
            </Tooltip>

            <button
              onClick={() => {
                if (
                  firebase.auth().currentUser.email ===
                  "evaladmin03092014@cimplyfive.com"
                ) {
                  confirmAlert({
                    title: "Push As Template",
                    message:
                      "Are you sure you want to push this survey as a template for all the users? The survey title will be taken as the template title and the subtitle will be used as a template description, click yes only if you are sure about it.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: this.props.pushtemplate,
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                } else {
                  alert("This option is reserved for Cimplybest Admins only");
                }
              }}
              className={classes.PushButton}
              style={{
                display:
                  firebase.auth().currentUser.email ===
                  "evaladmin03092014@cimplyfive.com"
                    ? null
                    : "none",
              }}
            >
              Push As Template
            </button>
            <button
              onClick={this.submit}
              className={classes.DeleteButton}
              style={{
                display:
                  this.props.role === "Admin" || this.props.role === "Editor"
                    ? "normal"
                    : "none",
              }}
            >
              <Icon className={classes.Delete}>delete</Icon>Delete
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    role: state.surveyEditer.company.role,
    compId: state.surveyEditer.company.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggle: (surveyId, token, submit, compId, title) =>
      dispatch(toggleSubmit(surveyId, token, submit, compId, title)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyItem);
