import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/actions/actionsTypes";
import CompanyItem from "../../Components/CompanyList/CompanyItem";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { fetchCompanies } from "../../Store/actions/companies";
import img from "../../assets/images/pixeltrue-search.svg";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import classes from "./CompanyList.module.css";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { db } from "../../firebase-config";
import firebase from "firebase";

class CompanyList extends Component {
  componentDidMount() {
    this.props.onFetchCompanies(this.props.token, this.props.userId);
  }

  openCompany(company) {
    db.collection(`company/${company._id}/roles`)
      .doc(String(localStorage.getItem("email")))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const roleData = { ...doc.data(), id: doc.id };
        this.props.onGetCompId(
          company._id,
          company.logo,
          company.name,
          moment(company.activeTill, "DD/MM/YYYY").isAfter(moment(new Date()))
            ? "Active"
            : "Inactive",
          company.activeTill,
          company.address,
          company.cin,
          roleData.role
        );
        localStorage.setItem("companyid", company._id);
      });
  }

  render() {
    let companyList = (
      <div>
        <div
          style={{
            marginLeft: "55px",
            marginRight: "45px",
            marginTop: "50px",
            display: "flex",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={70}
                height={70}
                style={{ marginBottom: "15px", borderRadius: "7px" }}
              ></Skeleton>
              <Skeleton
                variant="rect"
                width={400}
                height={35}
                animation="wave"
                style={{
                  borderRadius: "7px",
                  marginLeft: "10px",
                  marginTop: "15px",
                }}
              />
            </div>
            <Skeleton
              variant="rect"
              width={500}
              height={250}
              animation="wave"
            />
          </div>
          <div style={{ marginLeft: "100px" }}>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={70}
                height={70}
                style={{ marginBottom: "15px", borderRadius: "7px" }}
              ></Skeleton>
              <Skeleton
                variant="rect"
                width={400}
                height={35}
                animation="wave"
                style={{
                  borderRadius: "7px",
                  marginLeft: "10px",
                  marginTop: "15px",
                }}
              />
            </div>
            <Skeleton
              variant="rect"
              width={500}
              height={250}
              animation="wave"
            />
          </div>
        </div>
        <div
          style={{
            marginLeft: "55px",
            marginRight: "45px",
            marginTop: "50px",
            display: "flex",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={70}
                height={70}
                style={{ marginBottom: "15px", borderRadius: "7px" }}
              ></Skeleton>
              <Skeleton
                variant="rect"
                width={400}
                height={35}
                animation="wave"
                style={{
                  borderRadius: "7px",
                  marginLeft: "10px",
                  marginTop: "15px",
                }}
              />
            </div>
            <Skeleton
              variant="rect"
              width={500}
              height={250}
              animation="wave"
            />
          </div>
          <div style={{ marginLeft: "100px" }}>
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={70}
                height={70}
                style={{ marginBottom: "15px", borderRadius: "7px" }}
              ></Skeleton>
              <Skeleton
                variant="rect"
                width={400}
                height={35}
                animation="wave"
                style={{
                  borderRadius: "7px",
                  marginLeft: "10px",
                  marginTop: "15px",
                }}
              />
            </div>
            <Skeleton
              variant="rect"
              width={500}
              height={250}
              animation="wave"
            />
          </div>
        </div>
      </div>
    );
    if (!this.props.loading) {
      if (Object.keys(this.props.companies).length > 0) {
        companyList = this.props.companies.map((company) => (
          <div className={classes.companies} key={company._id}>
            <CompanyItem
              open={() => this.openCompany(company)}
              key={company._id}
              name={company.name}
              status={
                moment(company.activeTill, "DD/MM/YYYY").isAfter(
                  moment(new Date())
                )
                  ? "Active"
                  : "Inactive"
              }
              companyId={company._id}
              logo={company.logo}
              address={company.address}
              cin={company.cin}
              activetill={company.activeTill}
            />
          </div>
        ));
      } else {
        companyList = (
          <div className={classes.none}>
            <img src={img} alt="401" className={classes.errorun}></img>
            <Alert severity="info">No Companies Found!</Alert>
          </div>
        );
      }
    }
    return <div>{companyList}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
    loading: state.companies.loading,
    token: state.auth.token,
    userId: state.auth.userId,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanies: (token, userId) => {
      dispatch(fetchCompanies(token, userId));
    },
    onGetCompId: (
      compId,
      logo,
      name,
      status,
      activeTill,
      address,
      cin,
      role
    ) => {
      window.selectedCompany = {
        name: name,
        logo: logo,
        role: role,
      };
      dispatch({
        type: actionTypes.GET_COMPANY_ID,
        compId: compId,
        logo: logo,
        name: name,
        status: status,
        activeTill: activeTill,
        address: address,
        cin: cin,
        role: role,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
