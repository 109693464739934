import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classes from "./UsersPage.module.css";
import NewUser from "../Containers/NewUser/NewUser"
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import UserList from "../Containers/UserList/UserList";
import firebase from 'firebase';

class UsersPage extends Component {
  
  componentDidMount(){
    firebase.analytics().logEvent('User Rights page is rendered');
  }

  render() {

    let user = (
      <h1>Login</h1>
    );


    if (this.props.isAuthenticated) {
      user = (<div>
        <div className={classes.Header}>
          <h5 className={classes.HeaderText}>Edit User Access </h5>
          <div className={classes.vertical}></div>
          <Chip
            avatar={<Avatar style={{ backgroundColor: "#0F5293", color: "white", fontSize: "14px" }}>{this.props.role.charAt(0)}</Avatar>}
            label={"Current Role: " + this.props.role}
            clickable
            style={{ backgroundColor: "#1976D3", marginLeft: "15px", color: "white", fontSize: "14px" }}
          />

          <div className={classes.vertical}></div>
          <Link to={"/" + this.props.compId + "/surveys"}>
            <button className={classes.Back}>Back</button>
          </Link>
        </div>
        <div className={classes.HomePage}>
          <div style={{ display: this.props.status == "Inactive" ? "none" : null }}>
            <NewUser
              loading={this.props.isLoading}
              isError={this.props.isError}
            />
          </div>
          <div style={{ display: this.props.role !== "Admin" ? null : "none" }}>
            <Alert severity="warning">Your current role does not allow you to add new users, this page is read only.</Alert>
          </div><div style={{ display: this.props.role == "Admin" && this.props.status == "Inactive" ? null : "none" }}>
            <Alert severity="error">This company is currently inactive, new users cannot be added.

            </Alert>
          </div>
          <UserList />

          {this.props.error ? (
            <p>No internet connection or Internal Server error</p>
          ) : null}
        </div>
      </div>
      );
    }

    return <Fragment>{user}</Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    isLoading: state.surveys.loading,
    error: state.surveys.error,
    isError: state.surveys.error !== null,
    logo: state.surveyEditer.company.logo,
    surveyId: state.surveyEditer.survey.id,
    name: state.surveyEditer.company.name,
    status: state.surveyEditer.company.status,
    compId: state.surveyEditer.company.id,
    haveSurvId: state.surveyEditer.survey.id !== "",
    role: state.surveyEditer.company.role
  };
};


export default withRouter(
  connect(mapStateToProps)(UsersPage)
);